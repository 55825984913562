import {Injectable} from "@angular/core";
import {isValidNumber, isVoid} from "../utils";
import {MessageBusService} from "../message-bus.service";
import {AtmStrikeService} from '../common-services/atm-strike-service/atm-strike.service';
import {AtmStrikeChangedEvent} from "../common-services/atm-strike-service/atm-strike-changed-event";
import {ApplicationSettingsService, AppSettingsUpdatedMessageTopic} from "../app-settings/application-settings.service";
import {LastQuoteCacheService} from "../last-quote-cache.service";
import {QuoteDto} from "../shell-communication/dtos/quote-dto.class";
import {ExpectedMoveRangeProcessor} from "./expected-move-range.processor";
import {OptionsChainService} from "../option-chains.service";
import {GetOptionChainShellResponse} from "../shell-communication/shell-dto-protocol";

export interface ExpectedMoveArgs {
    source: ExpectedMoveWhereToShow;
    strike: number;
    ticker: string;
    expiration: string;
}

export type ExpectedMoveRangeModel = 'ATM Straddle' | 'Custom Percentage';

export type ExpectedMoveDaysToExpiration = '0' | '1' | '0 & 1' | 'All';

export type ExpectedMoveWhereToShow = 'OPG' | 'Hedge Matrix' | 'OPG & Hedge Matrix';

export interface ExpectedMoveSettings {
    enabled?: boolean;
    whereToShow: ExpectedMoveWhereToShow;
    daysToExpiration: ExpectedMoveDaysToExpiration;
    rangeModel: ExpectedMoveRangeModel;
    rangeModelCustomPercent?: number;
}

@Injectable({providedIn: 'root'})
export class ExpectedMoveService {

    constructor(
        private readonly _messageBusService: MessageBusService,
        private readonly _atmStrikeService: AtmStrikeService,
        private readonly _appSettings: ApplicationSettingsService,
        private readonly _lastQuoteCacheService: LastQuoteCacheService,
        private readonly _optionChainService: OptionsChainService,
    ) {
    }

    private _rangeProcessors = new Map<string, ExpectedMoveRangeProcessor>();
    private _chains = new Map<string, GetOptionChainShellResponse>();

    init() {
        this._messageBusService.of<AtmStrikeChangedEvent>('AtmStrikeChangedEvent')
            .subscribe(data => {
                this.onAtmChanged(data.payload);
            });

        this._messageBusService.of<QuoteDto[]>('QuoteDto')
            .subscribe(x => this.onQuote(x.payload));

        this._messageBusService.of(AppSettingsUpdatedMessageTopic)
            .subscribe(() => this.onSettingsChanged());

        this.watch('SPX');
        this.watch('SPY');

    }

    isStrikeInRange(args: ExpectedMoveArgs): boolean {

        if (!isValidNumber(args.strike)) {
            return false;
        }

        const settings = this._appSettings.expectedMoveSettings;

        if (!settings.enabled) {
            return false;
        }

        const whereToShow = this._appSettings.expectedMoveSettings.whereToShow || '';

        if (whereToShow.indexOf(args.source) < 0) {
            return false;
        }

        const daysToExpiration = this._appSettings.expectedMoveSettings.daysToExpiration;

        if (daysToExpiration !== 'All') {
            if (!this._chains.has(args.ticker)) {
                return false;
            }

            const chain = this._chains.get(args.ticker);

            const zeroExpiration = chain.expirations[0]?.optionExpirationDate;
            const oneExpiration = chain.expirations[1]?.optionExpirationDate;

            if (daysToExpiration === '0 & 1') {
                if (zeroExpiration !== args.expiration && oneExpiration !== args.expiration) {
                    return false;
                }
            } else if (daysToExpiration === '0') {
                if (zeroExpiration !== args.expiration) {
                    return false;
                }
            } else if (daysToExpiration === '1') {
                if (oneExpiration !== args.expiration) {
                    return false;
                }
             }
        }

        const id = `${args.ticker}:${args.expiration}`;

        let rangeProcessor = this._rangeProcessors.get(id);

        if (isVoid(rangeProcessor)) {
            rangeProcessor = new ExpectedMoveRangeProcessor(
                args.ticker,
                args.expiration,
                this._appSettings.expectedMoveSettings,
                this._lastQuoteCacheService,
                this._messageBusService
            );
            const currentAtm = this._atmStrikeService.getCurrentAtm(args.ticker);
            rangeProcessor.onAtmChanged(currentAtm);
            this._rangeProcessors.set(rangeProcessor.id, rangeProcessor);
        }

        return rangeProcessor.isStrikeInRange(args.strike);
    }

    private watch(ticker: string) {
        this._atmStrikeService.watch(ticker);
        this._optionChainService.getChain(ticker).then((data) => {
            this._chains.set(ticker, data);
        });
    }

    private onAtmChanged(payload: AtmStrikeChangedEvent) {
        this._rangeProcessors.forEach(rp => {
            if (rp.ticker === payload.underlying) {
                rp.onAtmChanged(payload.currentAtm);
            }
        });
    }

    private onQuote(payload: QuoteDto[]) {
        this._rangeProcessors.forEach(rp => {
            const quoteDto = payload.find(q => q.ticker === rp.callTicker || q.ticker === rp.putTicker);
            if (quoteDto) {
                rp.onQuote(quoteDto);
            }
        });
    }

    private onSettingsChanged() {
        const expectedMoveSettings = this._appSettings.expectedMoveSettings;
        this._rangeProcessors
            .forEach(rp => rp.onSettingsChanged(expectedMoveSettings))
    }
}