import { Injectable } from '@angular/core';
import {environment} from "./environments/environment";

@Injectable({
   providedIn: 'root'
})
export class LocationService {
   get isEtsHost(): boolean {
      let isEtsHost = location.hostname === 'localhost' || location.hostname.indexOf('evergreen') >= 0;

      if (environment.production) {
         return isEtsHost;
      }

      return false;
   }

   get isBackupServer():boolean {

      if (this.isEtsHost) {
         return false;
      }

      if (location.hostname.indexOf('backup') >= 0) {
         return true;
      }

      return false;
   }

   get isStagingServer():boolean {

      if (this.isEtsHost) {
         return false;
      }

      if (location.hostname.indexOf('staging') >= 0) {
         return true;
      }

      return false;
   }

}
