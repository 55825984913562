import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthServiceClientService} from '../authentication/auth-service-client.service';
import {Observable} from 'rxjs';
import {CheckIfTokenValid} from '../authentication/operations/check-if-token-valid.class';
import {SessionService} from '../authentication/session-service.service';

@Injectable({
    providedIn: 'root'
})
export class ValidAuthTokenGuard implements CanActivate {
    constructor(
        private readonly authServiceClient: AuthServiceClientService,
        private readonly router: Router,
        private readonly sessionService: SessionService
    ) {
    }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.sessionService.isAuthenticated) {
            this.router.navigateByUrl('/login');
            return false;
        }

        const token = this.sessionService.sessionData.authToken;
        if (!token) {
            this.router.navigateByUrl('/login');
            return false;
        }

        const qry = new CheckIfTokenValid(token);
        return this.authServiceClient
            .processQuery(qry)
            .then(() => {
                return true;
            })
            .catch(err => {
                this.router.navigateByUrl('/login');
                return false;
            });
    }
}
