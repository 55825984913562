import { TradingInstrument } from './trading-instrument.class';
import { Injectable } from '@angular/core';
import { Logger } from '../logging/logger.interface';
import { TradingInstrumentsService } from './trading-instruments-service.interface';
import { LoggerService } from '../logging/logger-factory.service';
import { isTruthy } from '../utils';
import {MessageBusService} from "../message-bus.service";

@Injectable({ providedIn: 'root' })
export class TradingInstrumentDisplayNameService {
   constructor(
      private _tiService: TradingInstrumentsService,
      loggerService: LoggerService,
      private readonly _messageBus: MessageBusService
   ) {
      this._logger = loggerService.createLogger(
         'TradingInstrumentDisplayNameService'
      );

      this._tiService.newInstrument.subscribe((x) => this._onNewInstrumentCreated(x));
   }

   private readonly _logger: Logger;
   private _ticker2displayName: Record<string, string>;
   private _displayName2ticker: Record<string, string>;

   init(): Promise<any> {

      const start = Date.now();

      this._ticker2displayName = {};
      this._displayName2ticker = {};

      const instruments = this._tiService.getAllTradingInstruments();
      
      instruments.forEach((instrument) => {
         this._ticker2displayName[instrument.ticker] = instrument.displayName;
         this._displayName2ticker[instrument.displayName] = instrument.ticker;
      });


      const end = Date.now();

      this._messageBus.publish({
         topic: 'ServiceInitialized',
         payload: {
            time: end-start,
            name: 'TradingInstrumentsDisplayName Service'
         }
      });

      return Promise.resolve();
   }

   getDisplayNameForTicker(ticker: string): string {
      let displayName = this._ticker2displayName[ticker];

      if (!displayName) {
         if (!isTruthy(ticker)) {
            displayName = 'N/A';
         } else {

            const ti = this._tiService.getInstrumentByTicker(ticker);
            if (ti) {
               displayName = ti.displayName;
            }

            if (!displayName) {
               displayName = ticker;
            }

            this._ticker2displayName[ticker] = displayName;

            this._displayName2ticker[displayName] = ticker;
         }
      }

      return displayName;
   }

   private _onNewInstrumentCreated(x: TradingInstrument) {
      this._ticker2displayName[x.ticker] = x.displayName;
      this._displayName2ticker[x.displayName] = x.ticker;
   }

}
