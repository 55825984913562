import {NgModule} from '@angular/core';
import {MaintenanceScreenComponent} from './maintenance-screen.component';

@NgModule({
    imports: [],
    exports: [
    ],
    declarations: [
        MaintenanceScreenComponent,
    ],
    providers: [],
})
export class MaintenanceScreenModule {
}
