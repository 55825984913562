import {EventEmitter} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {
    jsonCloneObject,
    DxValueChanged,
    getShortUUID,
    isNullOrUndefined,
    isValidNumber,
    isVoid
} from '../../utils';
import {DxTextBoxComponent} from "devextreme-angular";
import {StrategyTemplate} from "./strategy.template";
import {PricingGridLegDescriptor} from "./pricing-grid-leg.descriptor";
import {PricingGridStrategyDescriptor} from "./pricing-grid-strategy.descriptor";
import {GridSettingsProvider} from "./grid-settings.provider";
import * as Enumerable from "linq";
import {DateTime} from "luxon-business-days";

export class PricingGridStrategy {

    constructor(
        public readonly gridSettings: GridSettingsProvider,
        public readonly targetSide: 'calls' | 'puts',
        descriptor?: PricingGridStrategyDescriptor
    ) {

        if (!isVoid(descriptor)) {
            this.strategyId = descriptor.strategyId;
            this.strategyName = descriptor.strategyName;
            this.selectedStrategy = descriptor.selectedStrategy;
            this.strategyLegs = jsonCloneObject(descriptor.strategyLegs);
            this.width = descriptor.width;
            this.markForChanges();
        } else {
            this.strategyId = getShortUUID();
            this.strategyLegs = [];
        }

        const isCalendarized = this.strategyLegs
            .some(x => isValidNumber(x.expirationOffset, true));
        this.calendarized = isCalendarized;
    }

    private _hasBeenApplied = true;

    readonly strategyId: string;

    readonly unsubscribers: Subscription[] = [];

    calendarized: boolean;

    brokenExpiration = false;

    strategyName: string;

    selectedStrategy: StrategyTemplate;

    strategyLegs: PricingGridLegDescriptor[];

    isEditingHeader = false;

    width: number = 5;

    applyLegsClicked$ = new EventEmitter<PricingGridStrategy>();

    somethingChanged$ = new EventEmitter<PricingGridStrategy>();

    callDetectChanges$ = new EventEmitter<PricingGridStrategy>();

    getDescriptor(): PricingGridStrategyDescriptor {
        const descriptor: PricingGridStrategyDescriptor = {
            strategyId: this.strategyId,
            strategyName: this.strategyName,
            selectedStrategy: this.selectedStrategy,
            strategyLegs: jsonCloneObject(this.strategyLegs || []),
            width: this.width
        };
        return descriptor;
    }


    get isDirty(): boolean {
        return !this._hasBeenApplied;
    }


    get canApplyLegs(): boolean {

        let offsetsAreFine = this.targetSide === 'calls'
            ? this.gridSettings.customCallHedgeOffsetsAreFine
            : this.gridSettings.customPutHedgeOffsetsAreFine;

        if (!offsetsAreFine) {
            return false;
        }

        const checkLegsQty = this.strategyLegs.length === 1 || this.strategyLegs.length > 2;

        const everyLegHasSide = this.strategyLegs
            .filter(x => x.type === 'leg')
            .every(x => !isNullOrUndefined(x.side));

        const everyLegHasQty = this.strategyLegs
            .filter(x => x.type === 'leg')
            .every(x => x.qty >= 1);

        const everyWidthIsGtZero = this.strategyLegs
            .filter(x => x.type === 'width')
            .every(x => x.width > 0);

        const strategyNameProvided = !isNullOrUndefined(this.strategyName);

        const isDirty = this.isDirty;

        return checkLegsQty && everyLegHasSide && everyLegHasQty && everyWidthIsGtZero && strategyNameProvided && isDirty;
    }

    afterLegsApplied() {
        this._hasBeenApplied = true;
    }

    onHeaderDblClick($event) {
        this.isEditingHeader = true;
        this.callDetectChanges$.emit(this);
    }

    onHeaderTextBox(iam: DxTextBoxComponent) {
        setTimeout(() => {
            iam.instance.focus();
            const el = iam.instance.element().querySelector('.dx-texteditor-input') as HTMLInputElement;
            el.select();
        });
    }

    onHeaderChanged(ev) {
        this.isEditingHeader = false;

        if (!ev.value) {
            return;
        }

        this.strategyName = ev.value;

        this.onSomethingChanged(true);
    }

    onHeaderCancelEditing() {
        this.isEditingHeader = false;
        this.callDetectChanges$.emit(this);
    }

    onTextBoxKeyUp(ev, currentText: string) {
        if (ev.event.key === 'Escape') {
            this.onHeaderCancelEditing();
        } else if (ev.event.key === 'Enter') {
            const header = currentText;
            this.onHeaderChanged({value: header, event: 'ets'});
        }
    }

    reverseLegsSide() {
        this.strategyLegs.forEach(leg => {
            if (leg.side === 'Buy') {
                leg.side = 'Sell';
            } else if (leg.side === 'Sell') {
                leg.side = 'Buy';
            }
        });
        this.onSomethingChanged();
    }

    addLeg() {
        if (this.strategyLegs.length === 0) {
            this.strategyLegs.push({type: 'leg'});
        } else {
            this.strategyLegs.push({type: 'width'});
            this.strategyLegs.push({type: 'leg'});
        }
        this.onSomethingChanged();
    }

    removeLeg(leg) {
        const ix = this.strategyLegs.findIndex(x => x === leg);

        if (ix >= 0) {

            this.strategyLegs.splice(ix, 1);

            if (this.strategyLegs.length > 0) {
                let widthIx = ix - 1;
                if (widthIx < 0) {
                    widthIx = ix;
                } else if (widthIx >= this.strategyLegs.length) {
                    widthIx = this.strategyLegs.length - 1;
                }
                if (this.strategyLegs[widthIx].type === 'width') {
                    this.strategyLegs.splice(widthIx, 1);
                }
            }

            this.onSomethingChanged();
        }
    }

    applyLegs() {

        this._hasBeenApplied = true;
        this.applyLegsClicked$.emit(this);
    }

    validateOffsets(validationContext?: any) {

        if (!this.calendarized) {
            return true;
        }

        let expiration = this.gridSettings.expiration;

        if (!isVoid(validationContext?.expiration)) {
            expiration = validationContext.expiration;
        }

        const selectedExpirationDateTime = DateTime.fromISO(expiration.optionExpirationDate);

        const allGood = this.strategyLegs
            .filter(x => x.type === 'leg')
            .every(leg => {
                const expirationOffset = leg.expirationOffset || 0;

                const offsetDate = selectedExpirationDateTime.plusBusiness({days: expirationOffset});

                const offsetDateIso = offsetDate.toISO();

                const dateBeyond = this.gridSettings.optionChain.expirations
                    .find(x => x.optionExpirationDate <= offsetDateIso);

                return !isVoid(dateBeyond);
            });

        return allGood;
    }

    get minExpirationOffset(): number {
        const minAvailableExpiration = this.gridSettings.optionChain.expirations[0]?.daysToExpiration;
        const anchorExpiration = this.gridSettings.expiration?.daysToExpiration;
        const minOffset = minAvailableExpiration - anchorExpiration;
        return minOffset;
    }

    onStrategySelected(ev: { value: StrategyTemplate }) {
        this.strategyLegs = [];
        const legs = this.getStrategyLegs(ev.value);
        this.strategyLegs = legs;
        this.onSomethingChanged();
    }

    onExpirationOffsetChange(value: number, leg: PricingGridLegDescriptor) {
        if (!isValidNumber(value, true)) {
            value = null;
        }
        leg.expirationOffset = value;
    }

    onExpirationOffsetChanged(event: DxValueChanged<number>, leg: PricingGridLegDescriptor) {
        if (!event.event) {
            return;
        }

        if (event.value === 0) {
            leg.expirationOffset = null;
        }

        this.onSomethingChanged();
    }

    private checkIfExpirationBroken() {
        const isBroken = this.strategyLegs.some(x => !this
            .validateOffsets({ expiration: this.gridSettings.expiration?.optionExpirationDate }));

        return isBroken;
    }

    getLegExpirationOffset(leg: PricingGridLegDescriptor) {
        return isValidNumber(leg.expirationOffset, true) ? leg.expirationOffset : null;
    }

    isValid(validationContext?: any) {

        if (this.strategyLegs.length === 0) {
            return false;
        }

        if (!this._hasBeenApplied) {
            return false;
        }

        return true;
    }


    onSomethingChanged(silent?: boolean) {
        if (!silent) {
            this._hasBeenApplied = false;
        }
        this.somethingChanged$.emit(this);
    }

    onWidthChanged() {
        if (this.selectedStrategy === 'BOS') {
            this.recalculateBosStrategy();
        } else if (this.selectedStrategy === 'Sponsored Long') {
            this.recalculateSponsoredLongStrategy();
        }
        this.onSomethingChanged();
    }

    private recalculateBosStrategy() {
        let legs = Enumerable.from(this.strategyLegs);

        let creditSpread: Enumerable.IEnumerable<PricingGridLegDescriptor>;
        if (this.targetSide === 'calls') {
            creditSpread = legs.take(3);
        } else if (this.targetSide === 'puts') {
            creditSpread = legs.reverse().take(3);
        }

        let debitSpread: Enumerable.IEnumerable<PricingGridLegDescriptor>;
        if (this.targetSide === 'calls') {
            debitSpread = legs.reverse().take(3);
        } else if (this.targetSide === 'puts') {
            debitSpread = legs.take(3);
        }

        const debitSpreadWidth = debitSpread
            .first(x => x.type === 'width')?.width;

        const creditSpreadWidth = creditSpread
            .first(x => x.type === 'width')?.width;

        creditSpread.where(x => x.type === 'leg')
            .forEach(x => x.qty = debitSpreadWidth / creditSpreadWidth);
    }

    private recalculateSponsoredLongStrategy() {
        let legs = Enumerable.from(this.strategyLegs);

        let creditSpread: Enumerable.IEnumerable<PricingGridLegDescriptor>;
        if (this.targetSide === 'calls') {
            creditSpread = legs.take(3);
        } else if (this.targetSide === 'puts') {
            creditSpread = legs.reverse().take(3);
        }

        let debitSpread: Enumerable.IEnumerable<PricingGridLegDescriptor>;
        if (this.targetSide === 'calls') {
            debitSpread = legs.reverse().take(3);
        } else if (this.targetSide === 'puts') {
            debitSpread = legs.take(3);
        }

        const debitSpreadWidth = debitSpread
            .first(x => x.type === 'width')?.width;

        const creditSpreadWidth = creditSpread
            .first(x => x.type === 'width')?.width;

        creditSpread.where(x => x.type === 'leg')
            .forEach(x => x.qty = debitSpreadWidth / creditSpreadWidth);
    }


    isBalanced() {
        const widths = this.strategyLegs.filter(x => x.type === 'width');
        const avgOfWidths = widths.reduce((p, c) => p + c.width, 0) / widths.length;
        const isBalanced = widths.every(x => x.width === avgOfWidths);
        return isBalanced;
    }


    private getStrategyLegs(strategy: StrategyTemplate): PricingGridLegDescriptor[] {

        const legs: PricingGridLegDescriptor[] = [];

        const width = isValidNumber(this.width, true) ? this.width : 5;

        switch (strategy) {
            case 'BOS':
                const minWidth = this.gridSettings.tradingInstrument?.ticker === 'SPX' ? 5 : 1;
                const minQty = width / minWidth;
                legs.unshift({type: 'leg', side: 'Buy', qty: 1});
                legs.unshift({type: 'width', width});
                legs.unshift({type: 'leg', side: 'Sell', qty: 1});
                legs.unshift({type: 'width', width: minWidth});
                legs.unshift({type: 'leg', side: 'Sell', qty: minQty});
                legs.unshift({type: 'width', width: minWidth});
                legs.unshift({type: 'leg', side: 'Buy', qty: minQty});

                if (this.targetSide === 'puts') {
                    legs.reverse();
                }

                break;
            case 'Vertical':
                legs.push({type: 'leg', side: 'Sell', qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: 'Buy', qty: 1});

                if (this.targetSide === 'puts') {
                    legs.reverse();
                }
                break;

            case 'Butterfly':
                legs.push({type: 'leg', side: 'Buy', qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: 'Sell', qty: 2});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: 'Buy', qty: 1});
                break;

            case 'Condor':
                legs.push({type: 'leg', side: 'Buy', qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: 'Sell', qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: 'Sell', qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: 'Buy', qty: 1});
                break;

            case 'Slingshot':
                legs.push({type: 'leg', side: 'Buy', qty: 2});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: 'Sell', qty: 2});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: 'Buy', qty: 1});
                if (this.targetSide === 'puts') {
                    legs.reverse();
                }
                break;

            case 'Slingshot - Modified':
                legs.push({type: 'leg', side: 'Sell', qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: 'Buy', qty: 2});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: 'Sell', qty: 2});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: 'Buy', qty: 1});
                if (this.targetSide === 'puts') {
                    legs.reverse();
                }
                break;
            case 'Sponsored Long':
                const csWidth = this.gridSettings.tradingInstrument?.ticker === 'SPX' ? 5 : 1;
                const csQty = width / csWidth;
                legs.unshift({type: 'leg', side: 'Buy', qty: 1});
                legs.unshift({type: 'width', width});
                legs.unshift({type: 'leg', side: 'Sell', qty: csQty});
                legs.unshift({type: 'width', width: csWidth});
                legs.unshift({type: 'leg', side: 'Buy', qty: csQty});

                if (this.targetSide === 'puts') {
                    legs.reverse();
                }

                break;

            case '1 Leg':
                legs.push({type: 'leg', side: null, qty: 1});
                break;

            case '2 Legs':
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                break;

            case '3 Legs':
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                break;

            case '4 Legs':
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                break;

            case '6 Legs':
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                break;

            case '8 Legs':
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                legs.push({type: 'width', width});
                legs.push({type: 'leg', side: null, qty: 1});
                break;
        }

        return legs;
    }

    markForChanges() {
        this._hasBeenApplied = false;
    }
}
