import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DetectMethodChanges, DetectSetterChanges, isValidNumber, isVoid} from "../utils";
import {QuoteGeneratorService} from "./quote-generator.service";
import {TradingInstrument} from "../trading-instruments/trading-instrument.class";
import {TradingInstrumentsService} from "../trading-instruments/trading-instruments-service.interface";
import {
    OptionExpirationDescriptor, QuoteGeneratorLoadedStreamDto,
    QuoteGeneratorProgressDto,
    QuoteGeneratorQuoteDto
} from "../shell-communication/shell-dto-protocol";
import {OptionsChainService} from "../option-chains.service";
import {DatasetSelectorComponent} from "./dataset-selector/dataset-selector.component";
import {MessageBusService} from "../message-bus.service";
import {makeOptionTicker, makeOptionTickerDisplayName, parseOptionTicker} from "../options-common/options.model";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {CustomScriptInputComponent} from "./custom-script-input/custom-script-input.component";
import {QuoteGeneratorMode, QuoteGeneratorQuoteSource, QuoteStream} from "./quote.stream";
import {HistoryDatasetDescriptor, SavedScriptDescriptor} from "../shell-communication/shell-operations-protocol";
import {CustomScriptSaveComponent} from "./custom-script-save/custom-script-save.component";
import {AtmStrikeService} from "../common-services/atm-strike-service/atm-strike.service";

@Component({
    selector: 'ets-quote-generator-component',
    templateUrl: 'quote-generator.component.html',
    styleUrls: ['quote-generator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteGeneratorComponent implements OnInit, OnDestroy {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _quoteGeneratorService: QuoteGeneratorService,
        private readonly _tiService: TradingInstrumentsService,
        private readonly _optionChainService: OptionsChainService,
        private readonly _messageBus: MessageBusService,
        private readonly _atmService: AtmStrikeService,
    ) {
    }

    private _isLoading = false;
    get isLoading(): boolean {
        return this._isLoading;
    }

    @DetectSetterChanges()
    set isLoading(value: boolean) {
        this._isLoading = value;
    }

    visible = false;

    pinnedStream: QuoteStream;

    streams: QuoteStream[] = [];

    addUnderlying: TradingInstrument;

    addExpirationList: OptionExpirationDescriptor[] = [];

    addExpiration: OptionExpirationDescriptor;

    addOptionType: 'Call' | 'Put';

    addStrike: number;

    addOptionContract = false;

    streamFrequencyChanged$ = new Subject<QuoteStream>();

    trendBiasChanged$ = new Subject<QuoteStream>();

    @ViewChild(CustomScriptSaveComponent)
    customScriptSaveDialog: CustomScriptSaveComponent;

    @ViewChild(DatasetSelectorComponent)
    datasetSelector: DatasetSelectorComponent;

    @ViewChild(CustomScriptInputComponent)
    customScriptInput: CustomScriptInputComponent;

    get isUnderlyingSelected(): boolean {
        return !isVoid(this.addUnderlying);
    }

    get isReducedView(): boolean {
        return !isVoid(this.pinnedStream);
    }

    ngOnInit() {
        this._quoteGeneratorService.init();

        this._quoteGeneratorService.atmChanged$.subscribe(() => this._changeDetector.detectChanges());

        this._messageBus.of<any>('Qg.DatasetSelected')
            .subscribe(msg => this.onDataStreamSelected(msg.payload));

        this._messageBus.of<any>('Qg.ScriptSelected')
            .subscribe(msg => this.onSavedScriptSelected(msg.payload));

        this._messageBus.of<any>('Qg.EditScript')
            .subscribe(msg => this.onEditScriptClicked(msg.payload));

        this._messageBus.of<any>('Qg.DeleteScript')
            .subscribe(msg => this.deleteScript(msg.payload));

        this._messageBus.of<QuoteGeneratorQuoteDto>('QuoteGeneratorQuoteDto')
            .subscribe(msg => this.onQuoteGeneratorQuote(msg.payload));

        this._messageBus.of<QuoteGeneratorProgressDto>('QuoteGeneratorProgressDto')
            .subscribe(msg => this.onQuoteGeneratorProgress(msg.payload));

        this._messageBus.of<QuoteGeneratorLoadedStreamDto>('QuoteGeneratorLoadedStreamDto')
            .subscribe(msg => this.onQuoteGeneratorStreamLoaded(msg.payload));

        this.streamFrequencyChanged$
            .pipe(
                debounceTime(1000)
            ).subscribe(msg => this.setStreamFrequency(msg));

        this.trendBiasChanged$
            .pipe(
                debounceTime(1000)
            ).subscribe(msg => this.setTrendBias(msg));

    }

    ngOnDestroy() {
        this._quoteGeneratorService.dispose();
    }

    @DetectMethodChanges()
    async show() {

        if (this.isLoading) {
            return;
        }

        try {
            this.isLoading = true;
            this.streams = await this._quoteGeneratorService.getRegisteredStreams();
            const spxStream = this.streams.find(x => x.ticker === 'SPX');
            this.pinnedStream = spxStream;
            this.visible = true;
        } finally {
            this.isLoading = false;
        }
    }

    @DetectMethodChanges()
    hide() {
        this.visible = false;
    }

    @DetectMethodChanges()
    setMode(stream: QuoteStream, mode: QuoteGeneratorMode) {
        this.isLoading = true;
        setTimeout(async () => {
            try {
                stream.mode = mode;
                await this._quoteGeneratorService.setMode(stream.ticker, mode);
                if (stream.mode !== 'History') {
                    stream.lastTimestamp = null;
                }
            } finally {
                this.isLoading = false;
            }
        });
    }

    @DetectMethodChanges()
    toggleStreamRunning(stream: QuoteStream) {
        this.isLoading = true;
        setTimeout(async () => {
            try {
                await this._quoteGeneratorService.toggleStreamPlaying(stream);
                stream.toggleRunning();
            } finally {
                this.isLoading = false;
            }
        });
    }

    @DetectMethodChanges()
    onAddUnderlying($event: TradingInstrument) {
        this.isLoading = true;

        setTimeout(async () => {
            try {
                this.addUnderlying = $event;
                const chain = await this._optionChainService.getChain($event.ticker);
                this.addExpirationList = chain.expirations;
            } finally {
                this.isLoading = false;
            }
        });
    }

    @DetectMethodChanges()
    onAddExpirationChanged() {
    }

    @DetectMethodChanges()
    toggleExpandStream(stream?: QuoteStream) {
        this.pinnedStream = stream;
    }

    @DetectMethodChanges()
    onClose() {
        this.visible = false;
        this.pinnedStream = null;
    }

    @DetectMethodChanges()
    showDatasetSelector(stream: QuoteStream) {
        this.datasetSelector.show(stream, 'History');
    }

    @DetectMethodChanges()
    showSavedScriptSelector(stream: QuoteStream) {
        this.datasetSelector.show(stream, 'Script');
    }

    @DetectMethodChanges({isAsync: true})
    async showCustomScriptInput(stream: QuoteStream) {
        try {
            const script = await this.customScriptInput.show(stream);
            if (isVoid(script)) {
                return;
            }
            this.isLoading = true;
            await this._quoteGeneratorService.setCustomScript(stream, script);
        } catch {
            //
        } finally {
            this.isLoading = false;
        }
    }

    private editSavedScript(script: SavedScriptDescriptor) {
        this.customScriptInput.showEditor(script);
    }

    @DetectMethodChanges()
    onDataStreamSelected(msg: { stream: QuoteStream, dataset: any }) {
        this.isLoading = true;
        setTimeout(async () => {
            try {
                msg.stream.dataset = msg.dataset;
                await this._quoteGeneratorService.setHistoryDataset(msg.stream, msg.dataset.id);
            } finally {
                this.isLoading = false;
            }

        });

    }

    @DetectMethodChanges()
    onSavedScriptSelected(msg: { stream: QuoteStream, script: any }) {
        this.isLoading = true;
        setTimeout(async () => {
            try {
                msg.stream.savedScript = msg.script;
                msg.stream.customScript = null;
                await this._quoteGeneratorService.setSavedScript(msg.stream, msg.script.name);
            } finally {
                this.isLoading = false;
            }
        });
    }

    @DetectMethodChanges()
    async addStream() {

        this.isLoading = true;

        setTimeout(async () => {
            try {
                const ul = this.addUnderlying;

                const isOption = !isVoid(this.addExpiration)
                    || !isVoid(this.addOptionType) || !isVoid(this.addStrike);

                if (!isOption) {
                    await this._quoteGeneratorService.addStream(ul);
                    return;
                }

                const ticker = makeOptionTicker(this.addExpiration, this.addOptionType
                    , this.addStrike);

                const optionTicker = parseOptionTicker(ticker);

                const displayName = makeOptionTickerDisplayName(optionTicker);

                const ti = {ticker, displayName} as any;

                await this._quoteGeneratorService.addStream(ti);
            } finally {
                this.isLoading = false;
            }
        });

    }

    @DetectMethodChanges()
    onAddOptionContractChanged() {
        if (this.addOptionContract) {
            return;
        }

        this.addExpiration = null;
        this.addOptionType = null;
        this.addStrike = null;
    }

    @DetectMethodChanges()
    removeStream(stream: QuoteStream) {
        this._quoteGeneratorService.removeStream(stream);
    }

    onLeftArrowClicked(stream: QuoteStream) {
        this.isLoading = true;
        setTimeout(async () => {
            try {
                await this._quoteGeneratorService.nextQuote(stream.ticker, 'Backward', stream.quoteStep);
            } catch { }
            finally {
                this.isLoading = false;
            }

        });
    }

    onLastClicked(stream: QuoteStream) {
        this.isLoading = true;

        setTimeout(async () => {
            try {
                await this._quoteGeneratorService.nextQuote(stream.ticker, 'Last', stream.quoteStep);
            } finally {
                this.isLoading = false;
            }
        });

    }

    onRightArrowClicked(stream: QuoteStream) {
        this.isLoading = true;
        setTimeout(async () => {
            try {
                await this._quoteGeneratorService.nextQuote(stream.ticker, 'Forward', stream.quoteStep);
            } catch {}
            finally {
                this.isLoading = false;
            }
        });
    }

    @DetectMethodChanges()
    changeStreamFrequency(stream: QuoteStream) {
        this.streamFrequencyChanged$.next(stream);
    }

    @DetectMethodChanges()
    private onQuoteGeneratorQuote(payload: QuoteGeneratorQuoteDto) {
        try {
            this._quoteGeneratorService.onQuoteGeneratorQuote(payload);
        } catch {}
    }

    private async setStreamFrequency(stream: QuoteStream) {
        await this._quoteGeneratorService.setStreamFrequency(stream);
    }

    @DetectMethodChanges()
    private onQuoteGeneratorProgress(payload: QuoteGeneratorProgressDto) {
        this._quoteGeneratorService.onQuoteGeneratorProgress(payload);
    }

    @DetectMethodChanges()
    private onQuoteGeneratorStreamLoaded(payload: QuoteGeneratorLoadedStreamDto) {
        this._quoteGeneratorService.onQuoteGeneratorStreamLoaded(payload);
    }

    @DetectMethodChanges()
    async onMenuItemClicked(stream: QuoteStream, $event: { itemData: any }) {
        const command = $event.itemData.text || '';

        if (command.indexOf('Reset') > -1) {
            await this._quoteGeneratorService.resetStreamPriceToMarket(stream.ticker);
        }

    }

    getMinDate(stream: QuoteStream) {
        const dataset = stream.dataset as HistoryDatasetDescriptor;
        if (isVoid(dataset)) {
            return undefined;
        }
        return dataset.startPoint;
    }

    getMaxDate(stream: QuoteStream) {
        const dataset = stream.dataset as HistoryDatasetDescriptor;
        if (isVoid(dataset)) {
            return undefined;
        }
        return dataset.endPoint;
    }

    onTrendBiasChanged(stream: QuoteStream) {
        this.trendBiasChanged$.next(stream);
    }

    private async setTrendBias(msg: QuoteStream) {
        await this._quoteGeneratorService.setTrendBias(msg);
    }

    @DetectMethodChanges()
    saveCustomScript(stream: QuoteStream) {
        this.customScriptSaveDialog.show(stream);
    }

    async onStartDateChanged(stream: QuoteStream) {
        await this._quoteGeneratorService.setStartDate(stream);
    }

    async onEndDateChanged(stream: QuoteStream) {
        await this._quoteGeneratorService.setEndDate(stream);
    }

    canChangeMode(stream: QuoteStream) {
        return !stream.isRunning && stream.quoteSource != "Live";
    }

    @DetectMethodChanges()
    setQuoteStep(stream: QuoteStream, number: number) {
        stream.quoteStep = number;
    }

    @DetectMethodChanges()
    toggleStreamMicroView(stream: QuoteStream) {
        stream.microView = !stream.microView;
    }

    getWidth() {
        let width = 800;

        if (this.isReducedView) {

            if (this.isMicroView()) {
                width = 120;
            } else {
                const someShortened = this.streams.some(x => this.isShortenedWidth(x));

                if (someShortened) {
                    width = 485;
                }
            }
        }

        return width;
    }

    isMicroView(): boolean {
        return this.streams.some(x => x.microView);
    }

    getHeight() {
        let height = 465;
        if (this.isReducedView) {
            height = 165;
            if (this.isMicroView()) {
                height = 70;
            } else {
                const some = this.streams.some(x => this.isShortenedWidth(x));

                if (some) {
                    height = 195;
                }
            }
        }
        return height;
    }

    @DetectMethodChanges()
    getOutOfMicroView() {
        this.streams
            .filter(x => x.microView)
            .forEach(x => x.microView = false);
    }

    isResizable() {
        return !this.isReducedView && !this.isMicroView();
    }

    private onEditScriptClicked(payload: any) {
        this.customScriptInput.showEditor(payload.script);
    }

    private async deleteScript(payload: SavedScriptDescriptor) {
        await this._quoteGeneratorService.deleteScript(payload);
    }

    getAtm(stream: QuoteStream) {
        const currentAtm = this._quoteGeneratorService.getCurrentAtm(stream.ticker);
        return currentAtm;
    }

    getMenuItems(stream: QuoteStream) {
        return [
            {text: 'Reset To Market'},
            {text: (stream.isShowingAtm ? 'Hide' : 'Show') + ' ATM'}
        ]
    }

    @DetectMethodChanges()
    onMenuShowing(stream: QuoteStream) {
        stream.menuItems.forEach(item => {
            if (item.text.indexOf('ATM') < 0) {
                return;
            }
            item.text = stream.isShowingAtm ? 'Hide ATM Distance' : 'Show ATM Distance'
        });
    }

   getAtmDistance(stream: QuoteStream): string {
        try {
            const streamAtm = this._atmService
                .calculateCurrentAtm(stream.ticker, stream.lastPrice);

            const currentAtm = this._quoteGeneratorService
                .getCurrentAtm(stream.ticker);

            const diff = (streamAtm - currentAtm) || 0;

            const sign = Math.sign(diff) > 0 ? '+$' : (Math.sign(diff) < 0 ? '-$' : '$');

            return `${sign}${Math.abs(diff)}`;
        } catch  {
            return undefined;
        }
   }

    @DetectMethodChanges()
    moveMarketDown(stream: QuoteStream) {
        if (!isValidNumber(stream.atmDistance)) {
            stream.atmDistance = 0 - stream.quoteStep;
        } else {
            stream.atmDistance -= stream.quoteStep;
        }
    }

    @DetectMethodChanges()
    moveMarketUp(stream: QuoteStream) {
        if (!isValidNumber(stream.atmDistance)) {
            stream.atmDistance = 0 + stream.quoteStep;
        } else {
            stream.atmDistance += stream.quoteStep;
        }
    }

    async setQuoteSource(stream: QuoteStream, value: QuoteGeneratorQuoteSource) {
        await this._quoteGeneratorService.setQuoteSource(stream, value);
    }

    canChangeQuoteSource(stream: QuoteStream) {
        return !stream.isRunning;
    }

    isShortenedWidth(stream: QuoteStream) {
        if (isVoid(this.pinnedStream)) {
            return false;
        }

        if (isVoid(stream)) {
            return false;
        }

        if (this.pinnedStream.ticker !== stream.ticker) {
            return false;
        }

        return stream.isManualMode;
    }

    @DetectMethodChanges({isAsync: true})
    async resetToMarketPrice(stream: QuoteStream) {
        this.isLoading = true;
        try {
            await this._quoteGeneratorService.resetStreamPriceToMarket(stream.ticker);
        } finally {
            this.isLoading = false;
        }
    }
}