import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {MaintenanceScreenService} from "./maintenance-screen.service";


@Injectable({
    providedIn: 'root'
})
export class MaintenanceScreenGuard implements CanActivate {
    constructor(
        private readonly _router: Router,
        private readonly _maintenanceScreenService: MaintenanceScreenService,
    ) { }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        if (!this._maintenanceScreenService.isMaintenanceTime()) {
            return true;
        }

        return new Observable((scriber) => {
            this._router.navigateByUrl('/maintenance')
                .then(x => scriber.next(false));
        });
    }
}
