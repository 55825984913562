import {Component, AfterViewInit, OnInit, OnDestroy} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import { AuthTokenExpiredUIMessage } from '../../../shared-components/ui-messages/auth-token-expired-ui-message.interface';
import { MessageBusService } from '../../../shared-components/message-bus.service';
import { Logger } from '../../../shared-components/logging/logger.interface';
import { SessionService } from '../../../shared-components/authentication/session-service.service';
import { TelemetryService } from 'projects/shared-components/telemetry/telemetry.service';
import { LoggerService } from 'projects/shared-components/logging/logger-factory.service';
import {DateTime} from "luxon";
import {LocationService} from "../../../shared-components/location.service";
import {MaintenanceScreenService} from "../../../shared-components/maintenance-screen/maintenance-screen.service";
import {DismissToken} from "../../../shared-components/authentication/operations/dismiss-token.class";
import {AuthServiceClientService} from "../../../shared-components/authentication/auth-service-client.service";

@Component({
   selector: 'ets-root',
   templateUrl: './wt-app.component.html',
   styleUrls: ['./wt-app.component.scss']
})
export class WebtraderAppComponent implements OnInit, AfterViewInit, OnDestroy {
   private readonly _logger: Logger;

   constructor(
      private readonly _router: Router,
      private readonly _messageBus: MessageBusService,
      private readonly _sessionService: SessionService,
      private readonly _telemetryService: TelemetryService,
      private readonly _maintenanceScreen: MaintenanceScreenService,
      private readonly _authService: AuthServiceClientService,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('AppComponent');
   }

   private _interval: number;

   ngOnInit() {
      this._interval = setInterval(() => this.maintenanceWatcher(), 1000) as any;

   }

   ngOnDestroy() {
      clearInterval(this._interval);
   }

   async ngAfterViewInit(): Promise<void> {
      this._telemetryService.init();

      this._messageBus
         .of<AuthTokenExpiredUIMessage>('AuthTokenExpiredUIMessage')
         .subscribe(data => this.onAuthTokenChanged(data.payload));

      await this._router.navigate(['/login']);
   }


   private async onAuthTokenChanged(message: AuthTokenExpiredUIMessage) {
      this._logger.info('Received message that auth. token is expired', message);
      this._sessionService.reset();
      this._logger.info('User session has been reset');
      const targetRoute = message.doNotShowLogoutScreen ? '/login' : '/logout';
      await this._router.navigate([targetRoute], { skipLocationChange: targetRoute === '/logout' });
   }

   private maintenanceWatcher() {
      if (this._router.url.includes('/maintenance')) {
         return;
      }

      if (!this._maintenanceScreen.isMaintenanceTime()) {
         return;
      }

      const cmd = new DismissToken(this._sessionService.authToken, 'maintenance');
      this._authService
          .processCommand(cmd)
          .then(() => {
          })
          .catch(e =>
              console.debug('session dismiss operation failed during maintenance', { error: e })
          );

      this._router.navigate(['/logout'], { skipLocationChange: true })
          .then(x => {});
   }
}
