import {EventEmitter, Injectable} from "@angular/core";
import {ResourceButton} from "./resource-button.interface";
import {ShellClientService} from "../shell-communication/shell-client.service";
import {
    GetResourceButtonsShell,
    GetResourceButtonsShellReply, RemoveResourceButtonShell,
    SaveResourceButtonsShell, SaveResourceButtonsShellReply, ToggleResourceButtonStateShell
} from "../shell-communication/shell-operations-protocol";
import * as Enumerable from "linq";
import {array_move, getShortUUID, isVoid} from "../utils";
import {ToastrService} from "ngx-toastr";
import {MessageBusService} from "../message-bus.service";
import {ReloadResourcesUIMessage} from "../ui-messages/reload-price-charts-ui-message.interface";
import {ItemReorderedEvent} from "devextreme/ui/list";

@Injectable({providedIn: 'root'})
export class ResourceEditorService {
    constructor(
        private _shellService: ShellClientService,
        private _toastr: ToastrService,
        private _messageBus: MessageBusService
    ) {
    }

    private _resourceButtons: ResourceButton[] = [];

    stateChanged$ = new EventEmitter();

    async init(): Promise<void> {

        const start = Date.now();

        await this.loadResources();

        this._messageBus
            .of<ReloadResourcesUIMessage>('ReloadResourcesUIMessage')
            .subscribe(x => this.onReloadResourcesMessage(x.payload));

        const end = Date.now();

        this._messageBus.publish({
            topic: 'ServiceInitialized',
            payload: {
                time: end-start,
                name: 'ResourceEditor'
            }
        });

    }

    private async loadResources() {
        const qry = new GetResourceButtonsShell();

        const reply = await this._shellService
            .processQuery<GetResourceButtonsShellReply>(qry);

        if (isVoid(this._resourceButtons)) {
            this._resourceButtons = reply.buttons
                .sort((a,b) =>  a.seqNo > b.seqNo ? 1 : -1);
        } else {
            reply.buttons.forEach(nb => {
                const existing = this._resourceButtons.find(ob => ob.id === nb.id);
                if (isVoid(existing)) {
                    this._resourceButtons.push(nb);
                    return;
                }
                Object.assign(existing, nb);
            });
            const freshIds = reply.buttons.map(x => x.id);
            const removedBtns = this._resourceButtons.filter(x => freshIds.indexOf(x.id) < 0);
            removedBtns.forEach(x=> {
                const ix = this._resourceButtons.indexOf(x);
                if (ix >= 0) {
                    this._resourceButtons.splice(ix, 1);
                }
            })
        }
    }

    getResourceButtons(): ResourceButton[] {
        return this._resourceButtons;
    }

    newResourceButton(): ResourceButton {
        const btn: ResourceButton = { id: getShortUUID(), isPublished: false, hasChanges: true, attention: false };
        btn.title = 'Button #' + this._resourceButtons.length;
        btn.seqNo = this._resourceButtons.length;
        this._resourceButtons.push(btn);
        return btn;
    }

    async saveResourceButtons() {

        const dirtyOnes = this._resourceButtons.filter(x => x.hasChanges);

        if (isVoid(dirtyOnes)) {
            return;
        }

        const qry = new SaveResourceButtonsShell(dirtyOnes);

        await this._shellService.processQuery<SaveResourceButtonsShellReply>(qry);

        dirtyOnes.forEach(x => x.hasChanges = false);

        dirtyOnes.forEach(x => {
            const ix = this._resourceButtons.indexOf(x);
            if (ix === -1) {
                this._resourceButtons.push(x);
            }
        });

        this.stateChanged$.emit();
    }

    async removeResourceButton(data: ResourceButton): Promise<void> {
        const cmd = new RemoveResourceButtonShell(data.id);
        await this._shellService.processCommand(cmd);
        this.stateChanged$.emit();
    }

    async toggleButtonDraftState(button: ResourceButton) {
        const cmd = new ToggleResourceButtonStateShell(button.id);
        await this._shellService.processCommand(cmd);
        button.isPublished = !button.isPublished;
    }


    private async onReloadResourcesMessage(payload: ReloadResourcesUIMessage) {
        try {
            await this.loadResources();
        } finally {
            this.stateChanged$.emit();
        }
    }

    onButtonsReordered(event: ItemReorderedEvent<ResourceButton>) {
        const result = array_move(this._resourceButtons, event.fromIndex, event.toIndex);
        console.log(result);
        this._resourceButtons.forEach((x,ix) => {
            x.seqNo = ix;
            x.hasChanges = true;
        })
    }
}
