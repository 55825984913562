import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DetectMethodChanges} from "../../utils";

import {QuoteStream} from "../quote.stream";
import {QuoteGeneratorService} from "../quote-generator.service";

@Component({
    selector: 'ets-custom-script-save',
    template: `
      <dx-popup
        [closeOnOutsideClick]="false"
        [dragEnabled]="true"
        [resizeEnabled]="true"
        [showTitle]="true"
        [visible]="this.visible"
        [shading]="false"
        [width]="400"
        [height]="300"
        title="Custom Script"
        (onHidden)="this.onClose()"
      >
        <div *dxTemplate="let data of 'content'" class="content">
          <div class="body">
            <div class="name">
              <dx-text-box [(text)]="this.name" placeholder="Script Name"></dx-text-box>
            </div>
            <div class="area">
              <dx-text-area [(text)]="this.description" height="100%" width="100%"
                            placeholder="Description"
              ></dx-text-area>
            </div>
          </div>
          <div class="footer">
            <dx-button text="Save" (click)="this.saveClicked()" width="110px"></dx-button>
            <dx-button text="Cancel" (click)="this.cancelClicked()" width="110px"></dx-button>
          </div>
        </div>
      </dx-popup>
    `,
    styles: [`
        .content {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
        }
        
        .body {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: 5px;
        }
        
        .body .area {
            flex: 1;
        }
        
        .body .script-name {
            flex: 0;
        }
        
        .footer {
            flex: 0;
            display: flex;
            justify-content: flex-end;
            column-gap: 10px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomScriptSaveComponent implements OnInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _quoteGeneratorService: QuoteGeneratorService
    ) {
    }

    private _stream: QuoteStream;
    private _resolver: (value?: (PromiseLike<string> | string)) => void;
    private _rejecter: (reason?: any) => void;

    visible = false;

    name: string;

    description: string;

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(stream: QuoteStream) {
        this._stream = stream;
        this.visible = true;
    }

    @DetectMethodChanges()
    onClose() {
        this.visible = false;
    }

    async saveClicked() {
        const name =this.name;
        const description =this.description;
        const script = this._stream.customScript;

        await this._quoteGeneratorService.saveCustomScript(name, description, script, false);

        this._stream.customScript = null;
        this._stream.savedScript = {id: name, name, description};

        this.onClose();
    }

    cancelClicked() {
        this.onClose();
        this._rejecter();
    }
}