<div class="ets-login-card">
   <h1>{{this.formHeader}}</h1>
   <br>
   <form>
      <div class="dx-fieldset">
         <dx-text-box class="dx-field" [inputAttr]="{type: 'email'}" placeholder="E-mail"
            [(value)]="credentials.username" *ngIf="!isAuthenticated">
         </dx-text-box>
         <dx-text-box class="dx-field" mode="password" placeholder="Password" [(value)]="credentials.password"
            *ngIf="!isAuthenticated">
         </dx-text-box>
         <dx-select-box [dataSource]="availableShells" [(value)]="selectedShell" displayExpr="displayName"
            class="dx-field" placeholder="Select shell" *ngIf="availableShells && availableShells.length">
         </dx-select-box>
         <dx-button class="ets-login ets-login-submit" (onClick)="onLoginButtonClicked()"
            [disabled]="isLoading || (isAuthenticated && !selectedShell)">
            <div *dxTemplate="let data of 'content'">
               <dx-load-indicator class='ets-button-indicator' [visible]="isLoading"></dx-load-indicator>
               <span class='dx-button-text'>{{loginButtonText}}</span>
            </div>
         </dx-button>
      </div>
   </form>

   <div class="ets-login-help">
      <span *ngIf="this.isEtsHost">Evergreen Trading Systems &copy;</span>
      <span>v{{productVersion}}</span>
   </div>
  
  <div class="reset">
    <div style="width: 100%; text-align: center;">
      <dx-check-box text="Reset To Default Settings" [value]="false"
                    [disabled]="isLoading"></dx-check-box>
    </div>
  </div>
  
   <div id="ets-logout" *ngIf="isAuthenticated">
      <a href="javascript:void(0)" (click)="onLogoutButtonClicked()">Logout</a>
   </div>
  
</div>

<ets-generic-confirmation-dialog
  [title]="'Please Confirm'"
></ets-generic-confirmation-dialog>