<dx-drop-down-box
  (onClosed)="this.onDropdownClosed()"
  (onOpened)="this.onDropdownOpened($event)"
  [(opened)]="this.isDropdownOpened"
  [(value)]="this.atmMode ? this.atmStrike : this.strike"
  [acceptCustomValue]="false"
  [contentEditable]="false"
  [dataSource]="this.strikes.all"
  [deferRendering]="true"
  [disabled]="this.isDisabled"
  [dropDownOptions]="{width: 290, minHeight: this.boxHeight}"
  [openOnFieldClick]="true"
  [readOnly]="this.readonly"
  [inputAttr]="this.inputAttr"
  fieldTemplate="field"
  placeholder="Strike"
>
  
  <div *dxTemplate="let data of 'field'">
    <dx-text-box *ngIf="!this.atmMode" placeholder="Strike" [inputAttr]="this.inputAttr" [readOnly]="this.readonly" [value]="this.strike"></dx-text-box>
    <dx-text-box *ngIf="this.atmMode" placeholder="Strike" [inputAttr]="this.inputAttr" [readOnly]="this.readonly" [value]="this.atmStrike"></dx-text-box>
  </div>
  
  <div #container *dxTemplate="let data of 'content'">
    
    <div class="container">
      
      <div [class.disabled]="this.atmMode">
        
        <div class="strike-quote">
          
          <div class="section strike">
            <div class="input">
              <dx-number-box (onEnterKey)="this.applyTextBoxStrike()"
                             (onValueChanged)="this.onTextBoxStrikeValueChanged($event)"
                             [(value)]="this.textBoxStrike"
                             [showClearButton]="true">
                <dx-validator>
                  <dxi-validation-rule [validationCallback]="validateStrike"
                                       ignoreEmptyValue="true"
                                       message="Strike does not exist"
                                       reevaluate="true"
                                       type="custom">
                  </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </div>
            <div class="btn">
              <dx-button (onClick)="this.applyTextBoxStrike()" text="OK"></dx-button>
            </div>
          </div>
          
          <div class="section quote">
            {{ this.callQuote | currency:'USD':'symbol':'1.2-2' }}
            /
            {{ this.putQuote | currency:'USD':'symbol':'1.2-2' }}
          </div>
        
        </div>
        
        <hr>
        
        <div class="grid">
          
          <div class="buttons top">
            
            <div class="row row-4">
              <div (click)="this.onStrikeClick(item)"
                   *ngFor="let item of this.topStrikes | slice:15:20"
                   [ngClass]="this.getNgClassForItem(item)"
                   class="cell"
              >
                {{ item }}
              </div>
            </div>
            
            <div class="row row-3">
              <div (click)="this.onStrikeClick(item)"
                   *ngFor="let item of this.topStrikes | slice:10:15"
                   [ngClass]="this.getNgClassForItem(item)"
                   class="cell"
              >
                {{ item }}
              </div>
            </div>
            
            <div class="row row-2">
              <div (click)="this.onStrikeClick(item)"
                   *ngFor="let item of this.topStrikes | slice:5:10"
                   [ngClass]="this.getNgClassForItem(item)"
                   class="cell"
              >
                {{ item }}
              </div>
            </div>
            
            <div class="row row-1">
              <div (click)="this.onStrikeClick(item)"
                   *ngFor="let item of this.topStrikes | slice:0:5"
                   [ngClass]="this.getNgClassForItem(item)"
                   class="cell"
              >
                {{ item }}
              </div>
            </div>
          
          </div>
          
          <div class="navigation">
            
            <div (click)="this.moveStrikesPage('above', 'up')"
                 [style.visibility]="this.navigationButtonVisibility('above', 'up')"
                 class="up"
            >
              <i class="fas fa-chevron-up"></i>
            </div>
            
            <div (click)="this.resetStrikesPage('above')" class="atm">
                     <span [ngSwitch]="this.strikes.topPage">
                     <p *ngSwitchCase="1">
                        ATM
                     </p>
                     <p *ngSwitchDefault>
                        {{ this.currentAtm }}
                     </p>
                     </span>
            </div>
            
            <div (click)="this.moveStrikesPage('above', 'down')"
                 [style.visibility]="this.navigationButtonVisibility('above', 'down')"
                 class="down"
            >
              <i class="fas fa-chevron-down"></i>
            </div>
          
          </div>
        
        </div>
        
        <div class="separator">
          <br/>
        </div>
        
        <div class="grid">
          
          <div class="buttons bottom">
            
            <div class="row row-1">
              <div (click)="this.onStrikeClick(item)"
                   *ngFor="let item of this.bottomStrikes | slice:0:5"
                   [ngClass]="this.getNgClassForItem(item)"
                   class="cell"
              >
                {{ item }}
              </div>
            </div>
            
            <div class="row row-2">
              <div (click)="this.onStrikeClick(item)"
                   *ngFor="let item of this.bottomStrikes | slice:5:10"
                   [ngClass]="this.getNgClassForItem(item)"
                   class="cell"
              >
                {{ item }}
              </div>
            </div>
            
            <div class="row row-3">
              <div (click)="this.onStrikeClick(item)"
                   *ngFor="let item of this.bottomStrikes | slice:10:15"
                   [ngClass]="this.getNgClassForItem(item)"
                   class="cell"
              >
                {{ item }}
              </div>
            </div>
            
            <div class="row row-4">
              <div (click)="this.onStrikeClick(item)"
                   *ngFor="let item of this.bottomStrikes | slice:15:20"
                   [ngClass]="this.getNgClassForItem(item)"
                   class="cell"
              >
                {{ item }}
              </div>
            </div>
          
          </div>
          
          <div class="navigation">
            
            <div (click)="this.moveStrikesPage('below', 'up')"
                 [style.visibility]="this.navigationButtonVisibility('below', 'up')"
                 class="up"
            >
              <i class="fas fa-chevron-up"></i>
            </div>
            
            <div (click)="this.resetStrikesPage('below')" class="atm">
                     <span [ngSwitch]="this.strikes.bottomPage">
                        <p *ngSwitchCase="1">
                           ATM
                        </p>
                        <p *ngSwitchDefault>
                           {{ this.currentAtm }}
                        </p>
                        </span>
            </div>
            
            <div (click)="this.moveStrikesPage('below', 'down')"
                 [style.visibility]="this.navigationButtonVisibility('below', 'down')"
                 class="down"
            >
              <i class="fas fa-chevron-down"></i>
            </div>
          
          </div>
        
        </div>
        
        <hr>
        
        <div class="quick-buttons">
          <div class="button">
            <div (click)="this.changeTextStrike(this.strikes.step1, '-')" class="box sign minus">
              <i class="fas fa-minus"></i>
            </div>
            <div class="box strike-step">{{ this.strikes.step1 }}</div>
            <div (click)="this.changeTextStrike(this.strikes.step1, '+')" class="box sign plus">
              <i class="fas fa-plus"></i>
            </div>
          </div>
          <div class="button">
            <div (click)="this.changeTextStrike(this.strikes.step2, '-')" class="box sign minus">
              <i class="fas fa-minus"></i>
            </div>
            <div class="box strike-step">{{ this.strikes.step2 }}</div>
            <div (click)="this.changeTextStrike(this.strikes.step2, '+')" class="box sign plus">
              <i class="fas fa-plus"></i>
            </div>
          </div>
          <div class="button">
            <div (click)="this.changeTextStrike(this.strikes.step3, '-')" class="box sign minus">
              <i class="fas fa-minus"></i>
            </div>
            <div class="box strike-step">{{ this.strikes.step3 }}</div>
            <div (click)="this.changeTextStrike(this.strikes.step3, '+')" class="box sign plus">
              <i class="fas fa-plus"></i>
            </div>
          </div>
        </div>
      
      
      </div>
      
      <hr *ngIf="this.showAtm">
      
      <div *ngIf="this.showAtm" class="atm">
        
        <div class="section">
          <dx-check-box [(value)]="this.atmMode" text="ATM"></dx-check-box>
        </div>
        
        <div [class.disabled]="!this.atmMode" class="section numbers">
          <div class="button">
            <div (click)="this.decreaseAtmOffset()" class="box sign minus">
              <i class="fas fa-minus"></i>
            </div>
            
            <div class="box strike-offset">
              <dx-number-box [(value)]="this.atmOffset" [format]="this.atmOffsetInDollars ? 'currency' : null"
                             [valueChangeEvent]="'input'" height="25px"></dx-number-box>
            </div>
            
            <div (click)="this.increaseAtmOffset()" class="box sign plus">
              <i class="fas fa-plus"></i>
            </div>
          </div>
        </div>
        
        <div class="section dollars">
          <dx-check-box [(value)]="this.atmOffsetInDollars" [disabled]="!this.atmMode" text="$"></dx-check-box>
        </div>
      
      </div>
    
    </div>
    
    <dx-load-panel
      [position]="{my: 'center', at: 'center', of: container}"
      [visible]="isLoading"
    >
    </dx-load-panel>
  </div>

</dx-drop-down-box>