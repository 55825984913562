import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
    ExpectedMoveDaysToExpiration,
    ExpectedMoveRangeModel, ExpectedMoveSettings,
    ExpectedMoveWhereToShow
} from "./expected-move.service";
import {ApplicationSettingsService} from "../app-settings/application-settings.service";
import {DetectMethodChanges, DetectSetterChanges} from "../utils";

@Component({
    selector: 'expected-move-settings-component',
    templateUrl: 'expected-move-settings.component.html',
    styleUrls: ['expected-move-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpectedMoveSettingsComponent implements OnInit, AfterViewInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _appSettings: ApplicationSettingsService) {
    }

    private _enabled: boolean;
    get enabled(): boolean {
        return this._enabled || false;
    }

    @DetectSetterChanges()
    set enabled(value: boolean) {
        this._enabled = value;
    }

    rangeModelList = [
        'ATM Straddle',
        'Custom Percentage',
    ];

    rangeModel: ExpectedMoveRangeModel;

    rangeModelCustomPercent: number;

    daysToExpirationList: ExpectedMoveDaysToExpiration[] = [
        '0',
        '1',
        '0 & 1',
        'All'
    ];

    daysToExpiration: ExpectedMoveDaysToExpiration;

    whereToShowList: ExpectedMoveWhereToShow[] = [
        'OPG',
        'Hedge Matrix',
        'OPG & Hedge Matrix'
    ];

    whereToShow: ExpectedMoveWhereToShow;

    @DetectMethodChanges()
    ngOnInit(): void {
    }

    @DetectMethodChanges()
    ngAfterViewInit() {
        this.fillSettings();
    }


    getSettings(): ExpectedMoveSettings {
        return {
            rangeModel: this.rangeModel,
            rangeModelCustomPercent: this.rangeModelCustomPercent,
            enabled: this.enabled,
            daysToExpiration: this.daysToExpiration,
            whereToShow: this.whereToShow,
        };
    }

    @DetectMethodChanges()
    onChange() {

    }

    reset() {
        this.enabled = false;
        this.rangeModel = null;
        this.whereToShow = null;
        this.daysToExpiration = null;
        this.rangeModelCustomPercent = null;
    }

    private fillSettings() {
        const expectedMoveSettings = this._appSettings.expectedMoveSettings;

        this.enabled = expectedMoveSettings?.enabled;
        this.rangeModel = expectedMoveSettings?.rangeModel;
        this.daysToExpiration = expectedMoveSettings?.daysToExpiration;
        this.whereToShow = expectedMoveSettings?.whereToShow;
        this.rangeModelCustomPercent = expectedMoveSettings.rangeModelCustomPercent;
    }

    @DetectMethodChanges()
    init() {
        this.fillSettings();
    }
}