import { Injectable } from '@angular/core';
import {
   TradingInstrumentsService
} from 'projects/shared-components/trading-instruments/trading-instruments-service.interface';
import {
   TradingInstrumentDisplayNameService,
} from 'projects/shared-components/trading-instruments/trading-instrument-display-name.service';
import { SettingsStorageService } from '../settings-storage-service.service';
import { MessageBusService } from '../message-bus.service';
import { ToastrService } from 'ngx-toastr';
import { isInstrumentExpired } from '../utils';

export type ChartNodeCategoryLabel =
   | 'account'
   | 'terminal'
   | 'tradingInstrument'
   | 'strategy'
   | 'favorite';

export interface SymbolPickerTreeNode {
   id: string;
   header: string;
   categoryLabel?: ChartNodeCategoryLabel;
   dataObject?: any;
   children?: SymbolPickerTreeNode[];
}

@Injectable({ providedIn: 'root' })
export class SymbolPickerTreeService {
   private _tree: SymbolPickerTreeNode[];
   private _underlyingsTree: SymbolPickerTreeNode[];

   constructor(
      private tradingInstrumentsSerivce: TradingInstrumentsService,
      private tradingInstrumentDisplayNameService: TradingInstrumentDisplayNameService,
      private _userSettingsService: SettingsStorageService,
      private _messageBus: MessageBusService,
      private _toastr: ToastrService
   ) {
   }

   getTree(): SymbolPickerTreeNode[] {
      return this._tree.slice();
   }

   get underlyingsTree(): SymbolPickerTreeNode[] {
      return this._underlyingsTree;
   }

   init(): Promise<any> {

      const start = Date.now();

      const favs: string[] = this._userSettingsService.getItem('favorites') || [];

      const instruments = this.tradingInstrumentsSerivce.getAllTradingInstruments();

      const favoritesRoot: SymbolPickerTreeNode = { id: 'Favorites', header: 'Favorites', dataObject: null, children: [] };

      if (favs.length > 0) {
         
         favs.forEach( f => {
            
            const favTI = this.tradingInstrumentsSerivce.getInstrumentByTicker(f);

            if (!favTI) {
               return;
            }

            const favTicker = favTI.ticker + '-FAV';

            const tickerDisplayName = this.tradingInstrumentDisplayNameService.getDisplayNameForTicker(favTI.ticker);

            const obj: SymbolPickerTreeNode = {
               id: favTicker,
               header: tickerDisplayName,
               dataObject: favTI,
               children: [],
               categoryLabel: 'favorite'
            };

            favoritesRoot.children.push(obj);
         });
      }


      instruments.filter(x => !isInstrumentExpired(x)).map(x => {
         return { underlying: x.underlying, kind: x.kind };
      });

      const retVal = [];

      if (favoritesRoot.children.length > 0) {
         favoritesRoot.children.sort((a, b) => {
            return a.header > b.header ? 1 : -1;
         });
      }
      retVal.push(favoritesRoot);

      this._tree = retVal;

      const end = Date.now();

      this._messageBus.publish({
         topic: 'ServiceInitialized',
         payload: {
            time: end-start,
            name: 'SymbolPickerTreeService',
         }
      });

      return Promise.resolve();
   }

   addToFavorites(dataObject: any) {
      const favsRoot = this._tree.find(x => x.id === 'Favorites');
      const favTicker = dataObject.ticker + '-FAV';

      const existing = favsRoot.children.findIndex((x: SymbolPickerTreeNode) => x.id === favTicker);

      if (existing === -1) {
         const obj: SymbolPickerTreeNode = {
            id: favTicker,
            header: dataObject.displayName,
            dataObject,
            children: [],
            categoryLabel: 'favorite'
         };

         favsRoot.children.push(obj);

         this._messageBus.publish({
            topic: 'PrefetchOptionChainUIMessage',
            payload: { ticker: dataObject.ticker }
         });
      } else {
         this._toastr.info('Already in favorites');
      }

      this.saveFavorites(favsRoot);
   }

   removeFromFavorites(dataObject: any) {
      const favsRoot = this._tree.find(x => x.id === 'Favorites');
      const favTicker = dataObject.ticker + '-FAV';

      const existing = favsRoot.children.findIndex((x: SymbolPickerTreeNode) => x.id === favTicker);
      if (existing >= 0) {
         favsRoot.children.splice(existing, 1);
      }

      this.saveFavorites(favsRoot);
   }

   private saveFavorites(favsRoot: SymbolPickerTreeNode) {
      setTimeout(() => {
         const favTickers = favsRoot.children.map(x => {
            return x.id.split('-')[0];
         });
         this._userSettingsService.setItem('favorites', favTickers);
      });
   }
}
