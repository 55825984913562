import {EventEmitter, Injectable} from '@angular/core';
import {TradingInstrument} from "../trading-instruments/trading-instrument.class";
import {TradingInstrumentsService} from "../trading-instruments/trading-instruments-service.interface";
import {DetectMethodChanges, isVoid} from "../utils";
import {
    AddQuoteStream,
    DeleteSavedScript,
    GenerateNextQuote,
    GetLoadedStreams,
    GetSavedScript,
    GetSavedScriptList,
    GetSavedScriptReply,
    GetSingleQuoteShell,
    GetSingleQuoteShellReply,
    PauseQuoteGeneratorStream,
    PlayQuoteGeneratorConfig,
    PlayQuoteGeneratorStream,
    RemoveQuoteGeneratorStream,
    ResetStreamPriceToMarket,
    SaveCustomScript,
    SavedScriptDescriptor,
    SetCustomScript,
    SetHistoryDataset,
    SetQuoteGeneratorMode, SetQuoteGeneratorQuoteSource,
    SetQuoteGeneratorStreamFrequency,
    SetQuoteStreamEndDate,
    SetQuoteStreamStartDate,
    SetSavedScript,
    SetTrendBias
} from "../shell-communication/shell-operations-protocol";
import {ShellClientService} from "../shell-communication/shell-client.service";
import {
    QuoteGeneratorLoadedStreamDto,
    QuoteGeneratorProgressDto,
    QuoteGeneratorQuoteDto
} from "../shell-communication/shell-dto-protocol";
import {ProtocolCommand} from "../service-model/protocol-command.interface";
import {QuoteGeneratorMode, QuoteGeneratorQuoteSource, QuoteStream} from "./quote.stream";
import {AtmStrikeService} from "../common-services/atm-strike-service/atm-strike.service";
import {SessionService} from "../authentication/session-service.service";
import {MessageBusService} from "../message-bus.service";

@Injectable({providedIn: 'root'})
export class QuoteGeneratorService {

    constructor(
        private readonly _tiService: TradingInstrumentsService,
        private readonly _shellService: ShellClientService,
        private readonly _atmService: AtmStrikeService,
        private readonly _sessionService: SessionService,
        private readonly _messageBus: MessageBusService
    ) {
    }


    private _atmIndex: Record<string, number> = {};

    private _registeredStreams: QuoteStream[] = [];

    private _atmPoller: number;

    atmChanged$ = new EventEmitter<{ ticker: string, atm: number }>();

    init() {

        clearInterval(this._atmPoller);
        this._atmIndex = {};

        if (!this._sessionService.loginResult.isQuoteGeneratorShell) {
            return;
        }

        if (this.atmChanged$) {
            this.atmChanged$.complete();
        }

        this.atmChanged$ = new EventEmitter<{ ticker: string, atm: number }>();

        this._atmPoller = setInterval(async () => {
            await this.fetchAtm("SPX");
            await this.fetchAtm("SPY");
            await this.fetchAtm("ESH5");
        }, 3000) as any;
    }

    async getRegisteredStreams(): Promise<QuoteStream[]> {
        //
        // if (!isVoid(this._registeredStreams)) {
        //     return this._registeredStreams;
        // }
        //

        const qry = new GetLoadedStreams();

        const streams = await this._shellService
            .processQuery<QuoteGeneratorLoadedStreamDto[]>(qry);

        const quoteStreams = streams.map(x => QuoteStream.fromDto(x, this._tiService));

        this._registeredStreams = quoteStreams;

        return this._registeredStreams;
    }

    async addStream(tradingInstrument: TradingInstrument): Promise<void> {
        const number = this._registeredStreams.findIndex(x => x.ticker === tradingInstrument.ticker);

        if (number !== -1) {
            return;
        }

        const stream = new QuoteStream(tradingInstrument);

        this._registeredStreams.push(stream);

        const cmd = new AddQuoteStream(tradingInstrument.ticker);

        await this._shellService.processCommand(cmd);
    }

    removeStream(stream: QuoteStream) {
        const number = this._registeredStreams.indexOf(stream);

        if (number === -1) {
            return;
        }

        this._registeredStreams.splice(number, 1);

        const cmd = new RemoveQuoteGeneratorStream(stream.ticker);
        this._shellService.processCommand(cmd)
            .then(() => {
            })
            .catch(() => console.log('Failed to remove stream'));
    }

    async nextQuote(ticker: string, direction: 'Backward' | 'Last' | 'Forward', step?: number): Promise<void> {
        const cmd = new GenerateNextQuote(ticker, direction, step);
        await this._shellService.processCommand(cmd);
    }

    onQuoteGeneratorQuote(payload: QuoteGeneratorQuoteDto) {
        const stream = this._registeredStreams.find(x => x.ticker === payload.ticker);
        if (isVoid(stream)) {
            return;
        }
        stream.lastPrice = payload.lastPx;
        if (stream.mode === 'History') {
            stream.lastTimestamp = payload.isoTimestamp;
        }
    }

    async setMode(ticker: string, mode: QuoteGeneratorMode) {
        const cmd = new SetQuoteGeneratorMode(ticker, mode);
        await this._shellService.processCommand(cmd);
        this._messageBus.publishAsync({
            topic: 'Qg.StreamModeChanged',
            payload: {
                stream: ticker,
                mode: mode
            }
        });
    }

    async toggleStreamPlaying(stream: QuoteStream) {
        let cmd: ProtocolCommand;
        if (stream.isRunning) {
            cmd = new PauseQuoteGeneratorStream(stream.ticker);
        } else {
            const cfg: PlayQuoteGeneratorConfig = {
                ticker: stream.ticker,
                frequency: stream.frequency,
                customScript: stream.customScript,
                quoteStep: stream.quoteStep
            };
            cmd = new PlayQuoteGeneratorStream(cfg);
        }
        await this._shellService.processCommand(cmd);
    }

    async setStreamFrequency(stream: QuoteStream) {
        const cmd = new SetQuoteGeneratorStreamFrequency(
            stream.ticker,
            stream.frequency
        );

        await this._shellService.processCommand(cmd);
    }

    onQuoteGeneratorProgress(payload: QuoteGeneratorProgressDto) {
        const stream = this._registeredStreams.find(x => x.ticker === payload.ticker);
        if (isVoid(stream)) {
            return;
        }
        stream.progress = payload.progress;

        if (stream.progress >= 1) {
            if (stream.isRunning) {
                this.toggleStreamPlaying(stream).then(() => {
                });
            }
        }
    }

    onQuoteGeneratorStreamLoaded(payload: QuoteGeneratorLoadedStreamDto) {
        const number = this._registeredStreams.findIndex(x => payload.ticker === x.ticker);
        if (number > -1) {
            return;
        }
        const stream = QuoteStream.fromDto(payload, this._tiService);
        this._registeredStreams.push(stream);
    }

    async setCustomScript(stream: QuoteStream, script: string): Promise<void> {
        const cmd = new SetCustomScript(stream.ticker, script);
        await this._shellService.processCommand(cmd);
    }

    async setHistoryDataset(stream: QuoteStream, dataset: string) {
        const cmd = new SetHistoryDataset(stream.ticker, dataset);
        await this._shellService.processCommand(cmd);
    }

    async setSavedScript(stream: QuoteStream, script: string) {
        const cmd = new SetSavedScript(stream.ticker, script);
        await this._shellService.processCommand(cmd);
    }

    async resetStreamPriceToMarket(ticker: string) {
        const cmd = new ResetStreamPriceToMarket(ticker);
        await this._shellService.processCommand(cmd);
    }

    async setTrendBias(msg: QuoteStream) {
        const cmd = new SetTrendBias(msg.ticker, msg.trendBias);
        await this._shellService.processCommand(cmd);
    }

    async saveCustomScript(name: string, description: string, script: string, isEditMode: boolean) {
        const cmd = new SaveCustomScript(name, description, script, isEditMode);
        await this._shellService.processCommand(cmd);
    }

    async setStartDate(stream: QuoteStream) {
        const cmd = new SetQuoteStreamStartDate(stream.ticker, stream.startDate);
        await this._shellService.processCommand(cmd);
    }

    async setEndDate(stream: QuoteStream) {
        const cmd = new SetQuoteStreamEndDate(stream.ticker, stream.endDate);
        await this._shellService.processCommand(cmd);
    }

    async getSavedScript(id: string) {
        const qry = new GetSavedScript(id);
        const reply = await this._shellService.processQuery<GetSavedScriptReply>(qry);
        return reply.script;
    }

    async deleteScript(payload: any) {
        const cmd = new DeleteSavedScript(payload.script.id);
        await this._shellService.processCommand(cmd);
    }

    private async fetchAtm(ticker: string) {
        const qry = new GetSingleQuoteShell(ticker);

        const result = await this._shellService.processQuery<GetSingleQuoteShellReply>(qry);

        const currentAtm = this._atmService.calculateCurrentAtm(ticker, result.lastPx);

        const prevAtm = this._atmIndex[ticker];


        if (prevAtm === currentAtm) {
            return;
        }

        this._atmIndex[ticker] = currentAtm;

        this.atmChanged$.emit({ticker, atm: currentAtm});
    }

    getCurrentAtm(ticker: string): number | undefined {
        return this._atmIndex[ticker];
    }

    async setQuoteSource(stream: QuoteStream, value: QuoteGeneratorQuoteSource) {
        stream.quoteSource = value;
        const cmd = new SetQuoteGeneratorQuoteSource(stream.ticker, value);
        await this._shellService.processCommand(cmd);
    }

    dispose() {
        clearInterval(this._atmPoller);
    }

    getStream(ticker: string): QuoteStream {
        const quoteStream = this._registeredStreams.find(x => x.ticker === ticker);
        return quoteStream;
    }

    isTheoreticalPrices(ticker: string) {

        if (isVoid(ticker)) {
            return false;
        }

        if (!this._sessionService.loginResult.isQuoteGeneratorShell) {
            return false;
        }

        const stream = this.getStream(ticker);

        if (isVoid(stream)) {
            return false;
        }

        return stream.quoteSource === 'Theoretical';
    }

}