<fieldset class="parameters expected-move">
  <legend>
    <dx-check-box text="Expected Move Settings" [(value)]="this.enabled"></dx-check-box>
  </legend>
  <div class="expected-move-settings">
    <div class="body">
      <div class="parameter">
        <div class="label">Range Model</div>
        <div class="input">
          <dx-select-box [items]="this.rangeModelList" [(value)]="this.rangeModel" [disabled]="!this.enabled"
            (onValueChanged)="this.onChange()">
          </dx-select-box>
        </div>
      </div>
      <div class="parameter" *ngIf="this.rangeModel === 'Custom Percentage'">
        <div class="label">Custom Percent</div>
        <div class="input">
          <dx-number-box [showSpinButtons]="true" [(value)]="this.rangeModelCustomPercent"
                         [showClearButton]="true"
                         [min]="0.01"
                         [max]="5"
                         [step]="0.01"
                         format="0#%"
          ></dx-number-box>
        </div>
      </div>
      <div class="parameter">
        <div class="label">Show Days To Exp.</div>
        <div class="input">
          <dx-select-box [items]="this.daysToExpirationList" [(value)]="this.daysToExpiration" [disabled]="!this.enabled"></dx-select-box>
        </div>
      </div>
      <div class="parameter">
        <div class="label">Where To Show</div>
        <div class="input">
          <dx-select-box [items]="this.whereToShowList" [(value)]="this.whereToShow" [disabled]="!this.enabled"></dx-select-box>
        </div>
      </div>
    </div>
  </div>
</fieldset>
