import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {jsonCloneObject, DetectMethodChanges, DxValueChanged, isVoid} from "../../utils";
import {PortfolioGroup} from "../model/PackageComparisonModel";
import {
    HedgeBoardSettings,
    PackageComparisonSettings,
    PackageComparisonSettingsService
} from "./package-comparison-settings.service";
import {ServiceConfiguration} from "../../adjustment-pricing-grid/services/ServiceConfiguration";
import * as Enumerable from "linq";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {
    CashFlowStrategyTemplatesService
} from "../../adjustment-pricing-grid/services/cashflow-strategy-templates.service";
import {OptionPricingGridTemplatesService} from "../../options-pricing-grid/option-pricing-grid-templates.service";
import {DxTreeViewComponent} from "devextreme-angular";
import {ToastrService} from "ngx-toastr";
import {ApgDataService} from "../../adjustment-pricing-grid/services/apg-data.service";

@Component({
    selector: 'ets-package-comparison-settings',
    templateUrl: 'package-comparison-settings.component.html',
    styleUrls: ['package-comparison-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PackageComparisonSettingsComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _pkgCmprsnSettingsService: PackageComparisonSettingsService,
        private readonly _cashflowTplService: CashFlowStrategyTemplatesService,
        private readonly _opgTemplatesService: OptionPricingGridTemplatesService,
        private readonly _toastr: ToastrService,
        private readonly _apgDataService: ApgDataService
    ) {
        this.getPortfolioDisplayName = this.getPortfolioDisplayName.bind(this);
    }

    visible = false;

    portfolioList : PortfolioGroup[] = [];

    treeItems = [];

    hedgeBoardTree = [];

    isLoading = false;

    selectedPortfolio: ApgPortfolio;

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(portfolios: PortfolioGroup[]) {
        this.portfolioList = portfolios;
        this.visible = true;

        if (this.portfolioList.length === 1) {
            if (this.portfolioList[0].items.length === 1) {
                const pf = this.portfolioList[0].items[0];
                setTimeout(async () => {
                    this.selectedPortfolio = pf;
                    await this.onSettingsPortfolioSelected({value: pf, event: 'ets'});
                }, 25);
            }
        }
    }

    @DetectMethodChanges({isAsync: true})
    async onSettingsPortfolioSelected(args: DxValueChanged<ApgPortfolio>) {

        if (isVoid(args.event)) {
            return;
        }

        const ul = await this._apgDataService.getUnderlyingOfPortfolio(args.value);

        this.treeItems = [];
        this.isLoading = true;

        setTimeout(async () => {
            try {
                const templates = await this.getTemplateNodes(args.value);

                const hedgesCall = this.getHedgeNodes(args.value, 'Call');

                const hedgesPut = this.getHedgeNodes(args.value, 'Put');

                const adjustmentsCall = {
                    id: 'adj_c',
                    text: 'Adjustments "Call"',
                    items: [
                        {id: 'adj_c_1', text: '#1', type: 'adjustment_Call', data: '#1'},
                        {id: 'adj_c_2a', text: '#2A', type: 'adjustment_Call', data: '#2A'},
                        {id: 'adj_c_2b', text: '#2B', type: 'adjustment_Call', data: '#2B'},
                        {id: 'adj_c_3', text: '#3', type: 'adjustment_Call', data: '#3'},
                    ]
                } as any;

                const adjustmentsPut = {
                    id: 'adj_p',
                    text: 'Adjustments "Put"',
                    items: [
                        {id: 'adj_p_1', text: '#1', type: 'adjustment_Put', data: '#1'},
                        {id: 'adj_p_2a', text: '#2A', type: 'adjustment_Put', data: '#2A'},
                        {id: 'adj_p_2b', text: '#2B', type: 'adjustment_Put', data: '#2B'},
                        {id: 'adj_p_3', text: '#3', type: 'adjustment_Put', data: '#3'},
                    ]
                } as any;

                const settings =
                    this._pkgCmprsnSettingsService.get(args.value);

                if (!isVoid(settings)) {
                    try {
                        settings.templates.forEach(tplId => {
                            const tpl = templates.items.find( tpl => tpl.id === tplId );
                            if (!isVoid(tpl)) {
                                tpl.selected = true;
                            }
                        });
                    } catch {
                        //
                    }

                    try {
                        settings.adjustmentCall.forEach(adjCall => {
                            const node = adjustmentsCall.items
                                .find(adjCitem => adjCitem.data === adjCall);

                            if (!isVoid(node)) {
                                node.selected = true;
                            }
                        });

                    } catch  {}


                    try {
                        settings.adjustmentPut.forEach(adjPut => {
                            const node = adjustmentsPut.items
                                .find(adjPitem => adjPitem.data === adjPut);

                            if (!isVoid(node)) {
                                node.selected = true;
                            }
                        });
                    } catch {
                        //
                    }


                    try {
                        settings.hedgesCall.forEach(callHedgeFav => {
                            const hedges = hedgesCall.items.flatMap(x => x.items);
                            const node = hedges.find(h => h.data === callHedgeFav);
                            if (!isVoid(node)) {
                                node.selected = true;
                            }
                        });
                    } catch  {
                        //
                    }

                    try {
                        settings.hedgesPut.forEach(putHedgeFav => {
                            const hedges = hedgesPut.items.flatMap(x => x.items);
                            const node = hedges.find(h => h.data === putHedgeFav);
                            if (!isVoid(node)) {
                                node.selected = true;
                            }
                        });
                    } catch {
                        //
                    }
                }

                this.treeItems = [
                    templates,
                    adjustmentsCall,
                    adjustmentsPut,
                    hedgesCall,
                    hedgesPut
                ];

                //
                // Hedge Board
                //

                const callOffsets = {
                    id: 'offset_c',
                    text: 'Call Offsets',
                    items: []
                };

                const divider = ul === 'SPX' ?  1 : 10;
                const step = ul === 'SPX' ? 5 : 1;
                const ceil = ul === 'SPX' ? 100 : 10;

                for (let i = 0; i <= ceil; i+=step) {
                    const offset = i;
                    const item = {
                        id: `offset_call_${offset}`,
                        text: `$${offset}`,
                        data: offset,
                        type: 'offsets_call'
                    };
                    callOffsets.items.push(item);
                }

                const putOffsets = {
                    id: 'offset_p',
                    text: 'Put Offsets',
                    items: []
                };

                for (let i = 0; i <= ceil; i+=step) {
                    const offset = i;
                    const item = {
                        id: `offset_put_${offset}`,
                        text: `$${offset}`,
                        data: offset,
                        type: 'offsets_put'
                    };
                    putOffsets.items.push(item);
                }

                const templatesForHb = jsonCloneObject(templates);
                templatesForHb.items.forEach(x => x.selected = false);

                const adjustmentsForHedgesC =  jsonCloneObject(adjustmentsCall);
                adjustmentsForHedgesC.items.forEach(adjCitem => adjCitem.selected = false);

                const adjustmentsForHedgesP = jsonCloneObject(adjustmentsPut);
                adjustmentsForHedgesP.items.forEach(adjCitem => adjCitem.selected = false);

                const hedgesForHedgesC = jsonCloneObject(hedgesCall);
                hedgesForHedgesC.items.flatMap(x => x.items).forEach(x => x.selected = false);

                const hedgesForHedgesP = jsonCloneObject(hedgesPut);
                hedgesForHedgesP.items.flatMap(x => x.items).forEach(x => x.selected = false);

                this.hedgeBoardTree = [
                    templatesForHb,
                    adjustmentsForHedgesC,
                    adjustmentsForHedgesP,
                    hedgesForHedgesC,
                    callOffsets,
                    hedgesForHedgesP,
                    putOffsets
                ];

                const hedgeBoardSettings =
                    this._pkgCmprsnSettingsService.getHedgeBoardSettings(args.value);

                if (!isVoid(hedgeBoardSettings)) {
                    hedgeBoardSettings.templates.forEach(tplId => {
                        const tpl = templatesForHb.items.find( tpl => tpl.id === tplId );
                        if (!isVoid(tpl)) {
                            tpl.selected = true;
                        }
                    });

                    try {
                        hedgeBoardSettings.adjustmentCall.forEach(adjCall => {
                            const node = adjustmentsForHedgesC.items
                                .find(adjCitem => adjCitem.data === adjCall);

                            if (!isVoid(node)) {
                                node.selected = true;
                            }
                        });
                    } catch {
                        //
                    }

                    try {
                        hedgeBoardSettings.adjustmentPut.forEach(adjPut => {
                            const node = adjustmentsForHedgesP.items
                                .find(adjPitem => adjPitem.data === adjPut);

                            if (!isVoid(node)) {
                                node.selected = true;
                            }
                        });
                    } catch {
                        //
                    }


                    try {
                        hedgeBoardSettings.hedgesCall.forEach(callHedgeFav => {
                            const hedges = hedgesForHedgesC.items.flatMap(x => x.items);
                            const node = hedges.find(h => h.data === callHedgeFav);
                            if (!isVoid(node)) {
                                node.selected = true;
                            }
                        });
                    } catch {
                        //
                    }


                    try {
                        hedgeBoardSettings.hedgesPut.forEach(putHedgeFav => {
                            const hedges = hedgesForHedgesP.items.flatMap(x => x.items);
                            const node = hedges.find(h => h.data === putHedgeFav);
                            if (!isVoid(node)) {
                                node.selected = true;
                            }
                        });
                    } catch {
                        //
                    }


                    try {
                        hedgeBoardSettings.offsetsCall.forEach(adjCall => {
                            const node = callOffsets.items
                                .find(adjCitem => adjCitem.data === adjCall);

                            if (!isVoid(node)) {
                                node.selected = true;
                            }
                        });
                    } catch {
                        //
                    }


                    try {
                        hedgeBoardSettings.offsetsPut.forEach(adjPut => {
                            const node = putOffsets.items
                                .find(adjPitem => adjPitem.data === adjPut);

                            if (!isVoid(node)) {
                                node.selected = true;
                            }
                        });
                    } catch {
                        ///
                    }
                }

            } finally {
                this.isLoading = false;
                this._changeDetector.detectChanges();
            }
        });


    }

    onSettingsSelectionChanged($event: any) {
        // console.log($event);
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
        this.treeItems = [];
        this.hedgeBoardTree = [];
        this.portfolioList = [];
        this.selectedPortfolio = undefined;
    }

    @DetectMethodChanges()
    onCancelClick() {
        this.visible = false;
    }

    onSaveClick(tree: DxTreeViewComponent, hedgeBoardTree: DxTreeViewComponent) {

        this.savePackageSettings(tree);

        this.saveHedgeBoardSettings(hedgeBoardTree);

        this._toastr.success('Settings Saved');
    }

    private saveHedgeBoardSettings(tree: DxTreeViewComponent) {

        //
        // Validate no duplication
        //

        // Call Hedges
        const callHedgeNodes = tree.instance
            .getSelectedNodes()
            .map(x => x.itemData)
            .filter(x => x.type === 'hedge_Call');

        const distinctCallHedges = callHedgeNodes.map(x => x.text).filter( (value, index, array) => {
            return array.indexOf(value) === index;
        });

        if (callHedgeNodes.length !== distinctCallHedges.length) {
            this._toastr.error('Hedges With Duplicate Names Are Not Allowed', 'Call Hedges');
            return;
        }

        // Put Hedges
        const putHedgeNodes = tree.instance.getSelectedNodes()
            .map(x => x.itemData)
            .filter(x => x.type === 'hedge_Put');

        const distinctPutHedges = putHedgeNodes
            .map(x => x.text).filter((value, index, array) => {
                return array.indexOf(value) === index;
            });

        if (putHedgeNodes.length !== distinctPutHedges.length) {
            this._toastr.error('Hedges With Duplicate Names Are Not Allowed', 'Put Hedges');
            return;
        }

        // Templates
        const templateNodes = tree.instance.getSelectedNodes()
            .map(x => x.itemData)
            .filter(x => x.type === 'template');

        const distinctTemplates = templateNodes
            .map(x => x.text).filter((value, index, array) => {
                return array.indexOf(value) === index;
            });

        if (templateNodes.length !== distinctTemplates.length) {
            this._toastr.error('Templates With Duplicate Names Are Not Allowed', 'Hedge Board');
            return;
        }

        const templates = [];
        const adjustmentsCall = [];
        const adjustmentsPut = [];
        const hedgesCall = [];
        const hedgesPut = [];
        const offsetsCall = [];
        const offsetsPut = [];

        tree.instance.getSelectedNodes()
            .map(x => x.itemData)
            .filter(x => !isVoid(x.type) && !isVoid(x.data))
            .forEach(node => {
                if (node.type === 'template') {
                    templates.push(node.data);
                } else if (node.type === 'adjustment_Call') {
                    adjustmentsCall.push(node.data);
                } else if (node.type === 'adjustment_Put') {
                    adjustmentsPut.push(node.data);
                } else if (node.type === 'hedge_Call') {
                    hedgesCall.push(node.data);
                } else if (node.type === 'hedge_Put') {
                    hedgesPut.push(node.data);
                } else if (node.type === 'offsets_call') {
                    offsetsCall.push(node.data);
                } else if (node.type === 'offsets_put') {
                    offsetsPut.push(node.data);
                }
            });

        const settings: HedgeBoardSettings = {
            userId: this.selectedPortfolio?.userId,
            portfolioId: this.selectedPortfolio?.id,
            templates: templates,
            adjustmentCall: adjustmentsCall,
            adjustmentPut: adjustmentsPut,
            hedgesCall: hedgesCall,
            hedgesPut: hedgesPut,
            offsetsPut,
            offsetsCall
        };

        this._pkgCmprsnSettingsService.saveHedgeBoardSettings(settings);
    }

    private savePackageSettings(tree: DxTreeViewComponent) {
        //
        // Validate no duplication
        //

        // Call Hedges
        const callHedgeNodes = tree.instance.getSelectedNodes()
            .map(x => x.itemData)
            .filter(x => x.type === 'hedge_Call');

        const distinctCallHedges = callHedgeNodes.map(x => x.text).filter( (value, index, array) => {
            return array.indexOf(value) === index;
        });

        if (callHedgeNodes.length !== distinctCallHedges.length) {
            this._toastr.error('Hedges With Duplicate Names Are Not Allowed', 'Call Hedges');
            return;
        }

        // Put Hedges
        const putHedgeNodes = tree.instance.getSelectedNodes()
            .map(x => x.itemData)
            .filter(x => x.type === 'hedge_Put');

        const distinctPutHedges = putHedgeNodes
            .map(x => x.text).filter((value, index, array) => {
                return array.indexOf(value) === index;
            });

        if (putHedgeNodes.length !== distinctPutHedges.length) {
            this._toastr.error('Hedges With Duplicate Names Are Not Allowed', 'Put Hedges');
            return;
        }

        // Templates
        const templateNodes = tree.instance.getSelectedNodes()
            .map(x => x.itemData)
            .filter(x => x.type === 'template');

        const distinctTemplates = templateNodes
            .map(x => x.text).filter((value, index, array) => {
                return array.indexOf(value) === index;
            });

        if (templateNodes.length !== distinctTemplates.length) {
            this._toastr.error('Templates With Duplicate Names Are Not Allowed', 'Templates');
            return;
        }

        const templates = [];
        const adjustmentsCall = [];
        const adjustmentsPut = [];
        const hedgesCall = [];
        const hedgesPut = [];

        tree.instance.getSelectedNodes()
            .map(x => x.itemData)
            .filter(x => !isVoid(x.type) && !isVoid(x.data))
            .forEach(node => {
                if (node.type === 'template') {
                    templates.push(node.data);
                } else if (node.type === 'adjustment_Call') {
                    adjustmentsCall.push(node.data);
                } else if (node.type === 'adjustment_Put') {
                    adjustmentsPut.push(node.data);
                } else if (node.type === 'hedge_Call') {
                    hedgesCall.push(node.data);
                } else if (node.type === 'hedge_Put') {
                    hedgesPut.push(node.data);
                }
            });

        const settings: PackageComparisonSettings = {
            userId: this.selectedPortfolio?.userId,
            portfolioId: this.selectedPortfolio?.id,
            templates: templates,
            adjustmentCall: adjustmentsCall,
            adjustmentPut: adjustmentsPut,
            hedgesCall: hedgesCall,
            hedgesPut: hedgesPut
        };

        this._pkgCmprsnSettingsService.save(settings);
    }

    private async getTemplateNodes(portfolio: ApgPortfolio): Promise<any> {
        const cfg :ServiceConfiguration = {
            userId: portfolio.userId,
            userName: portfolio.userName,
            orientation: null
        };

        await this._cashflowTplService.configure(cfg);

        let templates: any[] = [];

        const lut = this._cashflowTplService.getLastUsedTemplate(portfolio.id);
        const defaultTemplate = this._cashflowTplService.getOrCreateDefaultTemplate(
            lut.underlying,
            lut.strategy
        );
        templates =this._cashflowTplService
            .getTemplates()
            .filter(tpl => tpl.underlying === lut?.underlying);
        if (templates.findIndex(x => x.templateId.startsWith('----')) < 0) {
            templates.push(defaultTemplate);
        }

        templates = Enumerable.from(templates)
            .orderBy(x => x.templateId)
            .select(x => {
                return {
                    id: x.templateId,
                    text: x.templateName,
                    type: 'template',
                    data: x.templateId
                }
            })
            .toArray();

        const node = {
            id: 'tpls',
            text: 'Templates',
            items: templates
        };

        return node;
    }

    private getHedgeNodes(portfolio: ApgPortfolio, type: 'Call' | 'Put'): any {

        const userId = portfolio?.userId;

        const templates = type === 'Call'
             ? this._opgTemplatesService.getCallTemplates(userId, true)
            : this._opgTemplatesService.getPutTemplates(userId, true);

        const groups = Enumerable.from(templates)
            .select(x => {

                const id = x.templateId;

                const text = x.templateName;

                const items = Enumerable.from(x.descriptors)
                    .select(d => {
                        const id = d.strategyId;
                        const text = d.strategyName;
                        const data = d.strategyId;

                        return {
                            id,
                            text,
                            data,
                            type: 'hedge_' + type
                        };
                    }).toArray();

                return {
                    id,
                    text,
                    items
                };

            }).toArray();


        const text = type === 'Call' ? 'Call Hedges'  : 'Put Hedges';

        const node = {
            id: 'hedges_' + text,
            text,
            items: groups
        };

        return node;
    }

    getPortfolioDisplayName(data) {
    }

}