<dx-popup
  (onHidden)="onHidden()"
  (onShown)="onShown()"
  [closeOnOutsideClick]="false"
  [dragEnabled]="true"
  [resizeEnabled]="true"
  [showTitle]="true"
  [visible]="isVisible"
  [height]="456"
  title="Application Settings"
  [width]="1100"
>
  <div *dxTemplate="let data of 'content'" style="position: relative">
    
    <div class="container">
      
      <div class="main-area">
        
        <fieldset class="parameters timezone">
          <legend>Default Timestamps Zone</legend>
          <ets-timezone-picker
            (timezoneChange)="this.onTimezoneChanged($event)"
            [(timezone)]="this.timestampsDefaultZone"
            [showBorder]="false"
            [showCaption]="false">
          </ets-timezone-picker>
        </fieldset>
        
        <div class="row">
          <fieldset class="parameters apg">
            <legend>Adjustment Pricing Grid Default Settings</legend>
            
            <div class="container">
              
              <div>
                <fieldset class="col asset">
                  <legend>Asset & Strategy</legend>
                  
                  <div class="setting">
                    <p class="label">Asset</p>
                    <div class="input">
                      <ets-symbol-picker
                        (instrumentSelected)="this.adjustmentPricingGrid.tradingInstrument = $event"
                        [selectedInstrument]="this.adjustmentPricingGrid?.tradingInstrument"
                        [showLastPrice]="false"
                      >
                      </ets-symbol-picker>
                    </div>
                  </div>
                  
                  <div class="setting">
                    <p class="label">Strategy</p>
                    <div class="input">
                      <dx-select-box
                        [(value)]="this.adjustmentPricingGrid.defaultStrategy"
                        [items]="this.adjustmentPricingGrid.strategies"
                      >
                      </dx-select-box>
                    </div>
                  </div>
                
                </fieldset>
                
                <fieldset class="col">
                  <legend>Margin</legend>
                  <dx-check-box [(value)]="this.adjustmentPricingGrid.useMarginEfficientAdjustment"
                                [disabled]="this.adjustmentPricingGrid.useCharlesSchwabFix"
                                text="Margin Efficient Orders"
                  ></dx-check-box>
                </fieldset>
                
                <fieldset class="col">
                  <legend>Charles Schwab</legend>
                  <dx-check-box
                    (onValueChanged)="this.onCharlesSchwabFixChanged()"
                    [(value)]="this.adjustmentPricingGrid.useCharlesSchwabFix"
                    text="Use Charles Schwab Fix"
                  ></dx-check-box>
                </fieldset>
              </div>
              
              
              <div>
                <fieldset class="col tweaks">
                  <legend>Misc.</legend>
                  
                  <!-- prefix templates -->
                  <div class="setting">
                    <dx-check-box
                      [(value)]="this.adjustmentPricingGrid.prefixTemplates"
                      text="Prefix Templates">
                    </dx-check-box>
                  </div>
                  
                  <!-- match expirations -->
                  <div class="setting">
                    <dx-check-box
                      [(value)]="this.adjustmentPricingGrid.matchExpirations"
                      text="Match Expirations">
                    </dx-check-box>
                  </div>
                  
                  <!-- Link strikes between sides -->
                  <div class="setting">
                    <dx-check-box
                      [(value)]="this.adjustmentPricingGrid.linkStrikes"
                      text="Link Strikes Between Sides">
                    </dx-check-box>
                  </div>
                  
                  <!-- checklist drafts -->
                  <div *ngIf="this.adjustmentPricingGrid.showChecklistDraftsAvailable" class="setting">
                    <dx-check-box
                      [(value)]="this.adjustmentPricingGrid.showChecklistDrafts"
                      text="Show Checklist Drafts">
                    </dx-check-box>
                  </div>
                
                </fieldset>
                
                <fieldset class="col atm-warning">
                  <legend>ATM Warning Mode</legend>
                  <dx-select-box
                    [items]="this.adjustmentPricingGrid.atmWarningModes"
                    [(value)]="this.adjustmentPricingGrid.atmWarningMode"
                  ></dx-select-box>
                </fieldset>
                
              </div>
              
              <fieldset class="col smart-expiration last">
                <legend>Expiration Smart Mode</legend>
                
                <table>
                  <thead>
                  <tr>
                    <th class="header" colspan="4">Expiration Smart Mode</th>
                  </tr>
                  </thead>
                  <tbody>
                  <!-- Spread -->
                  <tr class="row">
                    <td class="label">&nbsp;</td>
                    <td class="box">Back</td>
                    <td class="box">Forth</td>
                    <td class="enabled">&nbsp;</td>
                  </tr>
                  <tr class="row">
                    <td class="label">Spread</td>
                    <td class="box">
                      <div>
                        <dx-number-box
                          [(value)]="this.adjustmentPricingGrid?.expirationSmartMode.spread.back"
                          [disabled]="!this.adjustmentPricingGrid?.expirationSmartMode.spread.isEnabled"
                        >
                        </dx-number-box>
                      </div>
                    </td>
                    <td class="box">
                      <div>
                        <dx-number-box
                          [(value)]="this.adjustmentPricingGrid?.expirationSmartMode.spread.forth"
                          [disabled]="!this.adjustmentPricingGrid?.expirationSmartMode.spread.isEnabled"
                        >
                        </dx-number-box>
                      </div>
                    </td>
                    <td class="enabled">
                      <dx-check-box
                        (onValueChanged)="this.onChanges()"
                        [(value)]="this.adjustmentPricingGrid?.expirationSmartMode.spread.isEnabled"
                      >
                      </dx-check-box>
                    </td>
                  </tr>
                  
                  <!-- 2nd Spread -->
                  <tr class="row">
                    <td class="label">2nd Spread</td>
                    <td class="box">
                      <div>
                        <dx-number-box
                          [(value)]="this.adjustmentPricingGrid?.expirationSmartMode.secondSpread.back"
                          [disabled]="!this.adjustmentPricingGrid?.expirationSmartMode.secondSpread.isEnabled"
                        >
                        </dx-number-box>
                      </div>
                    </td>
                    <td class="box">
                      <div>
                        <dx-number-box
                          [(value)]="this.adjustmentPricingGrid?.expirationSmartMode.secondSpread.forth"
                          [disabled]="!this.adjustmentPricingGrid?.expirationSmartMode.secondSpread.isEnabled"
                        >
                        </dx-number-box>
                      </div>
                    </td>
                    <td class="enabled">
                      <dx-check-box
                        (onValueChanged)="this.onChanges()"
                        [(value)]="this.adjustmentPricingGrid?.expirationSmartMode.secondSpread.isEnabled"
                      >
                      </dx-check-box>
                    </td>
                  </tr>
                  
                  <!-- Protective Option -->
                  <tr class="row">
                    <td class="label">Protective Option</td>
                    <td class="box">
                      <div>
                        <dx-number-box
                          [(value)]="this.adjustmentPricingGrid?.expirationSmartMode.protectiveOption.back"
                          [disabled]="!this.adjustmentPricingGrid?.expirationSmartMode.protectiveOption.isEnabled"
                        >
                        </dx-number-box>
                      </div>
                    </td>
                    <td class="box">
                      <div>
                        <dx-number-box
                          [(value)]="this.adjustmentPricingGrid?.expirationSmartMode.protectiveOption.forth"
                          [disabled]="!this.adjustmentPricingGrid?.expirationSmartMode.protectiveOption.isEnabled"
                        >
                        </dx-number-box>
                      </div>
                    </td>
                    <td class="enabled">
                      <dx-check-box
                        (onValueChanged)="this.onChanges()"
                        [(value)]="this.adjustmentPricingGrid?.expirationSmartMode.protectiveOption.isEnabled"
                      >
                      </dx-check-box>
                    </td>
                  </tr>
                  
                  <!-- 2nd Protective Option -->
                  <tr class="row">
                    <td class="label">2nd Protective Option</td>
                    <td class="box">
                      <div>
                        <dx-number-box
                          [(value)]="this.adjustmentPricingGrid?.expirationSmartMode.secondProtectiveOption.back"
                          [disabled]="!this.adjustmentPricingGrid?.expirationSmartMode.secondProtectiveOption.isEnabled"
                        >
                        </dx-number-box>
                      </div>
                    </td>
                    <td class="box">
                      <div>
                        <dx-number-box
                          [(value)]="this.adjustmentPricingGrid?.expirationSmartMode.secondProtectiveOption.forth"
                          [disabled]="!this.adjustmentPricingGrid?.expirationSmartMode.secondProtectiveOption.isEnabled"
                        >
                        </dx-number-box>
                      </div>
                    </td>
                    <td class="enabled">
                      <dx-check-box
                        (onValueChanged)="this.onChanges()"
                        [(value)]="this.adjustmentPricingGrid?.expirationSmartMode.secondProtectiveOption.isEnabled"
                      >
                      </dx-check-box>
                    </td>
                  </tr>
                  </tbody>
                </table>
              
              </fieldset>
            
            </div>
          
          </fieldset>
          
          <div class="expected-move">
            <expected-move-settings-component #ems></expected-move-settings-component>
          </div>
        </div>
        
      </div>
      
      <div class="buttons">
        
        <dx-button
          (onClick)="saveChangesAndClose()"
          [width]="120"
          text="Save Changes"
        >
        </dx-button>
        
        <dx-button
          (onClick)="close()"
          [width]="120"
          text="Discard Changes"
        >
        </dx-button>
      </div>
    
    </div>
    
    <dx-load-panel
      [visible]="isLoading"
      style="margin: auto; vertical-align: middle"
    >
    </dx-load-panel>
  
  </div>
</dx-popup>
