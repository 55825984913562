import {Injectable} from '@angular/core';
import {DateTime} from "luxon";

export const MaintenanceHour = 17;

export const MaintenanceStartMinute = 0;

export const MaintenanceEndMinute = 45;

@Injectable({providedIn: 'root'})
export class MaintenanceScreenService {
    constructor() {
    }

    isMaintenanceTime() : boolean {

        if (location.hostname === 'localhost') {
            return false;
        }

        const currentEstTime = DateTime.local().setZone('America/New_York');

        let maintenanceWindow = currentEstTime.hour === MaintenanceHour;

        if (maintenanceWindow) {
            maintenanceWindow = currentEstTime.minute >= MaintenanceStartMinute && currentEstTime.minute < MaintenanceEndMinute;
            if (maintenanceWindow) {
                return true;
            }
        }
        return false;
    }
}