import {NgModule} from '@angular/core';
import {EtsDispositionReportsModule} from '../disposition-reports/disposition-reports.module';
import {SessionHistoryModule} from '../session-history/session-history.module';
import {EtsRiskManagementModule} from '../risk-management/risk-management.module';
import {EtsAlertsModule} from '../alerts/alerts.module';
import {EtsSimexSettingsModule} from '../simex-settings/simex-settings.module';
import {EtsStrategyCommanderModule} from '../strategy-commander/strategy-commander.module';
import {UnaccountedDataModule} from '../unaccounted-data/unaccounted-data.module';
import {ClearTradingDataModule} from '../clear-trading-data/clear-trading-data.module';
import {ArchivedPositionsModule} from '../archived-positions/archived-positions.module';
import {HostMenuComponent} from './host-menu.component';
import {CommonModule} from '@angular/common';
import {TriggersModule} from '../triggers/triggers.module';
import {AppSettingsModule} from 'projects/shared-components/app-settings/app-settings.module';
import {
    StrategySessionHistoryModule
} from 'projects/shared-components/strategy-session-history/strategy-session-history.module';
import {EtsRolloverSchedulerModule} from '../rollover-sheduler/rollover-scheduler.module';
import {SyncOverrideDialogModule} from 'projects/shared-components/sync-override-dialog/sync-override-dialog.module';
import {DevExtremeModule} from 'projects/shared-components/devextreme.module';
import {ApgDefaultsDialogModule} from 'projects/shared-components/apg-defaults-dialog/apg-defaults-dialog.module';
import {OptionsPricingGridModule} from "../../../../shared-components/options-pricing-grid/options-pricing-grid.module";
import {QuoteGeneratorModule} from "../../../../shared-components/quote-generator/quote-generator.module";

@NgModule({
    imports: [
        CommonModule,
        DevExtremeModule,
        EtsDispositionReportsModule,
        SessionHistoryModule,
        EtsRiskManagementModule,
        EtsAlertsModule,
        EtsSimexSettingsModule,
        EtsStrategyCommanderModule,
        UnaccountedDataModule,
        ClearTradingDataModule,
        ArchivedPositionsModule,
        TriggersModule,
        AppSettingsModule,
        StrategySessionHistoryModule,
        EtsRolloverSchedulerModule,
        SyncOverrideDialogModule,
        ApgDefaultsDialogModule,
        OptionsPricingGridModule,
        QuoteGeneratorModule
    ],
    exports: [HostMenuComponent],
    declarations: [HostMenuComponent],
    providers: [
        {provide: 'WINDOW', useValue: window}
    ]
})
export class WebtraderHostMenuModule {
}
