<dx-popup
  [closeOnOutsideClick]="false"
  [dragEnabled]="true"
  [resizeEnabled]="false"
  [showTitle]="true"
  [visible]="this.visible"
  [shading]="false"
  [width]="480"
  [height]="350"
  [title]="this.mode === 'History' ? 'Available Datasets'  : 'Available Scripts'"
  (onHidden)="this.onClose()"
>
  <div *dxTemplate="let data of 'content'" class="content" #content>
    
    <ng-container *ngIf="this.mode === 'History'">
      <div class="history-list">
        <table style="width: 100%">
          <thead>
          <tr style="font-weight: bold; font-size: 1.1em">
            <td class="cell left-align">Dataset</td>
            <td class="cell">Start Date</td>
            <td class="cell">End Date</td>
            <td class="cell"># of Ticks</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let dataset of this.datasets">
            <td class="cell left-align">
              <a href="javascript:void(null);" (click)="this.onDatasetSelected(dataset)">{{dataset.name}}</a>
            </td>
            <td class="cell">{{ dataset.startPoint | date }}</td>
            <td class="cell">{{ dataset.endPoint | date }}</td>
            <td class="cell">{{ dataset.totalPoints }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    
    <ng-container *ngIf="this.mode === 'Script'">
      
      <div class="script-list">
        <dx-scroll-view>
          <table style="width: 100%">
            <thead>
            <tr style="font-weight: bold; font-size: 1.1em">
              <td class="cell left-align" style="width: 31%">Script Name</td>
              <td class="cell" style="width: 51%">Description</td>
              <td class="cell" style="width: 4%">&nbsp;</td>
              <td class="cell" style="width: 4%">&nbsp;</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let script of this.scripts">
              <td class="cell left-align">
                <a href="javascript:void(null);" (click)="this.onScriptSelected(script)">{{script.name}}</a>
              </td>
              <td class="cell">{{ script.description }}</td>
              <td (click)="this.editScript(script)" class="cell edit-btn">
                <i class="fas fa-edit"></i>
              </td>
              <td (click)="this.deleteScript(script)" class="cell edit-btn">
                <i class="fas fa-trash-alt"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </dx-scroll-view>
      </div>
      
    </ng-container>
    
    <hr style="width: 95%; margin-top: 3px; margin-bottom: 3px">
    
    <div class="uploader">
      <dx-button text="Upload File" (onClick)="this.showFileUploader()"></dx-button>
    </div>
    
    <dx-load-panel [visible]="this.isLoading" [position]="{my: 'center', at:'center', of: content}"></dx-load-panel>
    
  </div>
  
</dx-popup>

<dx-popup [(visible)]="this.fileUploaderVisible"
          [resizeEnabled]="true"
          [dragEnabled]="true"
          width="400px"
          height="160"
          title="Upload File"
          (onHidden)="this.onFileUploaderClosed()"
>
  <div *dxTemplate="let data of 'content'" class="file-uploader" #uploader>
    
    <div class="name-desc">
      <div class="name">
        <dx-text-box placeholder="Script Name" [(value)]="this.uploadScriptName" (onValueChanged)="this.onChange()"></dx-text-box>
      </div>
      <div class="description">
        <dx-text-area placeholder="Description" [(value)]="this.uploadScriptDescription" (onValueChanged)="this.onChange()"></dx-text-area>
      </div>
    </div>
    
    <div class="select-file">
      <dx-file-uploader [(value)]="this.files"
                        (onFilesUploaded)="this.onUploaded($event)"
                        (onUploadStarted)="this.onUploadStarted()"
                        [uploadFailedMessage]="null"
                        [showFileList]="false"
                        [labelText]="this.uploadResultText"
                        [disabled]="!this.canUploadFile()"
      ></dx-file-uploader>
    </div>
    
    <dx-load-panel [visible]="this.isFileUploading" [position]="{my: 'center', at:'center', of: uploader}"></dx-load-panel>
  </div>
</dx-popup>


<ets-generic-confirmation-dialog title="Please Confirm Action">
</ets-generic-confirmation-dialog>