import {Injectable} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {LastQuoteCacheService} from '../../last-quote-cache.service';
import {MessageBusService} from '../../message-bus.service';
import {QuoteDto} from '../../shell-communication/dtos/quote-dto.class';
import {isVoid, roundToStep} from '../../utils';
import {AtmStrikeChangedEvent} from "./atm-strike-changed-event";
import {AtmWatcher} from "./atm-watcher";


@Injectable({
    providedIn: 'root'
})
export class AtmStrikeService {
    constructor(
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _messageBus: MessageBusService) {

    }

    private readonly _watchersByTicker: { [ix: string]: AtmWatcher } = {};

    //
    init(): Promise<void> {
        const start = Date.now();

        this._messageBus.of<QuoteDto[]>('QuoteDto')
            .subscribe(msg => this.onQuote(msg.payload));

        const end = Date.now();

        this._messageBus.publish({
            topic: 'ServiceInitialized',
            payload: {
                time: end-start,
                name: 'AtmStrikes'
            }
        });

        return Promise.resolve();
    }

    //
    watch(underlying: string) {

        let watcher = this._watchersByTicker[underlying];

        if (isNullOrUndefined(watcher)) {

            watcher = new AtmWatcher(underlying);

            watcher.atmChanged$.subscribe(data => {
                this._messageBus.publishAsync({
                    topic: 'AtmStrikeChangedEvent',
                    payload: data
                });
            })

            this._watchersByTicker[underlying] = watcher;

            this._lastQuoteCache.subscribeIfNotYet(underlying);

            const lq = this._lastQuoteCache.getLastQuote(underlying);

            if (lq) {
                watcher.onQuote(lq);
            }

            return;
        }

        watcher.broadcast();

    }

    //
    calculateCurrentAtm(underlying: string, lastPx: number): number {
        const increment = underlying === 'SPX' ? 5 : 1;
        return roundToStep(lastPx, increment);
    }

    //
    getCurrentAtm(underlying: string): number {

        const watcher = this._watchersByTicker[underlying];

        if (isVoid(watcher)) {
            return undefined;
        }

        return watcher.atm;

    }

    //
    private onQuote(quotes: QuoteDto[]) {
        quotes.forEach(q => this.deliverQuoteToWatch(q));
    }

    //
    private deliverQuoteToWatch(quote: QuoteDto) {
        const watcher = this._watchersByTicker[quote.ticker];

        if (isNullOrUndefined(watcher)) {
            return;
        }

        watcher.onQuote(quote);
    }

    private fakeEmitter(underlying: string, atm: number) {

        const data: AtmStrikeChangedEvent = {
            currentAtm: atm,
            previousAtm: undefined,
            underlying: underlying
        };

        this._messageBus.publishAsync({
            topic: 'AtmStrikeChangedEvent',
            payload: data
        });
    }
}