import {TradingInstrument} from "../trading-instruments/trading-instrument.class";
import {isVoid} from "../utils";
import * as Enumerable from "linq";
import {QuoteGeneratorLoadedStreamDto} from "../shell-communication/shell-dto-protocol";
import {TradingInstrumentsService} from "../trading-instruments/trading-instruments-service.interface";

export type QuoteGeneratorMode = 'Manual' | 'Random' | 'History' | 'Script';

export type QuoteGeneratorQuoteSource = 'Theoretical' | 'Live' | 'Historical';

export class QuoteStream {

    constructor(tradingInstrument: TradingInstrument) {
        this._tradingInstrument = tradingInstrument;

        const steps = [
            0.01,
            0.02,
            0.03,
            0.04,
            0.05,
            0.10,
            0.15,
            0.20,
            0.25,
            0.30,
            0.35,
            0.40,
            0.45,
            0.50,
            1.00,
            2.00,
            3.00,
            4.00,
            5.00,
            6.00,
            7.00,
            8.00,
            9.00,
            10.00,
            20.00,
            30.00,
            40.00,
            50.00,
            60.00,
            70.00,
            80.00,
            90.00,
            10.00,
        ];

        this.quoteStepList = steps;
        
        this.quoteStep = this._tradingInstrument.ticker === 'SPX' ? 10 : 1; // this.quoteStepList[0];
    }

    private readonly _tradingInstrument: TradingInstrument;

    quoteStepList: number[] = [];

    quoteStep: number;

    trendBias = 0;

    startDate: string;

    endDate: string;

    microView: boolean;

    get isShowingAtm(): boolean {
        return this.mode === 'Manual' && this.quoteSource === 'Live';
    };

    menuItems = [
        {text: 'Reset To Market'},
    ]

    get ticker(): string {
        return this._tradingInstrument.ticker;
    }

    get tickerDisplayName(): string {
        return this._tradingInstrument.displayName;
    }

    mode : QuoteGeneratorMode = 'Manual';

    quoteSource: QuoteGeneratorQuoteSource;

    get quoteSourceList(): QuoteGeneratorQuoteSource[] {
        const list: QuoteGeneratorQuoteSource[] = [];
        list.push('Theoretical');
        if (this.mode === 'Manual') {
            list.push('Live');
        }
        // list.push('Historical');
        return list;
    }

    lastPrice = 1000;

    lastTimestamp: string;

    frequency = 500;

    customScript: string;

    savedScript: any;

    progress: number;

    get isManualMode(): boolean {
        return this.mode === 'Manual';
    }

    get isRandomMode(): boolean {
        return this.mode === 'Random';
    }

    get isNavigationalMode(): boolean {
        return !this.isManualMode && !this.isRandomMode;
    }

    get isHistoryMode(): boolean {
        return this.mode === 'History';
    }

    get isScriptMode(): boolean {
        return this.mode === 'Script';
    }

    get isDatasetSelected(): boolean {
        return !isVoid(this.dataset);
    }

    isRunning: boolean;

    dataset: any;

    atmDistance: number;

    toggleRunning() {
        this.isRunning = !this.isRunning;
    }

    static fromDto(dto: QuoteGeneratorLoadedStreamDto, tiService: TradingInstrumentsService): QuoteStream {
        const ti = tiService.getInstrumentByTicker(dto.ticker);
        const stream = new QuoteStream(ti);
        stream.mode = dto.quoteMode as any;
        stream.isRunning = dto.isRunning;
        stream.progress = dto.progress;
        stream.lastPrice = dto.lastQuote?.lastPx;
        stream.frequency = dto.frequency;
        stream.quoteStep = dto.quoteStep;
        stream.quoteSource = dto.quoteSource as any;

        return stream;
    }
}