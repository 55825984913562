import {Environment} from './environment.interface';

export const environment: Environment = {
    production: true,
    runtimeAppId: '', // set at runtime
    runtimeInstanceId: '', // set at runtime
    authServiceUrl: 'https://{{host}}/auth/rest',
    shellRouterUrl: 'https://{{host}}/shell-router/rest',
    bcServiceUrl: 'https://{{host}}/business/rest',
    ccServiceUrl: 'https://{{host}}/client/rest',
    telemetryServiceUrl: 'https://{{host}}/telemetry/rest',
    keepAliveInterval: 10000,
    essReconnectInterval: 15000,
    shellStatusPollingInterval: 5000,
    eventStreamUrl: 'wss://{{host}}:44403/event-stream',
    toastrConfiguration: {
        maxOpened: 1,
        timeOut: 4000,
        extendedTimeOut: 6000,
        positionClass: 'toastr-top-wide-width',
        progressBar: true,
        escapeHtml: false,
        enableHtml: true,
    },
    showDevModule: false,
    productVersion: '2025.03.16',
    agGridLicenseKey: 'For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-12_December_2020_[v2]_MTYwNzczMTIwMDAwMA==fa16f35a173ee0e95e2af4e951739bb4',
    disableOrderConfirmations: true
};
