import {StrategyOrdersAndTradesComponent} from '../strategy-orders-and-trades/strategy-orders-and-trades.component';
import {WorkspacePanelsMenuItemClickedUIMessage} from './panels-menu/workspace-panels-menu-item-clicked-message';
import {WorkspacePanelsMenuService} from './panels-menu/workspace-panels-menu.service';
import {WorkspacePanelsMenu} from './panels-menu/workspace-panels-menu';
import {PanelModel} from './panel.model';
import {WorkspacePanelStateInfo, WorkspaceStateInfo} from './workspace-state-info';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Type,
    ViewChild
} from '@angular/core';
import {QuoteBoardComponent} from '../quote-board/quote-board.component';
import {EtsWorkspaceHeaderComponent} from './header/workspace-header.component';
import {WorkspacePanels} from './panels-menu/workspace-panels';
import {WorkspacePanelsMenuItem} from './panels-menu/workspace-panels-menu-item';
import {ManualPositionsComponent} from '../manual-trading/positions/manual-positions.component';
import {AggregatedPositionsComponent} from '../aggregated-positions/aggregated-positions.component';
import {ChartItemsSelectedEventArgs} from '../pnl-chart/pnl-chart-dialog/chart-items-selected-event-args';
import {PnLChartSubscription} from '../pnl-chart/pnl-chart-subscription';
import {TradingSystemsViewComponent} from '../trading-systems/trading-systems-view.component';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {PriceChartComponent} from '../price-chart/price-chart.component';
import {PnLChart2Component} from '../pnl-chart/pnl-chart-2/pnl-chart-2.component';
import Guid from 'devextreme/core/guid';
import {MessageBusService} from 'projects/shared-components/message-bus.service';
import {SettingsStorageService} from '../settings-storage-service.service';
import {EtsContentPlaceholderDirective} from './content-placeholder.directive';
import {EtsPnLChartStateInfo} from '../pnl-chart/pnl-chart-2/pnl-chart-state-info.interface';
import {DetectMethodChanges, DetectSetterChanges, getPanelStateKey} from 'projects/shared-components/utils';
import {AccountDto} from 'projects/shared-components/shell-communication/dtos/account-dto.class';
import {TerminalDto} from 'projects/shared-components/shell-communication/dtos/terminal-dto.class';
import {StrategyModel} from 'projects/shared-components/strategies/strategy-model';
import {IPanelComponent} from 'projects/shared-components/panels/panel-component.interface';
import * as shortid from 'shortid';
import {TradingInstrument} from 'projects/shared-components/trading-instruments/trading-instrument.class';
import {WorkspaceSecurityContextService} from './workspace-security-context.service';
import {StrategyIssuesPanelComponent} from '../strategy-issues/strategy-issues-panel.component';
import {HpDatasetsPanelComponent} from '../hp-datasets/hp-datasets.component';
import {OpenPositionChartComponent} from '../open-position-chart/open-position-chart.component';
import {PanelSortEventArgs} from './panels-sort/panel-sort-event-args';
import {PositionSizingReportComponent} from '../position-sizing-report/position-sizing-report.component';
import {StrategyTriggersComponent} from '../strategy-triggers/strategy-triggers.component';
import {OptionChainComponent} from '../option-chain/option-chain.component';
import {PortfoliosComponent} from '../portfolios/portfolios.component';
import {MultiTradePadComponent} from '../multi-trade-pad/multi-trade-pad.component';
import {LedgerComponent} from 'projects/webtrader/src/app/ledger/ledger.component';
import {OptionsPricingPadComponent} from '../options-pricing-pad/options-pricing-pad.component';
import {StrategyHedgedPositionsComponent} from '../strategy-hedged-positions/strategy-hedged-positions.component';
import {PortfolioItemsComponent} from '../portfolios/portfolio-items/portfolio-items.component';
import {WatchlistComponent} from '../watchlist/watchlist.component';
import {
    SessionHistoryPanelComponent
} from 'projects/webtrader/src/app/session-history/panel/session-history-panel.component';
import {ManualOrdersAndTradesComponent} from '../manual-trading/orders-and-trades/manual-orders-and-trades.component';
import {
    PortfolioOrdersAndTradesComponent
} from '../manual-trading/orders-and-trades/portfolio-orders-and-trades.component';
import {AdjustmentStrategiesViewComponent} from '../trading-systems/adjustment-strategies-view.component';
import {ParametersPanelComponent} from '../parameters-panel/parameters-panel.component';
import {MarginRequirementsComponent} from '../margin-requirements/margin-requirements.component';
import {ShellMessagesPanelComponent} from '../shell-messages/panel/shell-messages-panel.component';
import {FutureOrdersComponent} from '../manual-trading/orders-and-trades/future-orders.component';
import {TestPanelComponent} from '../test-panel/test-panel.component';
import {AdjustmentControlPanelComponent} from '../adjustment-control-panel/adjustment-control-panel.component';
import {
    AdjustmentPricingGridPanelComponent
} from '../adjustment-pricing-grid/panel/adjustment-pricing-grid-panel.component';
import {
    AdjustmentComparisonGridPanelComponent
} from '../adjustment-pricing-grid/panel/adjustment-comparison-grid-panel.component';
import {AutomationCpComponent} from '../automation-cp/automation-cp.component';
import {ChecklistEditorComponent} from '../checklist-editor/checklist-editor.component';
import {StrategyMessagesPanelComponent} from '../strategy-messages/strategy-messages-panel.component';
import {CashFlowTrackingPanelComponent} from '../cashflow-tracking/cashflow-tracking-panel.component';
import {ResourceEditorComponent} from "../resource-editor/resource-editor.component";
import { HedgingGridPanelComponent } from '../hedging-grid/hedging-grid-panel.component';
import {PackageComparisonPanelComponent} from "../package-comparison/panel/package-comparison-panel.component";
import {PositionModifierComponent} from "../options-pricing-grid/position-modifier.component";
import {OptionsPricingGridComponent} from "../options-pricing-grid/options-pricing-grid.component";
import {LocationService} from "../location.service";

const ADD_PANEL_CTX_MENU_TYPE = 'add-panel';
const TABS_LIST_CTX_MENU_TYPE = 'panels-list';


@Component({
    selector: 'ets-workspace',
    templateUrl: './workspace.component.html',
    styleUrls: ['./workspace.component.scss'],
    providers: [WorkspaceSecurityContextService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        public readonly securityContext: WorkspaceSecurityContextService,
        private _messageBus: MessageBusService,
        private _panelsMenuService: WorkspacePanelsMenuService,
        private _componentFactory: ComponentFactoryResolver,
        private _toastr: ToastrService,
        private _layoutService: SettingsStorageService,
        private _changeDetector: ChangeDetectorRef,
        private _locationService: LocationService
    ) {

    }

    private _unsubscriber: Subject<any>;
    private _selectedPanel: PanelModel;


    @Input() panels: PanelModel[] = [];
    @Input() layoutTabId: string;
    @Input() workspaceId: string;
    @Input() isInitial: boolean;

    @Output() expandAreaRequest = new EventEmitter();
    @Output() splitArea = new EventEmitter();

    @ViewChild('ctx', {static: true}) contextMenu: ElementRef;
    @ViewChild(EtsContentPlaceholderDirective, {static: true}) contentPlaceholder: EtsContentPlaceholderDirective;
    @ViewChild(EtsWorkspaceHeaderComponent, {static: true}) workspaceHeader: EtsWorkspaceHeaderComponent;

    isWorkspaceExpanded = false;

    addPanelContextMenu: WorkspacePanelsMenu;

    tabsMenuContextMenu: WorkspacePanelsMenu;

    isChartPickerDialogVisible = false;

    isPanelSortDialogVisible: boolean;

    get selectedPanel(): PanelModel {
        return this._selectedPanel;
    }

    @DetectSetterChanges()
    set selectedPanel(v: PanelModel) {

        this.deactivateAllPanels(this.panels);

        if (!v) {
            return;
        }

        v.isActive = true;

        this._selectedPanel = v;

        this.updateContent(v);

        this.tabsMenuContextMenu.items.forEach(x => {
            x.isActive = x.id === v.panelId;
        });

        if (!v.isRestoring) {
            this.saveState();
        }

        v.isRestoring = null;
    }


    restoreState(stateInfo: WorkspaceStateInfo): void {
        stateInfo.panels.forEach(tab => {
            if (!tab) {
                return;
            }
            this.restorePanelState(tab);
        });
    }


    @DetectMethodChanges()
    onAddPanelContextMenuRequest(ev: MouseEvent): void {
        if (
            !this.addPanelContextMenu.items ||
            this.addPanelContextMenu.items.length === 0 ||
            !this.addPanelContextMenu.items.some(x => x.isAvailable())
        ) {
            return;
        }

        this.locateMenuOnScreen(ev, this.addPanelContextMenu);

        const cxm = this.addPanelContextMenu;
        if (cxm.isOpened) {
            cxm.isOpened = false;
        } else {
            cxm.isOpened = true;
            this.tabsMenuContextMenu.isOpened = false;
        }
    }


    @DetectMethodChanges()
    onTabsMenuContextMenuRequest(ev: MouseEvent): void {
        if (
            !this.tabsMenuContextMenu.items ||
            this.tabsMenuContextMenu.items.length === 0
        ) {
            return;
        }

        this.locateMenuOnScreen(ev, this.tabsMenuContextMenu);

        const cxm = this.tabsMenuContextMenu;
        if (cxm.isOpened) {
            cxm.isOpened = false;
        } else {
            this.tabsMenuContextMenu.isOpened = true;
            this.addPanelContextMenu.isOpened = false;
        }
    }


    @DetectMethodChanges()
    onSortPanelsRequest(): void {
        if (this.panels.length <= 1) {
            return;
        }

        this.isPanelSortDialogVisible = !this.isPanelSortDialogVisible;
    }


    @DetectMethodChanges()
    onSortPanelsCompleted(ev: PanelSortEventArgs) {

        if (ev) {
            if (ev.workspaceId === this.workspaceId) {
                ev.panels.forEach(p => {
                    const oldPanelIx = this.panels.findIndex((panel) => panel.panelId === p.panelId);
                    if (oldPanelIx < 0) {
                        return;
                    }
                    const panelToReplace = this.panels[p.newIx];
                    const panelToSet = this.panels[oldPanelIx];
                    this.panels[p.newIx] = panelToSet;
                    this.panels[oldPanelIx] = panelToReplace;
                });

                this.saveState();
            }
        }

        this.isPanelSortDialogVisible = false;

    }


    onSplitRquest(args: MouseEvent) {

        args.preventDefault();

        if (!this.securityContext.canCollapseWorkspace && !this.securityContext.canSplitWorkspace) {
            return;
        }

        this.contextMenu.nativeElement.style.left = (args.x - 183) + 'px';
        this.contextMenu.nativeElement.style.top = args.y + 'px';
        this.contextMenu.nativeElement.classList.add('show-menu');
    }


    getStateInfo(): WorkspaceStateInfo {
        const tabs = this.panels.map((panel: PanelModel) => {
            const panelState: WorkspacePanelStateInfo = {
                panelId: panel.panelId,
                header: panel.header,
                typeCode: panel.typeCode,
                isActive: panel.isActive
            };
            return panelState;
        });
        const self = this;

        const state: WorkspaceStateInfo = {
            layoutTabId: self.layoutTabId,
            workspaceId: self.workspaceId,
            panels: tabs
        };

        return state;
    }


    @DetectMethodChanges()
    onExpanded(): void {
        this.expandAreaRequest.emit();
        this.isWorkspaceExpanded = !this.isWorkspaceExpanded;
    }


    @DetectMethodChanges()
    onSymbolLinkChanged(ev: { isLinked: boolean, panel: PanelModel }) {
        const ix = this.panels.indexOf(ev.panel);
        if (this.selectedPanel === ev.panel) {
            const component = ev.panel.instance.instance as IPanelComponent;
            if (component) {
                component.isLinkedToSymbol = ev.isLinked;
            }
        }
    }


    @DetectMethodChanges()
    onPanelClosed(panel: PanelModel): void {
        const ix = this.panels.indexOf(panel);
        this.panels.splice(ix, 1);

        if (this.selectedPanel === panel) {
            this._selectedPanel = null;
        }

        if (panel.isActive && this.panels.length) {
            if (ix >= this.panels.length) {
                this.selectedPanel = this.panels[this.panels.length - 1];
            } else {
                this.selectedPanel = this.panels[ix];
            }
        }

        const key = getPanelStateKey(panel);
        if (key) {
            this._layoutService.removeItem(key);
        }

        const component = panel.instance;
        if (component) {
            component.destroy();
        }

        this.removeMenuItemFromTabsMenu(panel);

        this.saveState();
    }


    onPanelHeaderChanged(panel: PanelModel) {
        this.saveState();
    }


    onChartItemSelected($event: ChartItemsSelectedEventArgs): void {
        if (!$event) {
            console.warn('PnL chart not added. Descriptor is undefined');
            return;
        }

        const guid: string = new Guid().valueOf();

        const subscriptions: PnLChartSubscription[] = [];

        if ($event.chartType === 'Combined') {
            const combinedSubscription = new PnLChartSubscription(
                guid,
                $event.chartTitle
            );

            combinedSubscription.isAccumulated = $event.isAccumulated;

            combinedSubscription.displayName = $event.chartTitle;

            combinedSubscription.subscriptionId = guid;

            $event.items.filter(x => x.categoryLabel === 'account')
                .forEach(x => {
                    combinedSubscription.accounts.push(x.dataObject.accountId);
                });

            $event.items.filter(x => x.categoryLabel === 'terminal')
                .forEach(x => {
                    combinedSubscription.terminals.push(x.dataObject.terminalId);
                });

            $event.items.filter(x => x.categoryLabel === 'tradingInstrument')
                .map(x => {
                    combinedSubscription.tradingInstruments.push(x.dataObject.ticker);
                });

            $event.items.filter(x => x.categoryLabel === 'strategy')
                .map(x => combinedSubscription.strategies.push(x.dataObject.strategyId));

            subscriptions.push(combinedSubscription);
        } else if ($event.chartType === 'Comparison') {

            $event.items.filter(x => x.categoryLabel === 'account')
                .forEach(x => {
                    const dataObject = (x.dataObject as AccountDto);
                    const subscriptionId: string = new Guid().valueOf();
                    const displayName = `${dataObject.accountCode} - Account`;
                    const accounts = [dataObject.accountId];

                    const subs = new PnLChartSubscription(
                        subscriptionId,
                        displayName
                    );
                    subs.isAccumulated = $event.isAccumulated;
                    subs.accounts = accounts;
                    subscriptions.push(subs);
                });

            $event.items.filter(x => x.categoryLabel === 'terminal')
                .forEach(x => {
                    const dataObject = (x.dataObject as TerminalDto);
                    const subscriptionId: string = new Guid().valueOf();
                    const displayName = `${dataObject.displayName} - Terminal`;
                    const terminals = [dataObject.terminalId];

                    const subs = new PnLChartSubscription(
                        subscriptionId,
                        displayName
                    );
                    subs.isAccumulated = $event.isAccumulated;
                    subs.terminals = terminals;
                    subscriptions.push(subs);
                });

            $event.items.filter(x => x.categoryLabel === 'tradingInstrument')
                .map(x => {
                    const dataObject = (x.dataObject as TradingInstrument);
                    const subscriptionId: string = new Guid().valueOf();
                    const displayName = `${dataObject.displayName} - Asset`;
                    const tradingInstruments = [dataObject.ticker];

                    const subs = new PnLChartSubscription(
                        subscriptionId,
                        displayName
                    );
                    subs.isAccumulated = $event.isAccumulated;
                    subs.tradingInstruments = tradingInstruments;
                    subscriptions.push(subs);
                });

            $event.items.filter(x => x.categoryLabel === 'strategy')
                .map(x => {
                    const subscriptionId: string = new Guid().valueOf();
                    const dataObject = x.dataObject as StrategyModel;
                    const displayName = `${dataObject.displayName} - Strategy`;
                    const strategies = [dataObject.strategyId];
                    const subs = new PnLChartSubscription(
                        subscriptionId,
                        displayName
                    );
                    subs.isAccumulated = $event.isAccumulated;
                    subs.strategies = strategies;
                    subscriptions.push(subs);
                });
        }

        const chartState = {
            chartTitle: $event.chartTitle,
            subscriptions,
            panelId: null
        };

        const panel = this.addPnLChartPanel(chartState, null);
        this.selectedPanel = panel;
        if (panel.instance) {
            const comp = panel.instance.instance as PnLChart2Component;
            comp.stateInfo = chartState;
        }
        this.saveState();
    }


    ngOnInit(): void {
        this.addPanelContextMenu = this._panelsMenuService.getMenu();
        this.tabsMenuContextMenu = {isOpened: false, items: [], top: 0, left: 0};

        this._unsubscriber = new Subject<any>();

        this._messageBus
            .of<WorkspacePanelsMenuItemClickedUIMessage>(
                'WorkspacePanelsMenuItemClickedUIMessage'
            )
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => {
                this.onContextMenuItemClicked(x.payload);
            });

        this._messageBus.of<any>('LayoutTabClosed')
            .pipe(
                filter(msg => msg.scopeId === this.layoutTabId)
            )
            .subscribe(x => this.onRemovedFromLayout());
    }


    ngOnDestroy(): void {
        if (this._unsubscriber) {
            this._unsubscriber.next();
            this._unsubscriber.complete();
        }
    }


    ngAfterViewInit(): void {

        this._changeDetector.detach();

        let action: () => void;

        if (this.isInitial) {
            action = () => {
                if (this.securityContext.tradingSystemsViewPanel) {
                    this.onAddPanelContextMenuItemClicked(WorkspacePanels.TradingSystemsView.toString());
                }
                this.isInitial = false;
                this._messageBus.publish({
                    topic: 'InitialWorkspaceInitializedUIMessage',
                    payload: null
                });
            };
        } else {
            action = () => {
                const storageData = this._layoutService.getItem(this.workspaceId);

                if (!storageData) {
                    return;
                }

                const wsInfo = storageData as WorkspaceStateInfo;
                if (!wsInfo) {
                    return;
                }

                try {
                    this.restoreState(wsInfo);
                } catch (e) {
                    this._layoutService.removeItem(this.workspaceId);
                    this._toastr.error('Workspace restored with errors');
                }
            };
        }

        setTimeout(() => {
            action();
            this._changeDetector.detectChanges();
        }, 25);

    }

    @HostListener('window:click') onAnyClick(): void {
        let shouldDetectChanges = false;

        if (this.addPanelContextMenu) {
            if (this.addPanelContextMenu.isOpened) {
                this.addPanelContextMenu.isOpened = false;
                shouldDetectChanges = true;
            }
        }

        if (this.tabsMenuContextMenu) {
            if (this.tabsMenuContextMenu.isOpened) {

                this.tabsMenuContextMenu.isOpened = false;
                shouldDetectChanges = true;
            }
        }

        if (this.contextMenu) {
            const nEl = this.contextMenu.nativeElement as HTMLElement;
            if (nEl.classList.contains('show-menu')) {
                this.contextMenu.nativeElement.classList.remove('show-menu');
                shouldDetectChanges = true;
            }
        }

        if (shouldDetectChanges) {
            this._changeDetector.detectChanges();
        }
    }


    onSplitClicked(direction): void {
        this.splitArea.emit({direction, workspaceId: this.workspaceId});
        this.contextMenu.nativeElement.classList.remove('show-menu');
    }

    private onRemovedFromLayout(): void {

        this._messageBus.publishAsync({
            topic: 'WorkspaceClosed',
            payload: {
                workspaceId: this.workspaceId
            }
        });

        const key = this.workspaceId;
        if (key) {
            this._layoutService.removeItem(key);
        }
    }

    private restorePanelState(stateInfo: WorkspacePanelStateInfo): void {
        let panel: PanelModel;

        switch (stateInfo.typeCode) {
            case WorkspacePanels.QuoteBoard: {
                if (this.securityContext.quoteBoardPanel) {
                    panel = this.addQuoteBoard(stateInfo);
                }
                break;
            }
            case WorkspacePanels.TradingSystemsView: {
                if (this.securityContext.tradingSystemsViewPanel) {
                    panel = this.addTradingSystemsView(stateInfo);
                }
                break;
            }
            case WorkspacePanels.StrategyMessages: {
                if (this.securityContext.strategyMessagesPanel) {
                    panel = this.addStrategyMessagesPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.StrategyOrdersAndTrades: {
                if (this.securityContext.strategyOrdersAndTradesPanel) {
                    panel = this.addStrategyOrdersAndTradesPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.ManualOrdersAndTrades: {
                if (this.securityContext.manualOrdersAndTradesPanel) {
                    panel = this.addManualOrdersAndTradesPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.ManualPositions: {
                if (this.securityContext.manualPositionsPanel) {
                    panel = this.addManualPositionsPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.AggregatedPositions: {
                if (this.securityContext.aggregatedPositionsPanel) {
                    panel = this.addAggregatedPositionsPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.PnLChart: {
                if (this.securityContext.pnlChartPanel) {
                    panel = this.addPnLChartPanel(null, stateInfo);
                }
                break;
            }
            case WorkspacePanels.PriceChart: {
                if (this.securityContext.priceChartPanel) {
                    panel = this.addPriceChartPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.StrategyIssues: {
                if (this.securityContext.strategyIssuesPanel) {
                    panel = this.addStrategyIssuesPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.HpDatasets: {
                if (this.securityContext.hpDatasetsPanel) {
                    panel = this.addHpDatasetsPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.OpenPositionChart: {
                if (this.securityContext.openPositionChart) {
                    panel = this.addOpenPositionChartPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.PositionSizingReport: {
                if (true) {
                    panel = this.addPositionSizingReportPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.StrategyTriggers: {
                if (this.securityContext.strategyTriggers) {
                    panel = this.addStrategyTriggersPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.OptionChain: {
                if (this.securityContext.optionsBoard) {
                    panel = this.addOptionsBoardPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.Portfolios: {
                if (this.securityContext.portfolios) {
                    panel = this.addPortfoliosPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.MultiTradePad: {
                if (this.securityContext.multiTradePad) {
                    panel = this.addMultiTradePadPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.Ledger: {
                if (this.securityContext.ledger) {
                    panel = this.addLedgerPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.OptionsPricingPad: {
                if (this.securityContext.optionsPricingPad) {
                    panel = this.addOptionsPricingPad(stateInfo);
                }
                break;
            }
            case WorkspacePanels.OptionsPricingGrid: {
                if (this.securityContext.optionsPricingGrid) {
                    panel = this.addOptionsPricingGrid(stateInfo);
                }
                break;
            }
            case WorkspacePanels.StrategyHedgedPositions: {
                if (this.securityContext.strategyHedgedPositions) {
                    panel = this.addStrategyHedgedPositions(stateInfo);
                }
                break;
            }
            case WorkspacePanels.PortfolioItems: {
                // if (this.securityContext.strategyHedgedPositions) {
                panel = this.addPortfolioPositions(stateInfo);
                // }
                break;
            }
            case WorkspacePanels.Watchlist: {
                // if (this.securityContext.strategyHedgedPositions) {
                panel = this.addWatchlist(stateInfo);
                // }
                break;
            }
            case WorkspacePanels.SessionHistory: {
                // if (this.securityContext.strategyHedgedPositions) {
                panel = this.addSessionHistory(stateInfo);
                // }
                break;
            }
            case WorkspacePanels.PortfolioOrdersAndTrades: {
                if (this.securityContext.manualOrdersAndTradesPanel) {
                    panel = this.addPortfolioOrdersAndTradesPanel(stateInfo);
                }
                break;
            }
            case WorkspacePanels.AdjustmentStrategies: {
                panel = this.addAdjustmentsStrategiesView(stateInfo);
                break;
            }
            case WorkspacePanels.ParametersPanel: {
                panel = this.addParametersPanel(stateInfo);
                break;
            }
            case WorkspacePanels.MarginRequirements: {
                panel = this.addMarginRequirementsPanel(stateInfo);
                break;
            }
            case WorkspacePanels.ShellMessages: {
                panel = this.addShellMessagesPanel(stateInfo);
                break;
            }
            case WorkspacePanels.FutureOrders: {
                panel = this.addFutureOrdersPanel(stateInfo);
                break;
            }
            case WorkspacePanels.TestPanel: {
                panel = this.addTestPanel(stateInfo);
                break;
            }
            case WorkspacePanels.AdjsutmentControlPanel: {
                panel = this.addAdjustmentControlPanel(stateInfo);
                break;
            }
            case WorkspacePanels.AutomationCp: {
                panel = this.addAutomationCpPanel(stateInfo);
                break;
            }
            case WorkspacePanels.AdjustmentPricingGridPanel: {
                panel = this.addAdjustmentPricingGridPanel(stateInfo);
                break;
            }
            case WorkspacePanels.CashFlowTracking: {
                panel = this.addCashFlowTracking(stateInfo);
                break;
            }
            case WorkspacePanels.AdjustmentComparisonGrid: {
                panel = this.addAdjustmentComparisonGridPanel(stateInfo);
                break;
            }
            case WorkspacePanels.ChecklistEditorPanel: {
                panel = this.addChecklistEditor(stateInfo);
                break;
            }
            case WorkspacePanels.ResourceEditorPanel: {
                panel = this.addResourceEditor(stateInfo);
                break;
            }
            case WorkspacePanels.HedgingGrid: {
                panel = this.addHedgingGrid(stateInfo);
                break;
            }
            case WorkspacePanels.PackageComparisonPanel: {
                panel = this.addPackageComparisonPanel(stateInfo);
                break;
            }
            case WorkspacePanels.PositionModifier: {
                panel = this.addPositionModifierPanel(stateInfo);
                break;
            }
            default:
                break;
        }

        if (!panel) {

            console.warn(`Panel (TypeCode=${WorkspacePanels[stateInfo.typeCode]}, Id=${stateInfo.panelId}) was not restored, because it is not available in security context`);
            this._layoutService.removeItem(this.workspaceId);

        } else {

            if (stateInfo) {
                panel.header = stateInfo.header;
            }

            if (panel.isActive) {
                panel.isRestoring = true;
                this.selectedPanel = panel;
            }
        }

        setTimeout(() => {
            this._changeDetector.detectChanges();
        }, 25);
    }

    private locateMenuOnScreen(ev: MouseEvent, menu: WorkspacePanelsMenu): void {
        const availableHeight = document.body.clientHeight;
        const clickedPoint = ev.y;

        const availableMenuItems = menu.items.filter(x => x.isAvailable());
        const menuHeight = availableMenuItems.length * 28 + 20;
        const availableSpaceDown = availableHeight - clickedPoint;
        const availableSpaceUp = clickedPoint;


        if (availableSpaceDown >= menuHeight && availableSpaceUp >= menuHeight) {
            menu.top = ev.y + 10;
            menu.left = ev.x;
            menu.maxHeight = null;
        } else if (availableSpaceUp >= menuHeight) {
            menu.top = ev.y - menuHeight + 10;
            menu.left = ev.x;
            menu.maxHeight = null;
        } else if (availableSpaceDown >= menuHeight) {
            menu.top = ev.y + 10;
            menu.left = ev.x;
            menu.maxHeight = null;
        } else {
            if (availableSpaceDown > availableSpaceUp) {
                menu.top = ev.y + 10;
                menu.left = ev.x;
                menu.maxHeight = availableSpaceDown - 50;
            } else {
                const maxHeight = availableSpaceUp - 50;
                menu.top = ev.y - maxHeight + 10;
                menu.left = ev.x;
                menu.maxHeight = maxHeight;
            }
        }

        this._changeDetector.detectChanges();
    }

    private addPanelToPanelsMenu(panel: PanelModel): void {
        const mi: WorkspacePanelsMenuItem = {
            id: panel.panelId,
            header: panel.header,
            isAvailable: () => true
        };
        this.tabsMenuContextMenu.items.push(mi);
    }

    private removeMenuItemFromTabsMenu(tab: PanelModel): void {
        const items = this.tabsMenuContextMenu.items;
        if (!items || items.length === 0) {
            return;
        }
        const ix = items.findIndex(x => x.id === tab.panelId);
        if (ix >= 0) {
            items.splice(ix, 1);
        }
    }

    private onContextMenuItemClicked(message: WorkspacePanelsMenuItemClickedUIMessage): void {
        if (
            [ADD_PANEL_CTX_MENU_TYPE, TABS_LIST_CTX_MENU_TYPE].indexOf(
                message.menuType
            ) < 0
        ) {
            return;
        }

        if (message.originKey !== this.workspaceId) {
            return;
        }

        if (message.menuType === ADD_PANEL_CTX_MENU_TYPE) {
            this.onAddPanelContextMenuItemClicked(message.itemId);
        } else if (message.menuType === TABS_LIST_CTX_MENU_TYPE) {
            this.onTabsListContextMenuItemClicked(message.itemId);
        }

        this.afterContextMenuActionInvoked(message.menuType);
    }

    private onTabsListContextMenuItemClicked(itemId: string): void {

        this.workspaceHeader.scrollToSelectedTab(itemId);
        const ix = this.panels.findIndex(x => x.panelId === itemId);
        if (ix > -1) {
            const selectedPanel = this.panels[ix];
            this.selectedPanel = selectedPanel;
        }
    }

    private deactivateAllPanels(panels: PanelModel[]): void {
        panels.forEach(tab => {
            if (!tab.isActive) {
                return;
            }

            tab.isActive = false;

            if (tab.instance) {
                tab.instance.instance.isActive = false;
            }

        });
    }

    private afterContextMenuActionInvoked(menuId: string): void {
        let detectChanges = false;
        switch (menuId) {
            case TABS_LIST_CTX_MENU_TYPE: {
                if (this.tabsMenuContextMenu.isOpened) {
                    this.tabsMenuContextMenu.isOpened = false;
                    detectChanges = true;
                }
                break;
            }
            case ADD_PANEL_CTX_MENU_TYPE: {
                if (this.addPanelContextMenu.isOpened) {
                    this.addPanelContextMenu.isOpened = false;
                    detectChanges = true;
                }
                break;
            }
        }

        if (detectChanges) {
            this._changeDetector.detectChanges();
        }

    }

    /** Add XXX panel methods  */
    addPnLChartPanel(chartState: EtsPnLChartStateInfo, workspaceState: WorkspacePanelStateInfo): PanelModel {
        const panelId = workspaceState ? workspaceState.panelId : shortid.generate();

        const panel: PanelModel = {
            layoutTabId: this.layoutTabId,
            workspaceId: this.workspaceId,
            panelId,
            header: 'PnL Chart',
            isActive: true,
            typeCode: WorkspacePanels.PnLChart,
            isSymbolLinkCompatible: true,
        };

        this.deactivateAllPanels(this.panels);
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);

        return panel;
    }

    //

    private addTradingSystemsView(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Trading Systems',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.TradingSystemsView,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //

    private addQuoteBoard(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const qb: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Quote Board',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.QuoteBoard,
            isSymbolLinkCompatible: false
        };
        this.panels.push(qb);
        this.addPanelToPanelsMenu(qb);
        return qb;
    }

    private addHpDatasetsPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const hpDatasets: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'HP Datasets',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.HpDatasets,
            isSymbolLinkCompatible: false
        };
        this.panels.push(hpDatasets);
        this.addPanelToPanelsMenu(hpDatasets);
        return hpDatasets;
    }

    private addStrategyMessagesPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const strategyMessagesPanel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Strategy Messages',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.StrategyMessages,
            isSymbolLinkCompatible: false
        };
        this.panels.push(strategyMessagesPanel);
        this.addPanelToPanelsMenu(strategyMessagesPanel);
        return strategyMessagesPanel;
    }

    private addStrategyOrdersAndTradesPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Strategy Orders & Trades',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.StrategyOrdersAndTrades,
            isSymbolLinkCompatible: false
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;
    }

    private addStrategyIssuesPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Strategy Issues',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.StrategyIssues,
            isSymbolLinkCompatible: false
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;
    }

    private addManualPositionsPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Manual Positions',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.ManualPositions,
            isSymbolLinkCompatible: false
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;
    }

    private addAggregatedPositionsPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Aggregated Positions',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.AggregatedPositions,
            isSymbolLinkCompatible: false
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;
    }

    //

    private addManualOrdersAndTradesPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Manual Orders & Trades',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.ManualOrdersAndTrades,
            isSymbolLinkCompatible: true
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;

    }

    private addPriceChartPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Price Chart',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.PriceChart,
            isSymbolLinkCompatible: true,
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;
    }

    private addOpenPositionChartPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Open Position Chart',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.OpenPositionChart,
            isSymbolLinkCompatible: true,
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;
    }

    private addPositionSizingReportPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Position Sizing Report',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.PositionSizingReport,
            isSymbolLinkCompatible: false
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;
    }

    private addStrategyTriggersPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Strategy Triggers',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.StrategyTriggers,
            isSymbolLinkCompatible: false
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;
    }

    private addOptionsBoardPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Option Chain',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.OptionChain,
            isSymbolLinkCompatible: true
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;
    }

    private addPortfoliosPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Portfolios',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.Portfolios,
            isSymbolLinkCompatible: false
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;
    }

    //

    private addMultiTradePadPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Multi-Trade Pad',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.MultiTradePad,
            isSymbolLinkCompatible: true
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;
    }

    //

    private addLedgerPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Ledger',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.Ledger,
            isSymbolLinkCompatible: false
        };

        this.panels.push(panel);

        this.addPanelToPanelsMenu(panel);

        return panel;
    }

    //

    private addOptionsPricingPad(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Options Pricing Pad',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.OptionsPricingPad,
            isSymbolLinkCompatible: true
        };

        this.panels.push(panel);

        this.addPanelToPanelsMenu(panel);

        return panel;
    }

    //

    private addOptionsPricingGrid(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Options Pricing Grid',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.OptionsPricingGrid,
            isSymbolLinkCompatible: true
        };

        this.panels.push(panel);

        this.addPanelToPanelsMenu(panel);

        return panel;
    }

    //

    private addStrategyHedgedPositions(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Strategy Hedged Positions',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.StrategyHedgedPositions,
            isSymbolLinkCompatible: false
        };

        this.panels.push(panel);

        this.addPanelToPanelsMenu(panel);

        return panel;
    }

    //

    private addPortfolioPositions(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Portfolio Items',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.PortfolioItems,
            isSymbolLinkCompatible: false,
        };

        this.panels.push(panel);

        this.addPanelToPanelsMenu(panel);

        return panel;
    }

    //

    private addWatchlist(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Watchlist',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.Watchlist,
            isSymbolLinkCompatible: false
        };

        this.panels.push(panel);

        this.addPanelToPanelsMenu(panel);

        return panel;
    }

    //

    private addSessionHistory(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Session History',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.SessionHistory,
            isSymbolLinkCompatible: false
        };

        this.panels.push(panel);

        this.addPanelToPanelsMenu(panel);

        return panel;
    }

    //

    private addPortfolioOrdersAndTradesPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const panel: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Portfolio Orders & Trades',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.PortfolioOrdersAndTrades,
            isSymbolLinkCompatible: false
        };
        this.panels.push(panel);
        this.addPanelToPanelsMenu(panel);
        return panel;

    }

    //

    private addAdjustmentsStrategiesView(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Adjustment Strategies',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.AdjustmentStrategies,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //

    private addParametersPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Parameters Panel',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.ParametersPanel,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //

    private addMarginRequirementsPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Margin Requirements',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.MarginRequirements,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //

    private addShellMessagesPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Shell Messages',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.ShellMessages,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //

    private addFutureOrdersPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Future Orders',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.FutureOrders,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //

    private addTestPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: '++ Test ++',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.TestPanel,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //
    private addAdjustmentControlPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Automation CP',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.AdjsutmentControlPanel,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //
    private addAutomationCpPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Automation CP',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.AutomationCp,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //
    private addAdjustmentPricingGridPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Adjustment Pricing Grid',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.AdjustmentPricingGridPanel,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //
    private addCashFlowTracking(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Cash Flow Tracking',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.CashFlowTracking,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //
    private addAdjustmentComparisonGridPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Adjustment Comparison Grid',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.AdjustmentComparisonGrid,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //
    private addChecklistEditor(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Checklist Editor',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.ChecklistEditorPanel,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    //
    private addResourceEditor(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Resource Editor',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.ResourceEditorPanel,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    private addHedgingGrid(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Hedging Grid',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.HedgingGrid,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    private addPackageComparisonPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Package Comparison',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.PackageComparisonPanel,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }

    private addPositionModifierPanel(stateInfo: WorkspacePanelStateInfo = null): PanelModel {
        const panelId = !!stateInfo ? stateInfo.panelId : shortid.generate();
        const tsv: PanelModel = {
            panelId,
            workspaceId: this.workspaceId,
            layoutTabId: this.layoutTabId,
            header: 'Position Modifier',
            isActive: stateInfo ? stateInfo.isActive : true,
            typeCode: WorkspacePanels.PositionModifier,
            isSymbolLinkCompatible: false
        };
        this.panels.push(tsv);
        this.addPanelToPanelsMenu(tsv);
        return tsv;
    }


    //
    private updateContent(panelDescriptor: PanelModel): void {

        if (!panelDescriptor) {
            this.contentPlaceholder.clear();
            return;
        }

        if (panelDescriptor.instance) {
            panelDescriptor.instance.instance.isActive = true;
        } else {
            let type: Type<any>;
            switch (panelDescriptor.typeCode) {
                case WorkspacePanels.QuoteBoard: {
                    type = QuoteBoardComponent;
                    break;
                }
                case WorkspacePanels.TradingSystemsView: {
                    type = TradingSystemsViewComponent;
                    break;
                }
                case WorkspacePanels.StrategyMessages: {
                    type = StrategyMessagesPanelComponent;
                    break;
                }
                case WorkspacePanels.StrategyOrdersAndTrades: {
                    type = StrategyOrdersAndTradesComponent;
                    break;
                }
                case WorkspacePanels.ManualOrdersAndTrades: {
                    type = ManualOrdersAndTradesComponent;
                    break;
                }
                case WorkspacePanels.ManualPositions: {
                    type = ManualPositionsComponent;
                    break;
                }
                case WorkspacePanels.AggregatedPositions: {
                    type = AggregatedPositionsComponent;
                    break;
                }
                case WorkspacePanels.PnLChart: {
                    // type = PnLChartComponent;
                    type = PnLChart2Component;
                    break;
                }
                case WorkspacePanels.PriceChart: {
                    type = PriceChartComponent;
                    break;
                }
                case WorkspacePanels.StrategyIssues: {
                    type = StrategyIssuesPanelComponent;
                    break;
                }
                case WorkspacePanels.HpDatasets: {
                    type = HpDatasetsPanelComponent;
                    break;
                }
                case WorkspacePanels.OpenPositionChart: {
                    type = OpenPositionChartComponent;
                    break;
                }
                case WorkspacePanels.PositionSizingReport: {
                    type = PositionSizingReportComponent;
                    break;
                }
                case WorkspacePanels.StrategyTriggers: {
                    type = StrategyTriggersComponent;
                    break;
                }
                case WorkspacePanels.OptionChain: {
                    type = OptionChainComponent;
                    break;
                }
                case WorkspacePanels.Portfolios: {
                    type = PortfoliosComponent;
                    break;
                }
                case WorkspacePanels.MultiTradePad: {
                    type = MultiTradePadComponent;
                    break;
                }
                case WorkspacePanels.Ledger: {
                    type = LedgerComponent;
                    break;
                }
                case WorkspacePanels.OptionsPricingPad: {
                    type = OptionsPricingPadComponent;
                    break;
                }
                case WorkspacePanels.OptionsPricingGrid: {
                    type = OptionsPricingGridComponent;
                    break;
                }
                case WorkspacePanels.StrategyHedgedPositions: {
                    type = StrategyHedgedPositionsComponent;
                    break;
                }
                case WorkspacePanels.PortfolioItems: {
                    type = PortfolioItemsComponent;
                    break;
                }
                case WorkspacePanels.Watchlist: {
                    type = WatchlistComponent;
                    break;
                }
                case WorkspacePanels.SessionHistory: {
                    type = SessionHistoryPanelComponent;
                    break;
                }
                case WorkspacePanels.PortfolioOrdersAndTrades: {
                    type = PortfolioOrdersAndTradesComponent;
                    break;
                }
                case WorkspacePanels.AdjustmentStrategies: {
                    type = AdjustmentStrategiesViewComponent;
                    break;
                }
                case WorkspacePanels.ParametersPanel: {
                    type = ParametersPanelComponent;
                    break;
                }
                case WorkspacePanels.MarginRequirements: {
                    type = MarginRequirementsComponent;
                    break;
                }
                case WorkspacePanels.ShellMessages: {
                    type = ShellMessagesPanelComponent;
                    break;
                }
                case WorkspacePanels.FutureOrders: {
                    type = FutureOrdersComponent;
                    break;
                }
                case WorkspacePanels.TestPanel: {
                    type = TestPanelComponent;
                    break;
                }
                case WorkspacePanels.AdjsutmentControlPanel: {
                    type = AdjustmentControlPanelComponent;
                    break;
                }
                case WorkspacePanels.AutomationCp: {
                    type = AutomationCpComponent;
                    break;
                }
                case WorkspacePanels.AdjustmentPricingGridPanel: {
                    type = AdjustmentPricingGridPanelComponent;
                    break;
                }
                case WorkspacePanels.CashFlowTracking: {
                    type = CashFlowTrackingPanelComponent;
                    break;
                }
                case WorkspacePanels.AdjustmentComparisonGrid: {
                    type = AdjustmentComparisonGridPanelComponent;
                    break;
                }
                case WorkspacePanels.ChecklistEditorPanel: {
                    type = ChecklistEditorComponent;
                    break;
                }
                case WorkspacePanels.ResourceEditorPanel: {
                    type = ResourceEditorComponent;
                    break;
                }
                case WorkspacePanels.HedgingGrid: {
                    type = HedgingGridPanelComponent;
                    break;
                }
                case WorkspacePanels.PackageComparisonPanel: {
                    type = PackageComparisonPanelComponent;
                    break;
                }
                case WorkspacePanels.PositionModifier: {
                    type = PositionModifierComponent;
                    break;
                }
                default: {
                    console.error(`Unsupported panel type`, {tabCode: panelDescriptor.typeCode});
                    break;
                }
            }

            const cmpFactory = this._componentFactory.resolveComponentFactory(type);
            const cRef = this.contentPlaceholder.createComponent(cmpFactory);

            const panelInstance = cRef.instance as IPanelComponent;
            panelInstance.panelId = panelDescriptor.panelId;
            panelInstance.workspaceId = panelDescriptor.workspaceId;
            panelInstance.layoutTabId = panelDescriptor.layoutTabId;
            panelInstance.isActive = true;
            panelDescriptor.instance = cRef;

            const key = getPanelStateKey(panelInstance);
            const state = this._layoutService.getItem<any>(key);
            if (state) {
                panelInstance.isLinkedToSymbol = state.isLinkedToSymbol;
                panelDescriptor.isLinkedToSymbol = state.isLinkedToSymbol;
            }
        }
    }

    //

    private onAddPanelContextMenuItemClicked(itemId: string): void {
        let panel: PanelModel;
        switch (itemId) {
            case WorkspacePanels.TradingSystemsView.toString(): {
                panel = this.addTradingSystemsView();
                break;
            }
            case WorkspacePanels.QuoteBoard.toString(): {
                panel = this.addQuoteBoard();
                break;
            }
            case WorkspacePanels.StrategyMessages.toString(): {
                panel = this.addStrategyMessagesPanel();
                break;
            }
            case WorkspacePanels.StrategyOrdersAndTrades.toString(): {
                panel = this.addStrategyOrdersAndTradesPanel();
                break;
            }
            case WorkspacePanels.ManualOrdersAndTrades.toString(): {
                panel = this.addManualOrdersAndTradesPanel();
                break;
            }
            case WorkspacePanels.ManualPositions.toString(): {
                panel = this.addManualPositionsPanel();
                break;
            }
            case WorkspacePanels.AggregatedPositions.toString(): {
                panel = this.addAggregatedPositionsPanel();
                break;
            }
            case WorkspacePanels.PnLChart.toString(): {
                this.isChartPickerDialogVisible = true;
                break;
            }
            case WorkspacePanels.PriceChart.toString(): {
                panel = this.addPriceChartPanel();
                break;
            }
            case WorkspacePanels.StrategyIssues.toString(): {
                panel = this.addStrategyIssuesPanel();
                break;
            }
            case WorkspacePanels.HpDatasets.toString(): {
                panel = this.addHpDatasetsPanel();
                break;
            }
            case WorkspacePanels.OpenPositionChart.toString(): {
                panel = this.addOpenPositionChartPanel();
                break;
            }
            case WorkspacePanels.PositionSizingReport.toString(): {
                panel = this.addPositionSizingReportPanel();
                break;
            }
            case WorkspacePanels.StrategyTriggers.toString(): {
                panel = this.addStrategyTriggersPanel();
                break;
            }
            case WorkspacePanels.OptionChain.toString(): {
                panel = this.addOptionsBoardPanel();
                break;
            }
            case WorkspacePanels.Portfolios.toString(): {
                panel = this.addPortfoliosPanel();
                break;
            }
            case WorkspacePanels.MultiTradePad.toString(): {
                panel = this.addMultiTradePadPanel();
                break;
            }
            case WorkspacePanels.Ledger.toString(): {
                panel = this.addLedgerPanel();
                break;
            }
            case WorkspacePanels.OptionsPricingPad.toString(): {
                panel = this.addOptionsPricingPad();
                break;
            }
            case WorkspacePanels.OptionsPricingGrid.toString(): {
                panel = this.addOptionsPricingGrid();
                break;
            }
            case WorkspacePanels.StrategyHedgedPositions.toString(): {
                panel = this.addStrategyHedgedPositions();
                break;
            }
            case WorkspacePanels.PortfolioItems.toString(): {
                panel = this.addPortfolioPositions();
                break;
            }
            case WorkspacePanels.Watchlist.toString(): {
                panel = this.addWatchlist();
                break;
            }
            case WorkspacePanels.SessionHistory.toString(): {
                panel = this.addSessionHistory();
                break;
            }
            case WorkspacePanels.PortfolioOrdersAndTrades.toString(): {
                panel = this.addPortfolioOrdersAndTradesPanel();
                break;
            }
            case WorkspacePanels.AdjustmentStrategies.toString(): {
                panel = this.addAdjustmentsStrategiesView();
                break;
            }
            case WorkspacePanels.ParametersPanel.toString(): {
                panel = this.addParametersPanel();
                break;
            }
            case WorkspacePanels.MarginRequirements.toString(): {
                panel = this.addMarginRequirementsPanel();
                break;
            }
            case WorkspacePanels.ShellMessages.toString(): {
                panel = this.addShellMessagesPanel();
                break;
            }
            case WorkspacePanels.FutureOrders.toString(): {
                panel = this.addFutureOrdersPanel();
                break;
            }
            case WorkspacePanels.TestPanel.toString(): {
                panel = this.addTestPanel();
                break;
            }
            case WorkspacePanels.AdjsutmentControlPanel.toString(): {
                panel = this.addAdjustmentControlPanel();
                break;
            }
            case WorkspacePanels.AutomationCp.toString(): {
                panel = this.addAutomationCpPanel();
                break;
            }
            case WorkspacePanels.AdjustmentPricingGridPanel.toString(): {
                panel = this.addAdjustmentPricingGridPanel();
                break;
            }
            case WorkspacePanels.CashFlowTracking.toString(): {
                panel = this.addCashFlowTracking();
                break;
            }
            case WorkspacePanels.AdjustmentComparisonGrid.toString(): {
                panel = this.addAdjustmentComparisonGridPanel();
                break;
            }
            case WorkspacePanels.ChecklistEditorPanel.toString(): {
                panel = this.addChecklistEditor();
                break;
            }
            case WorkspacePanels.ResourceEditorPanel.toString(): {
                panel = this.addResourceEditor();
                break;
            }
            case WorkspacePanels.HedgingGrid.toString(): {
                panel = this.addHedgingGrid();
                break;
            }
            case WorkspacePanels.PackageComparisonPanel.toString(): {
                panel = this.addPackageComparisonPanel();
                break;
            }
            case WorkspacePanels.PositionModifier.toString(): {
                panel = this.addPositionModifierPanel();
                break;
            }
            default:
                break;
        }
        this.selectedPanel = panel;
        this.saveState();
    }

    //

    private saveState(): void {
        setTimeout(() => {
            const info = this.getStateInfo();
            this._layoutService.setItem(info.workspaceId, info);
        });
    }
}
