import {CashFlowStrategy} from "../../adjustment-control-panel/cash-flow-strategy";
import {CashFlowStrategyRole, OptionExpirationDescriptor} from "../../shell-communication/shell-dto-protocol";
import {getNearestExpiration, isVoid, numberCompare} from "../../utils";
import * as Enumerable from "linq";

export class SmartExpirationResolveService {

    constructor(private readonly _strategy: CashFlowStrategy, private readonly _role: CashFlowStrategyRole) {
        this._promise = new Promise<OptionExpirationDescriptor>((resolve, reject) => {
            this._resolver = resolve;
            this._rejecter = reject;
        })
    }

    private readonly _promise: Promise<OptionExpirationDescriptor>;
    private readonly _requests: any[] = [];
    private _resolver: (value?: (PromiseLike<OptionExpirationDescriptor> | OptionExpirationDescriptor)) => void;
    private _rejecter: (reason?: any) => void;

    addRequest(request: any): Promise<OptionExpirationDescriptor> {

        this._requests.push(request);

        setTimeout(() => {
            if (this._strategy !== 'Calls & Puts') {
                this.pickExpirationSmart();
            } else {
                if (this._requests.length === 2) {
                    this.pickExpirationSmart2();
                }
            }
        });

        // error timeout
        setTimeout(() => {
            if (!isVoid(this._resolver)) {
                console.error('SmartExpirationResolveService failed. Requests:', this._requests);
                this._rejecter('Smart expiration resolve timeout');
            }
        }, 3000);

        return this._promise;
    }

    private pickExpirationSmart(): void {

        const parameters = this._requests[0];

        if (parameters.expirations.length == 0) {
            this.resolve(null);
            return;
        }

        const strikeStart = parameters.strikeStart
        const strikeRange = parameters.strikeRange;

        const strikeReverseDirection = parameters.strikeReverseDirection;

        const expirationRangeStart = parameters.expirationRangeStart;
        const expirationRangeEnd = parameters.expirationRangeEnd;
        const defaultDaysToExpiration = parameters.defaultDaysToExpiration;

        const expirations = parameters.expirations;

        const filteredExpirations = expirations
            .filter(x => x.daysToExpiration >= expirationRangeStart)
            .filter(x => x.daysToExpiration <= expirationRangeEnd);

        if (filteredExpirations.length === 0) {
            const defaultExp = getNearestExpiration(defaultDaysToExpiration, expirations);
            this.resolve(defaultExp);
            return;
        }

        const multiplier = strikeReverseDirection ? 1 : -1;

        const strikeEnd = strikeStart + ((strikeRange * 1.5) * multiplier);

        const scoredExpirations: any[] = filteredExpirations.map(x => {

            const filteredStrikes = x.strikes
                .filter(strike => {
                    const compareResult = numberCompare(strike, strikeStart) * multiplier;
                    return compareResult >= 0;
                })
                .filter(strike => {
                    const compareResult = numberCompare(strikeEnd, strike) * multiplier;
                    return compareResult >= 0;
                });

            let score = filteredStrikes.length;

            if (x.strikes.indexOf(parameters.targetStrike) >= 0) {
                score = Math.ceil(score * 1.2);
            }

            return {
                count: score,
                expiration: x
            }
        });

        this.log('scored expirations', scoredExpirations);

        const best = Enumerable.from(scoredExpirations)
            .groupBy(x => x.count)
            .orderByDescending(x => x.key())
            .firstOrDefault();

        this.log('best expiration', best.toArray());

        if (isVoid(best)) {
            this.resolve(null)
            return;
        }

        if (best.count() === 1) {
            this.resolve(best.first().expiration);
            return;
        }

        const expirationsWithDiff = best.select(x => {
            const diff = Math.abs(x.expiration.daysToExpiration - defaultDaysToExpiration);
            return {
                diff,
                expiration: x.expiration
            };
        });

        const desiredExpiration = expirationsWithDiff
            .orderBy(x => x.diff)
            .first()
            .expiration;

        this.resolve(desiredExpiration);

        return;
    }

    private pickExpirationSmart2(): void {

        const parameters = this._requests[0];
        const parameters2 = this._requests[1];

        if (parameters.expirations.length == 0 || parameters2.expirations.length == 0) {
            return null;
        }

        const strikeStart = parameters.strikeStart
        const strikeRange = parameters.strikeRange;

        const strikeStart2 = parameters2.strikeStart
        const strikeRange2 = parameters2.strikeRange;


        const strikeReverseDirection = parameters.strikeReverseDirection;
        const strikeReverseDirection2 = parameters2.strikeReverseDirection;

        const expirationRangeStart = parameters.expirationRangeStart;
        const expirationRangeEnd = parameters.expirationRangeEnd;
        const defaultDaysToExpiration = parameters.defaultDaysToExpiration;

        const expirationRangeStart2 = parameters2.expirationRangeStart;
        const expirationRangeEnd2 = parameters2.expirationRangeEnd;
        const defaultDaysToExpiration2 = parameters2.defaultDaysToExpiration;

        const expirations = parameters.expirations;

        const filteredExpirations = expirations
            .filter(x => x.daysToExpiration >= expirationRangeStart && x.daysToExpiration >= expirationRangeStart2)
            .filter(x => x.daysToExpiration <= expirationRangeEnd && x.daysToExpiration <= expirationRangeEnd2);

        if (filteredExpirations.length === 0) {
            const descriptor = getNearestExpiration(defaultDaysToExpiration, expirations);
            this.resolve(descriptor);
            return;
        }

        const multiplier = strikeReverseDirection ? 1 : -1;
        const multiplier2 = strikeReverseDirection2 ? 1 : -1;

        const strikeEnd = strikeStart + ((strikeRange * 1.5) * multiplier);
        const strikeEnd2 = strikeStart2 + ((strikeRange2 * 1.5) * multiplier2);

        const scoredExpirations: any[] = filteredExpirations.map(x => {

            const filteredStrikes: number[] = x.strikes
                .filter((strike: number) => {
                    const compareResult = numberCompare(strike, strikeStart) * multiplier;
                    return compareResult >= 0;
                })
                .filter((strike: number) => {
                    const compareResult = numberCompare(strikeEnd, strike) * multiplier;
                    return compareResult >= 0;
                });

            const filteredStrikes2: number[] = x.strikes
                .filter((strike: number) => {
                    const compareResult = numberCompare(strike, strikeStart2) * multiplier2;
                    return compareResult >= 0;
                })
                .filter((strike: number) => {
                    const compareResult = numberCompare(strikeEnd2, strike) * multiplier2;
                    return compareResult >= 0;
                });


            const avg = (filteredStrikes.length + filteredStrikes2.length) / 2.0;

            const stdDev1 = Math.abs(avg - filteredStrikes.length);
            const stdDev2 = Math.abs(avg - filteredStrikes2.length);


            let score = avg;

            if (x.strikes.indexOf(parameters.targetStrike) >= 0) {
                score = Math.ceil(score * 1.2);
            }

            return {
                score,
                stdDev1: stdDev1,
                stdDev2: stdDev2,
                totalDev: stdDev1 + stdDev2,
                expiration: x
            }
        });

        const sortedScoredExpirations = Enumerable
            .from(scoredExpirations)
            .orderByDescending(x => x.score)
            .thenBy(x => x.totalDev);

        this.log('scored expirations', sortedScoredExpirations.toArray());

        const best = sortedScoredExpirations
            .groupBy(x => `${x.score}${x.totalDev}`)
            .firstOrDefault();

        this.log('best expiration', best.toArray());

        if (isVoid(best)) {
            const exp = this.pickFallbackExpiration(expirations, defaultDaysToExpiration);
            this.resolve(exp);
            return;
        }

        if (best.count() === 1) {
            this.resolve(best.first().expiration);
            return;
        }

        const expirationsWithDiff = best.select(x => {
            const diff = Math.abs(x.expiration.daysToExpiration - defaultDaysToExpiration);
            return {
                diff,
                expiration: x.expiration
            };
        });

        const desiredExpiration = expirationsWithDiff
            .orderBy(x => x.diff)
            .first()
            .expiration;

        this.resolve(desiredExpiration);

        return;
    }

    private pickFallbackExpiration(expirations: OptionExpirationDescriptor[], dte: number): OptionExpirationDescriptor {
        const descriptor = getNearestExpiration(dte, expirations);
        return descriptor;
    }

    private resolve(expiration: OptionExpirationDescriptor) {
        if (isVoid(this._resolver)) return;
        const resolver = this._resolver;
        this._resolver = null;
        resolver(expiration);
    }

    private log(message: string, ...data: any) {
        console.log(`[${this._strategy},${this._role}]: ${message}`, data);
    }
}