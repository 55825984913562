import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {WebtraderAppComponent} from './wt-app.component';
import {WebtraderRoutingModule} from './wt-app-routing.module';
import {PageNotFoundComponent} from 'projects/shared-components/page-not-found.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {LoginModule} from './login/login.module';
import {HttpClientModule} from '@angular/common/http';
import {LogoutModule} from '../../../shared-components/logout-screen/logout.module';
import {environment} from 'projects/shared-components/environments/environment';
import {WebtraderHostModule} from './host/host.module';
import {WebtraderTypeRegistrations} from './wt-di-container.const';
import {MaintenanceScreenModule} from "../../../shared-components/maintenance-screen/maintenance-screen.module";

@NgModule({
    declarations: [
        WebtraderAppComponent,
        PageNotFoundComponent
    ],

    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,

        LoginModule,
        WebtraderHostModule,
        LogoutModule,
        WebtraderRoutingModule,
        MaintenanceScreenModule,

        ToastrModule.forRoot(environment.toastrConfiguration)
    ],

    providers: WebtraderTypeRegistrations,

    bootstrap: [WebtraderAppComponent]
})
export class WebtraderAppModule {
}
