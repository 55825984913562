<dx-button (onClick)="this.show()" width="100%" height="100%" text="QG" class="action-btn"></dx-button>

<dx-popup
  (onHidden)="this.onClose()"
  [closeOnOutsideClick]="false"
  [dragEnabled]="true"
  [height]="this.getHeight()"
  [resizeEnabled]="this.isResizable()"
  [shading]="false"
  [showTitle]="true"
  [visible]="this.visible"
  [width]="this.getWidth()"
  [titleTemplate]="this.isMicroView() ? 'titleTpl' : 'title'"
  title="Quote Generator"
>
  <div *dxTemplate="let data of 'titleTpl'" class="window-title">
    <div class="title-text">{{this.pinnedStream?.ticker}}</div>
    <div class="atm-distance">{{this.getAtmDistance(this.pinnedStream)}}</div>
    <div class="icons">
      <div class="icon expand">
        <i class="fas fa-expand-arrows-alt" (click)="this.getOutOfMicroView()"></i>
      </div>
      <div class="icon close">
        <i class="fas fa-times" (click)="this.onClose()"></i>
      </div>
    </div>
  </div>
  
  <div *dxTemplate="let data of 'content'" class="content" #content>
    
    <div [class.reduced]="this.isReducedView" class="streams">
      <ng-container *ngIf="!this.isReducedView">
        <dx-scroll-view>
          <ng-container *ngFor="let stream of this.streams">
            <ng-container *ngTemplateOutlet="quoteStream; context: {$implicit: stream}"></ng-container>
          </ng-container>
        </dx-scroll-view>
      </ng-container>
      
      <ng-container *ngIf="this.isReducedView">
        <ng-container *ngTemplateOutlet="quoteStream; context: {$implicit: this.pinnedStream}"></ng-container>
      </ng-container>
    </div>
    
    <div *ngIf="!this.isReducedView" class="add-stream-controls">
      
      <fieldset class="fieldset">
        <legend class="legend">Underlying</legend>
        <div>
          <ets-symbol-picker (instrumentSelected)="this.onAddUnderlying($event)" [selectedInstrument]="this.addUnderlying"
                             [showLastPrice]="false">
          </ets-symbol-picker>
        </div>
      </fieldset>
      
      <fieldset class="fieldset option">
        <legend class="legend">
          <dx-check-box (onValueChanged)="this.onAddOptionContractChanged()" [(value)]="this.addOptionContract"
                        [disabled]="!this.isUnderlyingSelected"
                        text="Option"
          ></dx-check-box>
        </legend>
        <div class="inputs">
          <div class="expiration">
            <div class="input">
              <dx-select-box (onValueChanged)="this.onAddExpirationChanged()"
                             [(value)]="this.addExpiration"
                             [disabled]="!this.addOptionContract"
                             [inputAttr]="{style: 'text-align: center;'}"
                             [items]="this.addExpirationList"
                             [showClearButton]="true"
                             displayExpr="dateWithDaysToExpiration"
                             placeholder="Expiration"
              ></dx-select-box>
            </div>
          </div>
          
          <div class="type">
            <div class="input">
              <dx-select-box
                [(value)]="this.addOptionType"
                [disabled]="!this.addOptionContract"
                [inputAttr]="{style: 'text-align: center;'}" [items]="['Call', 'Put']"
                [showClearButton]="true"
                placeholder="Option Type"
              ></dx-select-box>
            </div>
          </div>
          
          <div class="strike">
            <div class="input">
              <ets-strikes-dropdown [(strike)]="this.addStrike"
                                    [disable]="!this.addOptionContract"
                                    [expiration]="this.addExpiration"
                                    [showClearButton]="true"
                                    [targetInstrument]="this.addUnderlying"
              ></ets-strikes-dropdown>
            </div>
          </div>
        </div>
      </fieldset>
      
      
      <div class="add-button">
        <dx-button (onClick)="this.addStream()" height="100%" text="Add" type="default"
        ></dx-button>
      </div>
    </div>
    
    <dx-load-panel [visible]="this.isLoading && !this.isMicroView()" [position]="{my: 'center', at: 'center', of: content}">
    </dx-load-panel>
  </div>

</dx-popup>

<!--Quote Stream Template-->
<ng-template #quoteStream let-stream>
  
  <div class="stream-micro" *ngIf="this.isMicroView()" #micro>
    <div (click)="this.onLeftArrowClicked(stream)" [class.navigational]="stream.isNavigationalMode"
         [class.random]="stream.isRandomMode"
         class="arrow left section"
         *ngIf="stream.mode !== 'Random'"
    >
      <i *ngIf="!stream.isNavigationalMode" class="fas fa-chevron-down"></i>
      <i *ngIf="stream.isNavigationalMode" class="fas fa-chevron-left"></i>
    </div>
    
    <div (click)="this.onLastClicked(stream)" class="last section" [class.random]="stream.isRandomMode" #last>
      {{ stream.lastPrice | number:'1.2-2' }}
      <dx-context-menu
        [items]="[{text: 'Reset To Market'}]"
        [width]="200"
        [target]="last"
        (onItemClick)="this.onMenuItemClicked(stream, $event)"
      >
      </dx-context-menu>
    </div>
    
    <div (click)="this.onRightArrowClicked(stream)" [class.navigational]="stream.isNavigationalMode"
         [class.random]="stream.isRandomMode"
         class="arrow right section"
         *ngIf="stream.mode !== 'Random'"
    >
      <i *ngIf="!stream.isNavigationalMode" class="fas fa-chevron-up"></i>
      <i *ngIf="stream.isNavigationalMode" class="fas fa-chevron-right"></i>
    </div>
    
    <ng-container *ngIf="stream.mode === 'Random'">
      <div class="section divider">
        <div class="nbsp">&nbsp;</div>
      </div>
      
      <div class="play-btn">
        <div (click)="this.toggleStreamRunning(stream)" class="play-stop">
          <i *ngIf="!stream.isRunning" class="fas fa-play fa-lg icon" style="color: green;"></i>
          <i *ngIf="stream.isRunning" class="fas fa-stop fa-lg icon" style="color: red;"></i>
        </div>
      </div>
    
    </ng-container>
    
    <dx-load-panel [visible]="this.isLoading && this.isMicroView()"
                   [height]="35"
                   [width]="100"
                   [showIndicator]="false"
                   [message]="'Loading...'"
                   [position]="{my: 'center', at: 'center', of: micro}">
    </dx-load-panel>
  </div>
  
  <fieldset class="stream" *ngIf="!this.isMicroView()">
    
    <legend class="title">
      <div class="ticker">
        {{ stream.tickerDisplayName }}
      </div>
      
      <div class="icons">
        <div class="compress-expand icon">
          <div (click)="this.toggleExpandStream(stream)" *ngIf="!this.isReducedView" class="compress-icon">
            <i class="fas fa-expand-arrows-alt"></i>
          </div>
          
          <div (click)="this.toggleExpandStream()" *ngIf="this.isReducedView" class="compress-icon">
            <i class="fas fa-expand-arrows-alt"></i>
          </div>
        </div>
        
        <div (click)="this.toggleStreamMicroView(stream)" *ngIf="this.isReducedView" class="compress-icon">
          <i class="fas fa-compress"></i>
        </div>
        
        <div (click)="this.removeStream(stream)" class="remove-icon" *ngIf="!this.isReducedView">
          <i class="fas fa-times fa-lg"></i>
        </div>
      </div>
    </legend>
    
    <div class="body">
      <ng-container>
        
        <div class="navigation section">
          
          <div class="row atm" *ngIf="stream.isShowingAtm">
            <div class="number">
              {{(this.getAtmDistance(stream))}}
            </div>
            
          </div>
          
          <div class="row upper">
            <div (click)="this.onLeftArrowClicked(stream)" [class.navigational]="stream.isNavigationalMode"
                 [class.random]="stream.isRandomMode"
                 class="arrow left section"
            >
              <img *ngIf="!stream.isNavigationalMode" src="/assets/red-arrow-down.png" alt="Tick Down" width="32px" height="32px">
              <i *ngIf="stream.isNavigationalMode" class="fas fa-caret-left fa-lg big"></i>
            </div>
            
            <div class="section last">
              <div (click)="this.onLastClicked(stream)" class="last-px" [class.random]="stream.isRandomMode" #last>
                {{ stream.lastPrice | number:'1.2-2' }}
                <dx-context-menu
                  [items]="stream.menuItems"
                  [width]="200"
                  [target]="last"
                  (onItemClick)="this.onMenuItemClicked(stream, $event)"
                  [itemTemplate]="'item'"
                  (onShowing)="this.onMenuShowing(stream)"
                ></dx-context-menu>
              </div>
            </div>
            
            <div (click)="this.onRightArrowClicked(stream)" [class.navigational]="stream.isNavigationalMode"
                 [class.random]="stream.isRandomMode"
                 class="arrow right section"
            >
              <img *ngIf="!stream.isNavigationalMode" src="/assets/green-arrow-up.png" alt="Tick Up" width="32px" height="32px">
              <i *ngIf="stream.isNavigationalMode" class="fas fa-caret-right fa-lg big"></i>
            </div>
          </div>
          
          <div class="row bottom" *ngIf="stream.mode === 'History'">
            <div class="timestamp">
              {{ stream.lastTimestamp | date:'dd-MMM-yy hh:mm:ss aaa' }}
            </div>
          </div>
          
          <div class="row bottom" *ngIf="stream.isShowingAtm && stream.mode !== 'History'">
            <div class="market-atm" (click)="this.resetToMarketPrice(stream)">
              {{ this.getAtm(stream) | number }}
            </div>
          </div>
          
        </div>
        
        <div class="section divider">
          <div class="nbsp">&nbsp;</div>
        </div>
        
        <div class="modes section" [class.shortened]="this.isShortenedWidth(stream)">
          
          <div class="stream-mode">
            <div class="mode">
              <dx-select-box [items]="['Manual', 'Random', 'History', 'Script']"
                             [(value)]="stream.mode"
                             (onValueChanged)="this.setMode(stream, $event.value)"
                             [inputAttr]="{style: 'text-align: center;'}"
                             [disabled]="!this.canChangeMode(stream)"
                             placeholder="Mode"
              ></dx-select-box>
            </div>
          </div>
          
          <div class="quote-source">
            <div class="quote-source">
              <dx-select-box [items]="stream.quoteSourceList"
                             [(value)]="stream.quoteSource"
                             (onValueChanged)="this.setQuoteSource(stream, $event.value)"
                             [inputAttr]="{style: 'text-align: center;'}"
                             [disabled]="!this.canChangeQuoteSource(stream)"
                             placeholder="Source"
              ></dx-select-box>
            </div>
          </div>
        
        </div>
        
        <ng-container *ngIf="!this.isShortenedWidth(stream)">
          <div class="section divider">
            <div class="nbsp">&nbsp;</div>
          </div>
          
          <div [class.disabled]="stream.isManualMode" class="automatic section">
            <div (click)="this.toggleStreamRunning(stream)" class="play-stop">
              <i *ngIf="!stream.isRunning" class="fas fa-play fa-lg icon" style="color: green;"></i>
              <i *ngIf="stream.isRunning" class="fas fa-stop fa-lg icon" style="color: red;"></i>
            </div>
            
            <div class="speed">
              <dx-slider [(value)]="stream.frequency"
                         [disabled]="stream.isManualMode"
                         [max]="1000"
                         [min]="1"
                         [rtlEnabled]="false"
                         (onValueChanged)="this.changeStreamFrequency(stream)"
              ></dx-slider>
            </div>
          </div>
        </ng-container>
       
        <div class="section divider">
          <div class="nbsp">&nbsp;</div>
        </div>
      
        <div class="specific-controls section">
          <ng-container [ngSwitch]="stream.mode">
            <ng-container *ngSwitchCase="'Manual'">
              <ng-container *ngTemplateOutlet="manualSpecificControls; context: {$implicit: stream}">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Random'">
              <ng-container *ngTemplateOutlet="randomSpecificControls; context: {$implicit: stream}">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'History'">
              <ng-container *ngTemplateOutlet="datasetSpecificControls; context: {$implicit: stream}">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Script'">
              <ng-container *ngTemplateOutlet="scriptSpecificControls; context: {$implicit: stream}">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="div">
                N/A
              </div>
            </ng-container>
          </ng-container>
        </div>
        
      </ng-container>
    </div>
  
  </fieldset>
  
</ng-template>

<!--Manual Specific Controls-->
<ng-template #manualSpecificControls let-stream>
  <div class="manual" [class.shortened]="this.isShortenedWidth(stream)">
    
    <div class="element quote-step">
      <div class="label" *ngIf="!this.isShortenedWidth(stream)">Quote Step</div>
      <div class="input">
        <dx-select-box [inputAttr]="{style: 'text-align: center;'}"
                       [items]="stream.quoteStepList"
                       [(value)]="stream.quoteStep"
                       [acceptCustomValue]="true"
                       [searchEnabled]="true"
        ></dx-select-box>
      </div>
    </div>
    
    <div class="element punch-box">
      <div class="row">
        <div class="btn">
          <dx-button text="1" width="100%" (onClick)="this.setQuoteStep(stream,1)"></dx-button>
        </div>
        <div class="btn">
          <dx-button text="5" width="100%" (onClick)="this.setQuoteStep(stream,5)"></dx-button>
        </div>
        <div class="btn">
          <dx-button text="10" width="100%" (onClick)="this.setQuoteStep(stream, 10)"></dx-button>
        </div>
      </div>
      <div class="row">
        <div class="btn">
          <dx-button text="25" width="100%" (onClick)="this.setQuoteStep(stream,25)"></dx-button>
        </div>
        <div class="btn">
          <dx-button text="50" width="100%" (onClick)="this.setQuoteStep(stream,50)"></dx-button>
        </div>
        <div class="btn">
          <dx-button text="100" width="100%" (onClick)="this.setQuoteStep(stream,100)"></dx-button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!--Random Specific Controls-->
<ng-template #randomSpecificControls let-stream>
  <div class="random">
    
    <div class="speed">
      <div class="label">Trend Bias</div>
      
      <div class="input">
        <div class="trend">
          Bearish
        </div>
        <div class="slider">
          <dx-slider [max]="100" [min]="-100" [rtlEnabled]="false" [(value)]="stream.trendBias"
                     (onValueChanged)="this.onTrendBiasChanged(stream)">
          </dx-slider>
        </div>
        <div class="trend">
          Bullish
        </div>
      </div>
      
    </div>
    
    <div class="element quote-step">
      <div class="label">Quote Step</div>
      <div class="input">
        <dx-select-box [inputAttr]="{style: 'text-align: center;'}"
                       [items]="stream.quoteStepList"
                       [(value)]="stream.quoteStep"
                       [acceptCustomValue]="true"
                       [searchEnabled]="true"
        ></dx-select-box>
      </div>
    </div>
  </div>
</ng-template>

<!--History Specific Controls-->
<ng-template #datasetSpecificControls let-stream>
  <div class="dataset">
    
    <div class="row">
      <div (click)="this.showDatasetSelector(stream)" class="open-icon">
        <div><i class="fas fa-folder-open fa-lg"></i></div>
        <div class="label">Open Dataset:</div>
      </div>
      <div class="dataset-name">
        "{{ (stream.dataset?.name || '&lt;Not Selected&gt;') }}"
      </div>
    </div>
    
    <div class="row">
      
      <div class="from">
        <div class="label">From Date:</div>
        <div class="input">
          <ets-datetime-picker [minDate]="this.getMinDate(stream)"
                               [maxDate]="this.getMaxDate(stream)"
                               [(value)]="stream.startDate"
                               [disabled]="!stream.isDatasetSelected"
                               mode="date"
                               [showTodayButton]="false"
                               (valueChanged)="this.onStartDateChanged(stream)"
          ></ets-datetime-picker>
        </div>
      </div>
      
      <div class="to">
        <div class="label">To Date:</div>
        <div class="input">
          <ets-datetime-picker [minDate]="this.getMinDate(stream)" [maxDate]="this.getMaxDate(stream)"
                               [disabled]="!stream.isDatasetSelected"
                               mode="date"
                               [showTodayButton]="false"
                               [(value)]="stream.endDate"
                               (valueChanged)="this.onEndDateChanged(stream)"
          ></ets-datetime-picker>
        </div>
      </div>
    
    </div>
  
  </div>
</ng-template>

<!--Script Specific Controls-->
<ng-template #scriptSpecificControls let-stream>
  
  <div class="script">
    
    <div class="selectors">
      <div class="open button">
        <div (click)="this.showSavedScriptSelector(stream)" class="icon">
          <div><i class="fas fa-folder-open fa-lg"></i></div>
          <div class="label">Open Script</div>
        </div>
        <div class="dataset-name">
          "{{ (stream.savedScript?.name || '&lt;Not Selected&gt;') }}"
        </div>
      </div>
      
      <div class="provide button">
        <div class="icon" (click)="this.showCustomScriptInput(stream)"><i class="fas fa-keyboard fa-lg"></i></div>
        <div class="label">Custom Script</div>
        <div class="icon" *ngIf="stream.customScript" (click)="this.saveCustomScript(stream)">
          <i class="fas fa-save fa-lg" style="color: green; margin-top: 1px"></i>
        </div>
      </div>
    </div>
    
    <div class="quote-step">
      <div class="label">Tick Size</div>
      <div class="input">
        <dx-select-box [inputAttr]="{style: 'text-align: center;'}"
                       [items]="stream.quoteStepList"
                       [(value)]="stream.quoteStep"
                       [acceptCustomValue]="true"
                       [searchEnabled]="true"
        ></dx-select-box>
      </div>
    </div>
  </div>
  
</ng-template>

<ets-dataset-selector></ets-dataset-selector>

<ets-custom-script-input></ets-custom-script-input>

<ets-custom-script-save></ets-custom-script-save>
