import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MessageBusService} from '../../../../shared-components/message-bus.service';
import {ToastrService} from 'ngx-toastr';
import {DismissToken} from '../../../../shared-components/authentication/operations/dismiss-token.class';
import {EtsError} from '../../../../shared-components/unspecific/ets-error.class';
import {
    AuthTokenExpiredUIMessage
} from '../../../../shared-components/ui-messages/auth-token-expired-ui-message.interface';
import {SessionService} from '../../../../shared-components/authentication/session-service.service';
import {AccessControlService} from 'projects/shared-components/access-control-service.class';
import {SymbolPickerTreeService} from '../../../../shared-components/symbol-picker/symbol-picker-tree.service';
import {AlgoMetadataService} from '../../../../shared-components/algo/algo-metadata.service';
import {TerminalTotalDataService} from '../global-services/terminal-total-data.service';
import {LastQuoteCacheService} from '../../../../shared-components/last-quote-cache.service';
import {MultipleEventGuardService} from '../../../../shared-components/multiple-event-guard.service';
import {EventStreamServiceClient} from '../../../../shared-components/event-stream/event-stream-service-client.service';
import {AuthServiceClientService} from '../../../../shared-components/authentication/auth-service-client.service';
import {ShellStatusPollingService} from '../../../../shared-components/shell-status-polling.service';
import {StrategiesIssuesService} from '../../../../shared-components/strategies/strategy-issues.service';
import {StrategiesService} from '../../../../shared-components/strategies/strategies.service';
import {
    TradingInstrumentsService
} from '../../../../shared-components/trading-instruments/trading-instruments-service.interface';
import {
    TradingInstrumentDisplayNameService
} from '../../../../shared-components/trading-instruments/trading-instrument-display-name.service';
import {OptionsChainService} from 'projects/shared-components/option-chains.service';
import {OrderConfirmationService} from 'projects/shared-components/manual-trading/order-confirmation-service.service';
import {SettingsStorageService} from 'projects/shared-components/settings-storage-service.service';
import {JobsService} from 'projects/shared-components/jobs-tracker/jobs-service.service';
import {ShellMessagesService} from 'projects/shared-components/shell-messages/shell-messages.service';
import {AtmStrikeService} from '../../../../shared-components/common-services/atm-strike-service/atm-strike.service';
import {ApplicationSettingsService} from 'projects/shared-components/app-settings/application-settings.service';
import {ApgDefaultsService} from 'projects/shared-components/apg-defaults-dialog/apg-defaults.service';
import {ResourceEditorService} from "../../../../shared-components/resource-editor/resource-editor.service";
import {
    OptionPricingGridTemplatesService
} from "../../../../shared-components/options-pricing-grid/option-pricing-grid-templates.service";
import {LocationService} from "../../../../shared-components/location.service";
import {DetectMethodChanges} from "../../../../shared-components/utils";
import {ExpectedMoveService} from "../../../../shared-components/expected-move/expected-move.service";

@Component({
    templateUrl: './wt-initializer.component.html',
    styleUrls: ['./wt-initializer.component.scss']
})
export class WebtraderInitializerComponent implements OnInit {
    constructor(
        private readonly _sessionService: SessionService,
        private readonly _stategiesService: StrategiesService,
        private readonly _tradingInstrumentsService: TradingInstrumentsService,
        private readonly _tradingInstrumentDisplayNameService: TradingInstrumentDisplayNameService,
        private readonly _symbolPickerTreeService: SymbolPickerTreeService,
        private readonly _accessControlService: AccessControlService,
        private readonly _eventStreamServiceClient: EventStreamServiceClient,
        private readonly _algoMetadataService: AlgoMetadataService,
        private readonly _router: Router,
        private readonly _terminalTotalDataService: TerminalTotalDataService,
        private readonly _multipleEventGuardService: MultipleEventGuardService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly authServiceClient: AuthServiceClientService,
        private readonly _messageBusService: MessageBusService,
        private readonly _toastrService: ToastrService,
        private readonly _shellStatusPollingService: ShellStatusPollingService,
        private readonly _strategyIssuesService: StrategiesIssuesService,
        private readonly _optionChainService: OptionsChainService,
        private readonly _orderConfirmationService: OrderConfirmationService,
        private readonly _settingsStorageService: SettingsStorageService,
        private readonly _jobService: JobsService,
        private readonly _shellMessagesService: ShellMessagesService,
        private readonly _atmStrikeService: AtmStrikeService,
        private readonly _applicationSettingsService: ApplicationSettingsService,
        private readonly _apgDefaultsService: ApgDefaultsService,
        private readonly _resourceEditorService: ResourceEditorService,
        private readonly _opgTemplatesService: OptionPricingGridTemplatesService,
        private readonly _locationService: LocationService,
        private readonly _expectedMoveService: ExpectedMoveService
    ) {
        this.progressMessage = 'Initializing application. Please wait...';
    }


    readonly progressMessage: string;
    progressValue = 0;

    get isSessionValid(): boolean {
        return !!this._sessionService.authToken;
    }

    async ngOnInit(): Promise<void> {

        try {

            const start = Date.now();

            await this.initServicesNew();

            const end = Date.now();

            const diff = end - start;

            console.warn(`>>>  initialization took ${diff}ms`);

            if (this.isSessionValid) {
                await this._router.navigate(['/app']);
            } else {
                console.error('initializer|Initializer completed, but session not valid. App wil not be loaded');
                this._toastrService.error('Session Expired');
            }
        } catch (error) {
            this._toastrService.error(
                'Application Initialization Failed. Please contact system administrator',
                'Error'
            );

            if (!(error instanceof EtsError)) {
                console.error('initializer|Unknown initialization error', {error: error.message});
            }

            console.debug('initializer|Aborting current user session');

            const token = this._sessionService.authToken;

            if (token) {
                const cmd = new DismissToken(token, 'initialization failed');
                this.authServiceClient
                    .processCommand(cmd)
                    .then(() => {
                    })
                    .catch(e =>
                        console.debug('initializer|Session dismiss operation failed', {error: e})
                    );
            }

            this._messageBusService.publish({
                payload: {doNotShowLogoutScreen: true, source: 'Initializer'} as AuthTokenExpiredUIMessage,
                topic: 'AuthTokenExpiredUIMessage'
            });
        }
    }

    private async initServicesNew() : Promise<void> {

        const subscription = this._messageBusService.of<{time: number, name: string}>('ServiceInitialized')
            .subscribe(msg => this.onServiceInitialized(msg.payload));


        try {
            await this._eventStreamServiceClient.init();
            await this._settingsStorageService.init();

            const chainsPromise = this._optionChainService.init();
            const appSettingsPromise = this._applicationSettingsService.init();
            const aclPromise = this._accessControlService.init();
            const shellStatusPromise = this._shellStatusPollingService.init();
            const tiServicePromise = this._tradingInstrumentsService.init();
            const tidnPromise = this._tradingInstrumentDisplayNameService.init();
            const symbolPickerTreePromise = this._symbolPickerTreeService.init();

            if (this._locationService.isEtsHost) {
                await this._algoMetadataService.init();
                await this._terminalTotalDataService.init();
            }

            const lastQuoteCachePromise = this._lastQuoteCache.init();
            const multiEventGuardPromise = this._multipleEventGuardService.init();

            if (this._locationService.isEtsHost) {
                await this._stategiesService.init();
                await this._strategyIssuesService.init();
            }

            const shellMessagesPromise = this._shellMessagesService.init();

            // not for TM
            if (this._locationService.isEtsHost) {
                await this._orderConfirmationService.init();
            }


            const atmStrikesPromise = this._atmStrikeService.init().then(() => {
                this._expectedMoveService.init();
            });
            const apgDefaultsPromise = this._apgDefaultsService.init();
            const resourceEditorPromise = this._resourceEditorService.init();
            const opgTemplatesPromise = this._opgTemplatesService.init();


            const d = [
                appSettingsPromise,
                chainsPromise,
                aclPromise,
                shellStatusPromise,
                tiServicePromise,
                tidnPromise,
                symbolPickerTreePromise,
                lastQuoteCachePromise,
                multiEventGuardPromise,
                shellMessagesPromise,
                atmStrikesPromise,
                apgDefaultsPromise,
                resourceEditorPromise,
                opgTemplatesPromise,
            ];

            await Promise.all(d);


        } catch (e) {
            console.error(e);
            throw new EtsError(e.message);
        } finally {
            subscription.unsubscribe();
        }
    }

    private async initServicesOld(): Promise<void> {

        const total = 23;

        let count = 0;

        this.progressValue = 0;

        if (!this.isSessionValid) {
            return;
        }

        try {
            console.debug('initializer|trace|settings-storage service');
            await this._settingsStorageService.init();
            count++;
            this.progressValue = (count / total) * 100;
        } catch (e) {
            const errorMessage = 'initialize|error|settings-storage service failed';
            console.error(errorMessage, e);
            throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
            return;
        }

        try {
           console.debug('initializer|trace|app-settings service');
           await this._applicationSettingsService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (e) {
           const errorMessage = 'initialize|error|app-settings service failed';
           console.error(errorMessage, e);
           throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
           return;
        }

        try {
           console.debug('initializer|Initializing "OptionChainsService"');
           await this._optionChainService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (e) {
           const errorMessage = 'Failed to initialize "OptionChainsService"';
           console.error(errorMessage, e);
           throw new EtsError(errorMessage);
        }

        try {
           console.debug('initializer|Initializing "AccessControlService"');
           await this._accessControlService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (error) {
           const errorMessage = 'Initialization failed: "AccessControlService"';
           console.error(errorMessage, error);
           throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
           return;
        }

        try {
           console.debug('initializer|Initializing "ShellStatusPollingService"');
           await this._shellStatusPollingService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (e) {
           const errorMessage = 'Failed to initialize "ShellStatusPollingService"';
           console.error(errorMessage, e);
           throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
           return;
        }

        try {
           console.debug('initializer|Initializing "TradingInstrumentsService"');
           await this._tradingInstrumentsService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (error) {
           const errorMessage = 'Initialization failed: "TradingInstrumentsService"';
           console.error(errorMessage, error);
           throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
           return;
        }

        try {
           console.debug('initializer|Initializing "TradingInstrumentDisplayNameService"');
           await this._tradingInstrumentDisplayNameService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (error) {
           const errorMessage = 'Initialization failed: "TradingInstrumentDisplayNameService"';
           console.error(errorMessage, error);
           throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
           return;
        }

        try {
           console.debug('initializer|Initializing "SymbolPickerTreeService"');
           await this._symbolPickerTreeService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (error) {
           const errorMessage = 'Initialization failed: "SymbolPickerTreeService"';
           console.error(errorMessage, error);
           throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
           return;
        }

        try {
           console.debug('initializer|Initializing "AlgoMetadataService"');
           await this._algoMetadataService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (error) {
           const errorMessage = 'Initialization failed: "AlgoMetadataService"';
           console.error(errorMessage, error);
           throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
           return;
        }

        try {
           console.debug('initializer|Initializing "TerminalTotalDataProvider"');
           await this._terminalTotalDataService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (error) {
           const errorMessage = 'Initialization failed: "TerminalTotalDataProvider"';
           console.error(errorMessage, error);
           throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
           return;
        }

        try {
           console.debug('initializer|Initializing "LastQuoteCache"');
           await this._lastQuoteCache.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (error) {
           const errorMessage = 'Initialization failed: "LastQuoteCache"';
           console.error(errorMessage, error);
           throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
           return;
        }

        try {
           console.debug('initializer|Initializing "MultipleEventGuardService"');
           await this._multipleEventGuardService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (error) {
           const errorMessage = 'Initialization failed: "MultipleEventGuardService"';
           console.error(errorMessage, error);
           throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
           return;
        }

        //
        try {
           console.debug('initializer|Initializing "EventStreamServiceClient"');
           await this._eventStreamServiceClient.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (e) {
           const errorMessage = 'Failed to initialize ess-client worker';
           console.error(errorMessage, e);
           throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
           return;
        }

        //
        try {
           console.debug('initializer|Initializing "StrategiesService"');
           await this._stategiesService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (error) {
           const errorMessage = 'Initialization failed: "StrategiesService"';
           console.error(errorMessage, error);
           throw new EtsError(errorMessage);
        }

        if (!this.isSessionValid) {
           return;
        }

        //
        try {
           console.debug('initializer|Initializing "StrategyIssuesService"');
           await this._strategyIssuesService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (e) {
           const errorMessage = 'Failed to initialize "StrategyIssuesService"';
           console.error(errorMessage, e);
           throw new EtsError(errorMessage);
        }

        //
        try {
           console.debug('initializer|Initializing "ShellMessagesService"');
           await this._shellMessagesService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (e) {
           const errorMessage = 'Failed to initialize "ShellMessagesService"';
           console.error(errorMessage, e);
           throw new EtsError(errorMessage);
        }


        //
        try {
           console.debug('initializer|Initializing "Order Confirmation Service"');
           await this._orderConfirmationService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (e) {
           const errorMessage = 'Failed to initialize "Order Confirmation Service"';
           console.error(errorMessage, e);
           throw new EtsError(errorMessage);
        }

        //
        try {
           console.debug('initializer|Initializing "Jobs Service"');
           await this._jobService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (e) {
           const errorMessage = 'Failed to initialize "Jobs Service"';
           console.error(errorMessage, e);
           throw new EtsError(errorMessage);
        }

        //
        try {
           console.debug('initializer|Initializing "ATM Strike Service"');
           await this._atmStrikeService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (e) {
           const errorMessage = 'Failed to initialize "ATM Strike Service"';
           console.error(errorMessage, e);
           throw new EtsError(errorMessage);
        }

        //
        try {
           console.debug('initializer|Initializing "APG Defaults Service"');
           await this._apgDefaultsService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (e) {
           const errorMessage = 'Failed to initialize "APG Defaults Service"';
           console.error(errorMessage, e);
           throw new EtsError(errorMessage);
        }

        //
        try {
           console.debug('initializer|Initializing "Resource Service"');
           await this._resourceEditorService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (e) {
           const errorMessage = 'Failed to initialize "Resource Service"';
           console.error(errorMessage, e);
           throw new EtsError(errorMessage);
        }


        try {
           console.debug('initializer|Initializing "OPG Templates Service"');
           await this._opgTemplatesService.init();
           count++;
           this.progressValue = (count / total) * 100;
        } catch (e) {
           const errorMessage = 'Failed to initialize "OPG Templates Service"';
           console.error(errorMessage, e);
           throw new EtsError(errorMessage);
        }
    }

    private onServiceInitialized(payload: { time: number; name: string }) {
        this.progressValue += 6;
        console.log(`>>> service initialized: name=${payload.name} time=${payload.time}`);
    }
}
