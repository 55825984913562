import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {DetectMethodChanges} from "../utils";
import {Router} from "@angular/router";
import {MaintenanceHour, MaintenanceEndMinute, MaintenanceScreenService} from "./maintenance-screen.service";

@Component({
    selector: 'ets-maintenance-screen',
    template: `
        <div class="maintenance-screen">
          <div class="message">Closed For Maintenance</div>
          <div class="countdown-header">
            Service will be back online in: 
          </div>
          <div class="countdown-timer">
            <div class="wrapper">
              <div class="col minutes">
                <div class="header">Minutes</div>
                <div class="number">{{this.minutesLeft}}</div>
              </div>
              <div class="col seconds">
                <div class="header">Seconds</div>
                <div class="number">{{this.secondsLeft}}</div>
              </div>
            </div>
          </div>
          <div class="open-time">
            Maintenance ends at {{this.maintenanceHour > 12 ? this.maintenanceHour - 12 : this.maintenanceHour}}:{{this.maintenanceMinute}} PM (EST)
          </div>
        </div>
    `,
    styles:[
        `
        .maintenance-screen {
            display: flex; 
            flex-direction: column;
            justify-content: center; 
            align-items: center;
            height: 100%;
        }
        
        .message {
            font-size: 4rem;
            font-weight: 900;
        }
        
        .countdown-header {
            font-size: 1.3em;
            color: darkgray;
        }
        
        .countdown-timer {
            text-align: center;
            max-width: 400px;
            margin: 20px 10px;
            color: lawngreen;
            border-radius: 6px;
            font-family: sans-serif;
        }

        .wrapper {
            display: flex;
            column-gap: 10px;
            font-size: 2em;
        }

        .col {
            flex: 1;
            background: black;
            padding: 20px 10px;
            box-shadow: 2px 6px 9px 2px rgb(0 0 0 / 20%);
            border-radius: 10px;
        }

        .open-time {
            color: dimgray;
        }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MaintenanceScreenComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _maintenanceScreenService: MaintenanceScreenService,
        private readonly _router: Router
    ) {
    }

    private _interval: number;

    minutesLeft: number;
    secondsLeft: number;

    maintenanceHour = MaintenanceHour;
    maintenanceMinute = MaintenanceEndMinute;

    async ngOnInit() {
        await this.tickTack();
        this._interval = setInterval( async () => {
            await this.tickTack();
        }, 1000) as any;
    }

    ngOnDestroy() {
        clearInterval(this._interval);
    }

    @DetectMethodChanges({isAsync: true})
    async tickTack() {

        if (!this._maintenanceScreenService.isMaintenanceTime()) {
            await this._router.navigateByUrl('/');
            return;
        }

        const time = new Date();
        this.minutesLeft = MaintenanceEndMinute - time.getMinutes() - 1;
        this.secondsLeft = 60 - time.getSeconds();
    }
}