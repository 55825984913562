import {Injectable} from '@angular/core';
import {ShellClientService} from './shell-communication/shell-client.service';
import {
    GetUserSettings,
    GetUserSettingsData,
    StoreUserSettings,
} from './shell-communication/shell-operations-protocol';
import {UserSettingDto, UserSettingsChangedDto, UserSettingsDto} from './shell-communication/shell-dto-protocol';
import {isNullOrUndefined, isVoid} from './utils';
import {KeyValueDto} from './shell-communication/dtos/key-value-dto.interface';
import {SessionService} from './authentication/session-service.service';
import {getTemplateItems} from 'projects/webtrader/src/app/login/layout-templates';
import * as LZString from 'lz-string';
import {EtsConstants} from './ets-constants.const';
import {MessageBusService} from "./message-bus.service";
import {UserSettingsService} from "./user-settings.service";

const STORE_INTERVAL = 2000;

@Injectable({providedIn: 'root'})
export class SettingsStorageService {

    constructor(
        private readonly _sessionService: SessionService,
        private readonly _shellClient: ShellClientService,
        public readonly _messageBus: MessageBusService,
        private readonly _userSettingsService: UserSettingsService,
    ) {
        this._messageBus.of<UserSettingsChangedDto>('UserSettingsChangedDto')
            .subscribe(msg => this.onUserSettingChanged(msg.payload));
    }

    private _memoryDb = {};

    private _lastLayoutValue: string;

    //
    async init(): Promise<void> {

        const start = Date.now();

        window.sessionStorage.clear();

        const qry = new GetUserSettingsData(this._sessionService.sessionData.userId);

        let result = await this._shellClient.processQuery<UserSettingDto[]>(qry);

        if (isVoid(result)) {
            this.setInitialTemplate();
        } else {
            this._userSettingsService.init(result);
        }

        const end = Date.now();

        this._messageBus.publish({
            topic: 'ServiceInitialized',
            payload: {
                time: end-start,
                name: 'SettingsStorage'
            }
        });
    }

    setItem(key: string, item: any, userId?: string): void {

        this._userSettingsService.setValue(key, item, userId);

        // const value = typeof item === 'object' ? JSON.stringify(item) : item;
        // key = `${EtsConstants.storageKeys.scopePrefix}${EtsConstants.storageKeys.separator}${key}`;
        // if (!isVoid(userId)) {
        //     key = key + `${EtsConstants.storageKeys.userSeparator}${userId}`;
        // }
        // window.sessionStorage.setItem(key, value);
        //
        // const prevValue = this._messageBus[key];
        // if (prevValue !== value) {
        //     const cmd = new StoreUserSettings2(
        //         [{userId: userId, key, value}]
        //     );
        //
        //     this._messageBus[key] = value;
        //
        //     this._shellClient.processCommand(cmd)
        //         .catch(e => console.error('ERROR: storing user settings to server', e));
        // }
    }

    setItemAsIs(key: string, item: string) {
        console.warn(`SettingsStorageService.setItemAsIs(${key})`);
        window.sessionStorage.setItem(key, item);
    }

    getItem<T>(key: string, userId?: string): T {
        const value = this._userSettingsService.getValue<T>(key, userId);
        return value;
        // key = `${EtsConstants.storageKeys.scopePrefix}${EtsConstants.storageKeys.separator}${key}`;
        // if (!isVoid(userId)) {
        //     key = key + `${EtsConstants.storageKeys.userSeparator}${userId}`;
        // }
        // const item = window.sessionStorage.getItem(key);
        // return item;
    }

    async removeItem(key: string): Promise<void> {
        this._userSettingsService.deleteValue(key);
        // key = `${EtsConstants.storageKeys.scopePrefix}${EtsConstants.storageKeys.separator}${key}`;
        // window.sessionStorage.removeItem(key);
    }

    removeKeysMatchingPattern(pattern: string) {
        console.warn(`SettingsStorageService.removeKeysMatchingPattern(${pattern})`);
        const keys = Object.keys(window.sessionStorage);
        const tpl = `${pattern}`;
        const filtered = keys.filter(x => x.indexOf(tpl) >= 0);
        filtered.forEach(x => window.sessionStorage.removeItem(x));
    }

    private setInitialTemplate() {
        window.sessionStorage.clear();

        const templateId = this._sessionService.sessionData.defaultTemplateId;

        const templateItems = getTemplateItems(templateId, this._sessionService.sessionData.clientName);

        if (templateItems) {
            templateItems.forEach(ti => {
                this.setItem(ti.key, ti.value);
            });
        }
    }


    // private processUserSettings(userId: string, userSettings: string) {
    //
    //     const settings = LZString.decompressFromUTF16(userSettings);
    //
    //     if (isVoid(settings)) {
    //         return;
    //     }
    //
    //     const kvps = JSON.parse(settings) as KeyValueDto[];
    //
    //     if (isVoid(kvps)) {
    //         return;
    //     }
    //
    //     const extraStore: { key: string, value: string }[] = [];
    //
    //     const isOwnSettings = this._sessionService.sessionData.userId === userId;
    //
    //     kvps.forEach(kvp => {
    //
    //         const isSettingsStoredInContextOfUser = this.isSettingsThatStoredInContextOfUser(kvp.key)
    //
    //         if (!isOwnSettings && !isSettingsStoredInContextOfUser) {
    //             this._memoryDb[kvp.key] = kvp.value;
    //             return;
    //         }
    //
    //         //
    //         // Migration from older naming
    //         //
    //         if (kvp.key.startsWith('ets+before-state-defaults')) {
    //             kvp.key = kvp.key.replace('ets+before-state-defaults', 'ets+apg.before-state-defaults');
    //         }
    //
    //         if (kvp.key.startsWith('ets+comparison-before-state-defaults')) {
    //
    //             let newKey = kvp.key.replace('ets+comparison-before-state-defaults', 'ets+cpg.before-state-defaults');
    //
    //             const parts = kvp.key.split('-');
    //
    //             const reducedParts = parts.slice(0, parts.length - 1);
    //
    //             newKey = reducedParts.join('-');
    //
    //             newKey += `.${parts[parts.length - 1]}`;
    //
    //             kvp.key = newKey;
    //         }
    //
    //         if (kvp.key.startsWith('ets+apg.settings')) {
    //
    //             kvp.key = 'ets+' + EtsConstants.storageKeys.appSettings.adjustmentPricingGrid;
    //
    //             if (isOwnSettings) {
    //                 extraStore.push({key: kvp.key, value: kvp.value});
    //             }
    //
    //             return;
    //
    //         }
    //
    //         if (kvp.key.startsWith('ets+default-timezone')) {
    //
    //             kvp.key = 'ets+' + EtsConstants.storageKeys.appSettings.defaultTimezone;
    //
    //             if (isOwnSettings) {
    //                 extraStore.push({key: kvp.key, value: kvp.value});
    //             }
    //
    //             return;
    //
    //         }
    //
    //         //
    //         // Here need to duplicate other user items to be available in CPG
    //         //
    //         if (this.isCashFlowSetting(kvp.key)) {
    //
    //             if (kvp.key.indexOf(EtsConstants.storageKeys.userSeparator) >= 0) {
    //
    //                 const keyParts = kvp.key.split(EtsConstants.storageKeys.userSeparator);
    //
    //                 if (userId !== keyParts[1]) {
    //                     // this is item of subordinate user. no need to restore it as part of our
    //                     // values, it will be covered (and fresh) next
    //                     return;
    //                 }
    //
    //                 kvp.key = keyParts[0];
    //             }
    //
    //             // Migration from old naming
    //             if (kvp.key.indexOf('last_used_template') >= 0) {
    //                 kvp.key = kvp.key.replace('last_used_template', 'last-used-template');
    //             }
    //
    //             if (kvp.key.indexOf('last_used_destination') >= 0) {
    //                 kvp.key = kvp.key.replace('last_used_destination', 'last-used-destination');
    //             }
    //
    //             if (kvp.key.indexOf('.saved_positions.') >= 0) {
    //                 kvp.key = kvp.key.replace('.saved_positions.', '.saved-positions.');
    //             }
    //
    //             if (kvp.key.indexOf('.saved-positions.') >= 0) {
    //
    //                 const parts = kvp.key.split('.')
    //
    //                 if (parts.length >= 3) {
    //
    //                     let startIx = 2;
    //
    //                     if (parts[2] === 'left' || parts[2] === 'right') {
    //                         startIx = 3;
    //                     }
    //
    //                     const defs = parts.slice(startIx);
    //
    //                     if (defs.length == 2) {
    //                         parts.splice(startIx, 0, DefaultApgPortfolioId);
    //                     }
    //
    //                     kvp.key = parts.join('.');
    //                 }
    //             }
    //
    //             if (userId !== this._sessionService.sessionData.userId) {
    //
    //                 if (kvp.key.indexOf('apg.') >= 0) {
    //
    //                     let key = kvp.key;
    //
    //                     key = kvp.key.replace('apg.', 'cpg.');
    //
    //                     const parts = key.split('.');
    //
    //                     const cpgIx = parts.findIndex(x => x.indexOf('ets+cpg') >= 0);
    //
    //                     const afterCpgIx = cpgIx + 2;
    //
    //                     parts.splice(afterCpgIx, 0, 'left');
    //
    //                     const keyLeft = parts.join('.') + `${EtsConstants.storageKeys.userSeparator}${userId}`;
    //
    //                     extraStore.push({key: keyLeft, value: kvp.value});
    //
    //                     parts[afterCpgIx] = 'right';
    //
    //                     const keyRight = parts.join('.') + `${EtsConstants.storageKeys.userSeparator}${userId}`;
    //
    //                     extraStore.push({key: keyRight, value: kvp.value});
    //                 }
    //
    //                 if (kvp.key.indexOf('hg.hedge-positions') >= 0) {
    //
    //                     const poses: HedgePosition[] = JSON.parse(kvp.value);
    //
    //                     const split = kvp.key.split('.');
    //
    //                     const portfolioId = split[2];
    //
    //                     const ul = split[3];
    //
    //                     const  key = userId + portfolioId + ul;
    //
    //                     this._hgOriginalPositionsService.saveOriginalPositions(
    //                         poses,
    //                         key
    //                     );
    //                 }
    //
    //                 this._apgFormerPositionsStateService.saveFormerState(userId, kvp.key, kvp.value);
    //             }
    //
    //             kvp.key = `${kvp.key}${EtsConstants.storageKeys.userSeparator}${userId}`;
    //         }
    //
    //         if (this.isOpgSetting(kvp.key)) {
    //             if (kvp.key.indexOf(EtsConstants.storageKeys.userSeparator) <= 0) {
    //                 console.log(`Key updated. Before: ${kvp.key}`);
    //                 kvp.key = `${kvp.key}${EtsConstants.storageKeys.userSeparator}${userId}`;
    //                 console.log(`Key updated. After: ${kvp.key}`);
    //             }
    //         }
    //
    //         window.sessionStorage.setItem(kvp.key, kvp.value);
    //         this._memoryDb[kvp.key] = kvp.value;
    //
    //     });
    //
    //     extraStore.forEach(x => {
    //         window.sessionStorage.setItem(x.key, x.value);
    //         this._memoryDb[x.key] = x.value;
    //     });
    //
    // }

    private async storeToServer(): Promise<void> {

        if (!this._sessionService.isAuthenticated) {
            return;
        }

        if (!this._sessionService.connectedShell.isOnline) {
            setTimeout(() => this.storeToServer(), STORE_INTERVAL);
            return;
        }

        const settings: KeyValueDto[] = Object
            .keys(window.sessionStorage)
            .filter(k => k.startsWith(`${EtsConstants.storageKeys.scopePrefix}${EtsConstants.storageKeys.separator}`))
            .map(k => {
                return {key: k, value: window.sessionStorage.getItem(k)};
            })
            .filter(kvp => !isNullOrUndefined(kvp.value));

        if (settings.length === 0) {
            setTimeout(() => this.storeToServer(), STORE_INTERVAL);
            return;
        }

        const json = JSON.stringify(settings);

        const compressedJson = LZString.compressToUTF16(json);

        if (this._lastLayoutValue === compressedJson) {
            setTimeout(() => this.storeToServer(), STORE_INTERVAL);
            return;
        }

        this._lastLayoutValue = compressedJson;

        const cmd = new StoreUserSettings(compressedJson);

        try {
            await this._shellClient.processCommand(cmd);
        } catch (e) {
            console.error(e);
        } finally {
            setTimeout(() => this.storeToServer(), STORE_INTERVAL);
        }
    }

    private onUserSettingChanged(dto: UserSettingsChangedDto) {
         const filtered = dto.settings
             .filter(x => x.userId !== this._sessionService.sessionData.userId)
             .filter(x => this.isSettingsThatStoredInContextOfUser(x.key));

        for (let dto of filtered) {
            this.setItemAsIs(dto.key, dto.value);
        }
    }

    private isSettingsThatStoredInContextOfUser(key: string): boolean {
        return this.isOpgSetting(key) || this.isHgSetting(key) || this.isCashFlowSetting(key)
            || this.isPkgCpmrsnSetting(key);
    }

    private isOpgSetting(key: string): boolean {
        const isOpgSetting = key.startsWith('ets+opg.templates');
        return isOpgSetting;
    }

    private isCashFlowSetting(key: string): boolean {
        const isCashFlowSetting = key.indexOf('apg.') >= 0
            || key.indexOf('cpg.') >= 0
            || key.indexOf('hg.') >= 0;
        return isCashFlowSetting;
    }

    private isHgSetting(key: string): boolean {
        const isHgSettings = key.indexOf('ets+hg.') >= 0;
        return isHgSettings;
    }

    private isPkgCpmrsnSetting(key: string): boolean {
        const isPkgCmrsnSetting = key.startsWith('ets+pkg-cmprsn');
        return isPkgCmrsnSetting;
    }
}
