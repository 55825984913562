import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Column, GridOptions, GridReadyEvent, RowNode} from "ag-grid-community";
import {
    getColumnDefs,
    getDaysOfWeekString,
    getHedgeExpirationsString,
    getHedgeMatrixGridOptions
} from "./hedge-matrix-grid-options";
import {HedgePositionsService} from "../positions-section/hedge-positions/hedge-positions.service";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {
    defaultCurrencyFormatter,
    DetectMethodChanges,
    DxValueChanged,
    isOptionExpired,
    isValidNumber,
    isVoid,
    jsonCloneObject,
    makeDayOfWeekDate,
    makeGuiFriendlyExpirationDate,
    makeGuiFriendlyExpirationDateDte,
    makePortfolioKey
} from "../../utils";
import {TradingInstrument} from "../../trading-instruments/trading-instrument.class";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {OptionsChainService} from "../../option-chains.service";
import {ToastrService} from "ngx-toastr";
import {MessageBusService} from "../../message-bus.service";
import * as Enumerable from "linq";
import {HedgeMatrixDataServiceFactory} from "./hedge-matrix-data-service-factory.service";
import {ApgDataService} from "../../adjustment-pricing-grid/services/apg-data.service";
import {makeOptionTickerDisplayNameVerbose, parseOptionTicker} from "../../options-common/options.model";
import {TradingInstrumentsService} from "../../trading-instruments/trading-instruments-service.interface";
import {OptionExpirationDescriptor} from "../../shell-communication/shell-dto-protocol";
import {AtmStrikeService} from "../../common-services/atm-strike-service/atm-strike.service";
import {HedgesPricingService} from "../../package-comparison/services/hedges-pricing.service";
import {UserSettingsService} from "../../user-settings.service";
import {Subject, Subscription} from "rxjs";
import {
    HgHedgeMatrixHedgesSelectorComponent
} from "./hg-hedge-matrix-hedges-selector/hg-hedge-matrix-hedges-selector.component";
import {isNullOrUndefined} from "util";
import {HedgeData} from "./hedge-data";
import {ComboHighlightedItem, ComboHighlightedUIMessage} from "../../ui-messages/ui-messages";
import {TimeInForce} from "../../trading-model/time-in-force.enum";
import {OrderType} from "../../trading-model/order-type.enum";
import {PortfolioItemType} from "../../portfolios/portfolios.model";
import {MarketSide} from "../../trading-model/market-side.enum";
import {ClipboardService} from "../../clipboard.service";
import {DateTime} from "luxon-business-days";
import {debounceTime, filter, throttleTime} from "rxjs/operators";
import {HedgeMatrixSlideHedgeDialog} from "./slide-hedge/slide-hedge-dialog.component";
import {HedgePocketComponent} from "./hege-pocket/hedge-pocket.component";
import {OptionPricingGridTemplatesService} from "../../options-pricing-grid/option-pricing-grid-templates.service";
import {HedgeMatrixCellData} from "./hedge-matrix-cell.data";
import {HedgePosition} from "../data-model/hedge-position";
import {findOffsetExpiration, HedgeMatrixDataService} from "./hedge-matrix-data.service";
import {
    OrdersVerificationDialogComponent
} from "../../options-pricing-grid/orders-verification-dialog/orders-verification-dialog.component";
import {
    OrdersVerificationDialogConfig
} from "../../options-pricing-grid/orders-verification-dialog/orders-verification-dialog.config";
import {ExpectedMoveService} from "../../expected-move/expected-move.service";

const ShowPositionsKey = 'hg.matrix.show-positions';
const StrikesNoKey = 'hg.matrix.strikes-no';
const StrikeStepKey = 'hg.matrix.strike-step';
const CenterStrikeKey = 'hg.matrix.center-strike';

type GridState = {
    colState: any[];
    groupState: any[];
    expirationPnlNormalizedState: any[];
}

export interface HedgeMatrixRow {
    rowId?: string;
    strike?: number;
    rowSpan?: number;
    isPinned?: boolean;
    isHedgePrice?: boolean;
    isExpiration?: boolean;
    isDayOfWeek?: boolean;
    isMultiExpiration?: boolean;
}

export interface ExpirationDescriptor {
    side: 'Call' | 'Put';
    expiration: string;
    expirationFriendly: string;
    expirationFull: string;
    dayOfWeek: string;
    dayOfWeekShort: string;
    dte?: string;
}

@Component({
    selector: 'ets-hg-hedge-matrix',
    templateUrl: 'hg-hedge-matrix.component.html',
    styleUrls: ['hg-hedge-matrix.component.scss']
})

export class HgHedgeMatrixComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _messageBus: MessageBusService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _optionsChainService: OptionsChainService,
        private readonly _toastr: ToastrService,
        private readonly _hedgeMatrixDataServiceFactory: HedgeMatrixDataServiceFactory,
        private readonly _apgDataService: ApgDataService,
        private readonly _tiService: TradingInstrumentsService,
        private readonly _atmStrikeService: AtmStrikeService,
        private readonly _hedgePositionsService: HedgePositionsService,
        private readonly _userSettingsService: UserSettingsService,
        private readonly _clipboardService: ClipboardService,
        private readonly _opgTemplatesService: OptionPricingGridTemplatesService,
        private readonly _expectedMoveService: ExpectedMoveService
    ) {
        const svc = new HedgesPricingService(
            this._hedgePositionsService,
            this._lastQuoteCache,
            this._messageBus,
            this._apgDataService
        );
        this._hedgesPricingService = svc;
    }

    private readonly _hedgesPricingService: HedgesPricingService;
    private readonly _subscriptions: Subscription[] = [];

    private _hedgeMatrixDataService: HedgeMatrixDataService;

    get expectedMoveService(): ExpectedMoveService {
        return this._expectedMoveService;
    }

    @ViewChild(HgHedgeMatrixHedgesSelectorComponent)
    hedgesSelectorCmp: HgHedgeMatrixHedgesSelectorComponent;

    @ViewChild(HedgeMatrixSlideHedgeDialog)
    slideHedgeDialog: HedgeMatrixSlideHedgeDialog;

    @ViewChild(HedgePocketComponent)
    hedgePocket: HedgePocketComponent;

    @ViewChild(OrdersVerificationDialogComponent)
    orderVerificationDialog: OrdersVerificationDialogComponent;

    isLoading = false;

    theGrid: GridReadyEvent;

    gridOptions: GridOptions;

    selectedPortfolio: ApgPortfolio;

    portfolioDefaultQty = 1;

    centerStrike: number;

    tradingInstrument: TradingInstrument;

    strikeSteps = [];

    strikeStep = 5;

    numOfStrikes: number = 20;

    showPortfolioPositions = false;

    controlPanelExpanded = true;

    zoomLevel = 1;

    headersList = [
        'All',
        'Price Only',
        'Hide'
    ];

    headers: 'All' | 'Price Only' | 'Hide' = 'All';

    expirationForAtmSelector: OptionExpirationDescriptor;

    expirationPnlShowNormalized: string[] = [];

    get hedgesPricingService(): HedgesPricingService {
        return this._hedgesPricingService;
    }

    get hedgeMatrixDataService(): HedgeMatrixDataService {
        return this._hedgeMatrixDataService;
    }

    get lastQuoteCache(): LastQuoteCacheService {
        return this._lastQuoteCache;
    }

    get userSettingsService(): UserSettingsService {
        return this._userSettingsService;
    }

    ngOnInit() {

        this.restoreValues();

        this._hedgesPricingService.init();

        this.gridOptions = getHedgeMatrixGridOptions(this);

        this._subscriptions.push(
            this._messageBus.of<{ portfolio: ApgPortfolio }>('Hg.RefreshQuotes')
                .pipe(
                    debounceTime(250)
                )
                .subscribe(() => this.onChange())
        );

        this._subscriptions.push(
            this._messageBus.of<{ portfolio: ApgPortfolio }>('Hg.HedgePositionsChanged')
                .pipe(
                    filter(x => {
                        const pfKey0 = makePortfolioKey(x.payload.portfolio);
                        const pfKey1 = makePortfolioKey(this.selectedPortfolio);
                        return pfKey0 === pfKey1;
                    })
                )
                .subscribe(() => this.reloadData())
        );

        this._subscriptions.push(
            this._messageBus.of<{ portfolio: ApgPortfolio }>('Hg.HedgesCleared')
                .pipe(
                    filter(x => {
                        const pfKey0 = makePortfolioKey(x.payload.portfolio);
                        const pfKey1 = makePortfolioKey(this.selectedPortfolio);
                        return pfKey0 === pfKey1;
                    })
                )
                .subscribe(() => this.reloadData())
        );

        this._subscriptions.push(
            this._messageBus.of<any>('ExpectedMoveRangeChanged')
                .subscribe(() => {
                    if (!this.theGrid) {
                        return;
                    }
                    this.theGrid.api.refreshCells({force: true});
                })
        );
    }

    ngOnDestroy() {
        this._subscriptions.forEach(x => x.unsubscribe());
    }


    @DetectMethodChanges({isAsync: true})
    async onGridReady(args: GridReadyEvent) {

        this.theGrid = args;

        const subj: Subject<any> = this.gridOptions.context.subj;

        if (subj) {
            subj.pipe(
                throttleTime(50),
            ).subscribe(() => {
                this.theGrid.api.refreshCells({force: true});
            });
        }

    }

    private getRows(): HedgeMatrixRow[] {

        const strikesToShow = this.getStrikesToShow();

        const hedges = Enumerable.from(this.hedgeMatrixDataService.getHedges());

        const doubleStrikes = hedges.selectMany(hedge => {

            const cellData = this.hedgeMatrixDataService
                .getCellDataForHedge(hedge.id);

            const multiExpGroups = Enumerable.from(cellData)
                .groupBy(x => x.strike)
                .where(x => x.count() > 1);

            if (multiExpGroups.count() > 0) {
                return multiExpGroups.select(x => x.key()).toArray();
            }
            return [];
        });

        const rows = strikesToShow.flatMap(x => {

            const otm = x - this.centerStrike;

            const rowSpan = doubleStrikes.indexOf(x) >= 0 ? 2 : 1;

            const rowSpanRows = Enumerable.range(0, rowSpan)
                .select(((y, ix) => {
                    return {
                        otm,
                        strike: x,
                        rowSpan: ix === 0 ? rowSpan : 1,
                        isMultiExpiration: rowSpan > 1
                    } as HedgeMatrixRow
                }))
                .orderByDescending(z => z.rowSpan)
                .toArray();

            return rowSpanRows;
        });

        rows.forEach((row, ix) => row.rowId = ix + '');

        return rows;
    }

    private getStrikesToShow(): number[] {

        let centerStrike = this.centerStrike;

        if (isVoid(centerStrike)) {

            centerStrike = this._atmStrikeService.getCurrentAtm(this.tradingInstrument.ticker);

            if (isVoid(centerStrike)) {
                const lastQuote = this.tradingInstrument
                    ? this._lastQuoteCache.getLastQuote(this.tradingInstrument.ticker)
                    : null;
                this._toastr.warning('Cannot Determine Correct Market ATM. Check Quotes Provider');
                return [];
            }
        }

        const atmStrike = [this.getAtmStrike()].filter(x => isValidNumber(x));

        const numOfStrikes = this.numOfStrikes;
        const upperBound = centerStrike + numOfStrikes * this.strikeStep;
        const lowerBound = centerStrike - numOfStrikes * this.strikeStep;

        const up = Enumerable
            .rangeTo(centerStrike + this.strikeStep, upperBound, this.strikeStep);

        const down = Enumerable
            .rangeTo(lowerBound, centerStrike - this.strikeStep, this.strikeStep);

        const hedgeStrikes = this.hedgeMatrixDataService.getLegStrikes();

        let portfolioPositionStrikes = this.hedgeMatrixDataService.getPortfolioPositionStrikes();

        const strikes = Enumerable.from([centerStrike])
            .concat(atmStrike)
            .concat(up)
            .concat(down)
            .concat(hedgeStrikes)
            .concat(portfolioPositionStrikes)
            .distinct()
            .orderByDescending(x => x)
            .toArray();

        return strikes;
    }

    @DetectMethodChanges({isAsync: true})
    async onPortfolioSelected(args: ApgPortfolio): Promise<void> {

        await this._hedgesPricingService.subscribe(args);

        this.theGrid.api.setColumnDefs([]);

        this.theGrid.api.setRowData([]);

        this.selectedPortfolio = args;

        this.portfolioDefaultQty = await this._apgDataService.getDefaultQtyForPortfolio(args);

        this.expirationPnlShowNormalized = [];

        const ul = await this.getPortfolioUnderlying(args);

        if (isVoid(this.strikeStep)) {
            if (ul === 'SPX') {
                this.strikeStep = 5;
            } else {
                this.strikeStep = 1;
            }
        }

        this.strikeSteps = Enumerable
            .rangeTo(1, 50)
            .select(x => x * (ul === 'SPX' ? 5 : 1))
            .toArray();

        this._atmStrikeService.watch(ul);

        this.tradingInstrument = this._tiService.getInstrumentByTicker(ul);

        this._hedgeMatrixDataService = this._hedgeMatrixDataServiceFactory.getPortfolioDataService(
            this.selectedPortfolio
        );
        await this.hedgeMatrixDataService.onPortfolioSelected();

        this.expirationForAtmSelector = this.hedgeMatrixDataService.getNearestExpiration();

        this.fillReferenceLists();

        if (isVoid(this.centerStrike)) {
            this.centerStrike = this._atmStrikeService.getCurrentAtm(ul);
        }

        const columnDefs = getColumnDefs(this, false);

        this.theGrid.api.setColumnDefs(columnDefs);

        this.restoreGridState();

        this.hedgeMatrixDataService.getHedges().filter(x => !x.isNew).forEach(x => {
            const col = this.theGrid.columnApi.getColumn(x.id);
            if (!col) {
                return;
            }
            this.modifyHege(col, false);
        });

        const rows = this.getRows();

        this.hedgeMatrixDataService.calculatePnls(
            rows.map(x => x.strike)
        );

        this.theGrid.api.setRowData(rows);

        const pinned = this.getPinnedRows();

        this.theGrid.api.setPinnedTopRowData(pinned);

        this.theGrid.api.refreshCells({force: true});

        this.restoreSubscriptionsOfExpirationColumns();
    }

    private fillReferenceLists() {
        const hedges = this.hedgeMatrixDataService.getHedges();

        this.hedgeList = hedges.map(x => {

            const id = x.id;
            const label = x.name;
            const side = x.type;

            const expirationsString = getHedgeExpirationsString(this, x, 'original');
            const dayOfWeeksString = getDaysOfWeekString(this, x, 'original');

            return {
                id,
                label,
                side,
                expirationsString,
                dayOfWeeksString,
                hedge: x
            };

        });

        this.expirationList = Enumerable.from(hedges)
            .selectMany(x => {
                const expirations = this.hedgeMatrixDataService.getHedgeExpirations(x.id);
                const data = expirations.map(y => {
                    return {
                        type: x.type,
                        expiration: y
                    }
                });
                return data;
            })
            .distinct((el) => `${el.type}${el.expiration}`)
            .groupBy(x => x.type)
            .selectMany(x => {

                const data = x.select(y => {
                    const d = {
                        side: x.key(),
                        expiration: y.expiration,
                        expirationFriendly: makeGuiFriendlyExpirationDate(y.expiration),
                        expirationFull: makeGuiFriendlyExpirationDateDte(y.expiration),
                        dayOfWeek: makeDayOfWeekDate(y.expiration),
                        dayOfWeekShort: makeDayOfWeekDate(y.expiration, true),
                    };
                    return d;
                });

                return data;

            })
            .orderBy(x => x.side)
            .thenBy(x => x.expiration)
            .toArray();

        this.uniqueExpirationList = Enumerable.from(this.expirationList)
            .groupBy(x => x.expiration)
            .select(x => {
                const dteParts = x.first().dayOfWeek.split(' ');

                let dte;

                if (dteParts.length > 1) {
                    dte = dteParts[1];
                }

                return {
                    expiration: x.key(),
                    expirationFull: x.first().expirationFriendly,
                    dayOfWeek: x.first().dayOfWeek,
                    dayOfWeekShort: x.first().dayOfWeekShort,
                    dte: dte
                };
            })
            .toArray();

        this.pnlExpirationsList = JSON.parse(JSON.stringify(this.expirationList.slice()));

        const getHedgesByExpiration = (side: 'Call' | 'Put') => {
            const hedgesByExpiration = Enumerable.from(this.expirationList)
                .where(x => x.side === side)
                .select(exp => {

                    const hedgesWithExpiration = hedges
                        .filter(x => x.type === side)
                        .filter(h => {
                            const hedgeExpirations = this.hedgeMatrixDataService.getHedgeExpirations(h.id);
                            return hedgeExpirations.includes(exp.expiration);
                        });

                    const hedges1 = hedgesWithExpiration.map(h => {
                        const find = this.hedgeList.find(y => y.id === h.id);
                        return find;
                    })

                    return {
                        expiration: exp,
                        hedges: hedges1
                    }
                })
                .toArray();

            return hedgesByExpiration;
        }

        this.callHedgesByExpiration = getHedgesByExpiration('Call');

        this.putHedgesByExpiration = getHedgesByExpiration('Put');
    }

    private getPinnedRows(): HedgeMatrixRow[] {
        return [
            {isPinned: true, isExpiration: true},
            {isPinned: true, isDayOfWeek: true},
            {isPinned: true, isHedgePrice: true},
        ]
    }

    @DetectMethodChanges()
    onChange() {
        if (isVoid(this.theGrid)) {
            return;
        }
        this.theGrid.api.refreshCells({force: true});
    }

    private async getPortfolioUnderlying(portfolio: ApgPortfolio): Promise<string> {
        const underlying = await this._apgDataService.getUnderlyingOfPortfolio(portfolio);
        return underlying;
    }

    private async reloadData(): Promise<void> {
        await this.onPortfolioSelected(this.selectedPortfolio);
    }

    async onCenterStrikeChanged(): Promise<void> {
        await this.reloadData();
        this._userSettingsService.setValue(CenterStrikeKey, this.centerStrike);
    }

    async onStrikeStepChanged(): Promise<void> {
        await this.reloadData();
        this._userSettingsService.setValue(StrikeStepKey, this.strikeStep);
    }

    async onNumberOfStrikesChanged() {
        await this.reloadData();
        this._userSettingsService.setValue(StrikesNoKey, this.numOfStrikes);
    }

    onShowPortfolioPositionsChanged() {
        if (isVoid(this.theGrid)) {
            return;
        }

        this.theGrid.api.redrawRows();

        this._userSettingsService.setValue(ShowPositionsKey, this.showPortfolioPositions);
    }

    getHedgePriceFormatted(hedge: HedgeData) {
        const px = this.hedgesPricingService.getHedgeGroupCost(hedge.id);
        if (!isValidNumber(px)) {
            return null;
        }
        const price = defaultCurrencyFormatter(px);
        return price;
    }

    getHedgePriceWithTrans(hedge: HedgeData): number | undefined {
        const id = hedge.id;

        const hedgeCost = this._hedgesPricingService.getHedgeGroupCost(id) || 0;

        const transCost = this.hedgeMatrixDataService
            .getTransCostAsOwned(hedge.id) || 0;

        const sum = hedgeCost + transCost;

        return sum;
    }

    private restoreValues() {
        const centerStrike = this._userSettingsService.getValue<number>(CenterStrikeKey);
        this.centerStrike = centerStrike;

        const strikeStep = this._userSettingsService.getValue<number>(StrikeStepKey);
        this.strikeStep = strikeStep || 5;

        const strikesNo = this._userSettingsService.getValue<number>(StrikesNoKey);
        this.numOfStrikes = strikesNo || 20;

        const showPositions = this._userSettingsService.getValue<boolean>(ShowPositionsKey);
        this.showPortfolioPositions = showPositions || false;
    }

    @DetectMethodChanges()
    toggleControlsPanel() {
        this.controlPanelExpanded = !this.controlPanelExpanded;
    }


    //
    // Hedge Selected Dialog
    //
    hedgeList: { id: string, label: string, side: 'Call' | 'Put' }[] = [];

    expirationList: ExpirationDescriptor[] = [];

    pnlExpirationsList: ExpirationDescriptor[] = [];

    uniqueExpirationList: Partial<ExpirationDescriptor>[] = [];

    callHedgesByExpiration: any[] = [];

    putHedgesByExpiration: any[] = [];

    get selectedCallHedges(): number {
        return this.getSelectedHedgesCount('Call');
    }

    get selectedPutHedges(): number {
        return this.getSelectedHedgesCount('Put');
    };

    get selectedCallExpirations(): number {
        return this.getSelectedExpirationsCount('Call');
    };

    get selectedPutExpirations(): number {
        return this.getSelectedExpirationsCount('Put');
    };

    private getSelectedExpirationsCount(side: 'Call' | 'Put'): number {
        if (isVoid(this.theGrid)) {
            return undefined;
        }

        const amount = this.expirationList.filter(x => x.side === side)
            .map(x => {
                const colId = `${x.expiration}:expiration:${side}`;
                const column = this.theGrid.columnApi.getColumn(colId);
                if (isVoid(column)) {
                    return 0;
                }
                return column.isVisible() ? 1 : 0;
            })
            .reduce((p, c) => p + c, 0);

        return amount;
    }

    private getSelectedExpirationColumns(side: 'Call' | 'Put'): string[] {
        if (isVoid(this.theGrid)) {
            return undefined;
        }

        const amount = this.expirationList.filter(x => x.side === side)
            .map(x => {
                const colId = `${x.expiration}:expiration:${side}`;
                const column = this.theGrid.columnApi.getColumn(colId);
                if (isVoid(column)) {
                    return null;
                }
                return column.isVisible() ? x.expiration : null;
            }).filter(x => !isVoid(x));

        return amount;
    }

    private getSelectedHedgesCount(side: 'Call' | 'Put'): number {
        return this.getSelectedHedges(side).length;
    }

    getSelectedHedges(side: 'Call' | 'Put'): Column[] {
        if (isVoid(this.theGrid)) {
            return [];
        }

        const columns = this.hedgeList
            .filter(x => x.side === side)
            .map(x => {
                const colId = x.id;
                const column = this.theGrid.columnApi.getColumn(colId);
                if (isVoid(column) || !column.isVisible()) {
                    return undefined;
                }
                return column;
            })
            .filter(x => !isVoid(x));

        return columns;
    }

    getSelectedHedgesIds(side: 'Call' | 'Put'): string[] {
        if (isVoid(this.theGrid)) {
            return [];
        }

        const columns = this.hedgeList
            .filter(x => x.side === side)
            .map(x => {
                const colId = x.id;
                const column = this.theGrid.columnApi.getColumn(colId);
                if (isVoid(column) || !column.isVisible()) {
                    return undefined;
                }
                return x.id;
            })
            .filter(x => !isVoid(x));

        return columns;
    }


    @DetectMethodChanges()
    onHedgeSelectionChanged(hedge: any, value?: boolean) {

        const id = hedge.id;

        const column = this.theGrid.columnApi.getColumn(id);

        const transId = `${id}:trans`;
        const transColumn = this.theGrid.columnApi.getColumn(transId);

        const outcomeId = `${id}:outcome`;
        const outcomeColumn = this.theGrid.columnApi.getColumn(outcomeId);

        if (isNullOrUndefined(value)) {
            value = !column.isVisible();
        }

        if (!isVoid(column)) {
            this.theGrid.columnApi.setColumnVisible(column, value);
        }

        let hasModifications = false;

        if (value) {

            const hedgeData = this.hedgeMatrixDataService.getHedge(
                hedge.id
            );


            hasModifications = hedgeData?.underModification;

            value = hasModifications;
        }

        if (!isVoid(transColumn)) {
            this.theGrid.columnApi.setColumnVisible(transColumn, value);

        }

        if (!isVoid(outcomeColumn)) {
            this.theGrid.columnApi.setColumnVisible(outcomeColumn, value);
        }

        this.theGrid.api.refreshCells({force: true});
    }


    @DetectMethodChanges()
    onExpirationSelectionChanged(expiration: { expiration: string, side: 'Call' | 'Put' }, value?: boolean) {

        const id = `${expiration.expiration}:expiration:${expiration.side}`;

        const column = this.theGrid.columnApi.getColumn(id);

        if (isVoid(column)) {
            return;
        }

        if (isNullOrUndefined(value)) {
            value = !column.isVisible();
        }

        this.theGrid.columnApi.setColumnVisible(column, value);

        this.theGrid.api.forEachNode(node => {
            const strike: number = node.data.strike;
            if (!isValidNumber(node.data.strike, true)) {
                return;
            }

            const ticker = this.hedgeMatrixDataService.getTickerForCell(
                strike,
                expiration.expiration,
                expiration.side
            );

            if (!value) {
                this.lastQuoteCache.unsubscribeTicker(ticker);
            } else {
                this.lastQuoteCache.subscribeTicker(ticker);
            }
        });

        this.theGrid.api.refreshCells({force: true});
    }

    toggleSelectAllHedges(args: DxValueChanged<boolean>, side?: 'Call' | 'Put') {
        if (!args.event) {
            return;
        }

        this.hedgeList
            .filter(x => x => isVoid(side) ? true : x.side === side)
            .forEach(x => this.onHedgeSelectionChanged(x, args.value));
    }

    toggleSelectAllExpirations(args: DxValueChanged<boolean>, side?: 'Call' | 'Put') {
        if (!args.event) {
            return;
        }

        this.expirationList
            .filter(x => x => isVoid(side) ? true : x.side === side)
            .forEach(x => this.onExpirationSelectionChanged(x, args.value));
    }

    getColumnSelectorTextHedges() {

        const selectedHedges = this.selectedCallHedges + this.selectedPutHedges;
        const totalHedges = this.hedgeList.length;

        if (totalHedges === selectedHedges) {
            return "All Hedges Selected";
        }

        return `${selectedHedges} of ${totalHedges} Hedges Selected`;
    }

    getColumnSelectorTextExpirations() {

        const selectedExpirations = this.uniqueExpirationList
            .map(x => {

                const callColId = `${x.expiration}:expiration:Call`;

                const putColId = `${x.expiration}:expiration:Put`;

                const callColumn = this.theGrid.columnApi.getColumn(callColId);

                const putColumn = this.theGrid.columnApi.getColumn(putColId);

                if (isVoid(callColumn) && isVoid(putColumn)) {
                    return null;
                }

                return (callColumn.isVisible() || putColumn.isVisible()) ? x.expiration : null;

            }).filter(x => !isVoid(x));


        const distinct = selectedExpirations.filter((v, ix, arr) => arr.indexOf(v) === ix);

        if (this.uniqueExpirationList.length === distinct.length) {
            return "All Expirations Selected";
        }

        return `${distinct.length} of ${this.uniqueExpirationList.length} Expirations Selected`;
    }

    showHedgesSelector() {
        this.hedgesSelectorCmp.show(this);
    }

    isHedgeSelected(hedge: any): boolean {
        const id = hedge.id;
        const col = this.theGrid.columnApi.getColumn(id);
        return !isVoid(col) && col.isVisible();
    }

    isExpirationSelected(expiration: any): boolean {
        const id = `${expiration.expiration}:expiration:${expiration.side}`;
        const col = this.theGrid.columnApi.getColumn(id);
        return !isVoid(col) && col.isVisible();
    }

    modifyHege(column: Column, visibility?: boolean) {

        this.theGrid.api.showLoadingOverlay();

        try {
            const hedgeId: string = column.getColDef()['ets-hedge-id'];

            const hedgeData = this.hedgeMatrixDataService.getHedge(
                hedgeId
            );

            if (isVoid(hedgeData)) {
                this._toastr.error('Cannot Determine Hedge Data');
                return;
            }

            let colId = `${hedgeData.id}:trans`;
            let col = this.theGrid.columnApi.getColumn(colId);
            console.assert(!isVoid(col));

            let colVisibility = visibility;
            if (isNullOrUndefined(colVisibility)) {
                colVisibility = !col.isVisible()
            }

            this.theGrid.columnApi.setColumnVisible(col, colVisibility);

            colId = `${hedgeData.id}:outcome`;

            col = this.theGrid.columnApi.getColumn(colId);

            colVisibility = visibility;

            if (isNullOrUndefined(colVisibility)) {
                colVisibility = !col.isVisible()
            }

            this.theGrid.columnApi.setColumnVisible(col, colVisibility);

            const self = this;

            const f = !col.isVisible()
                ? () => self.hedgeMatrixDataService.onHedgeModificationFinished(hedgeData)
                : () => self.hedgeMatrixDataService.onHedgeModificationStarted(hedgeData);

            setTimeout(() => {
                const needToRebuildRows = f();
                if (needToRebuildRows) {
                    self.rebuildRows();
                }
                this.recalculatePnls();
                self.theGrid.api.refreshCells({force: true});
            });

        } finally {
            setTimeout(() => {
                this.theGrid.api.hideOverlay()
            }, 500);
        }
    }

    async addHedge(side: "Call" | "Put", templateStrategyId?: string, rowData?: HedgeMatrixRow) {
        try {
            await this.addHedgeInternal(side, templateStrategyId, rowData);
        } finally {
            this.fillReferenceLists();
        }
    }

    private async addHedgeInternal(side: "Call" | "Put", templateStrategyId?: string, rowData?: HedgeMatrixRow): Promise<HedgeData> {

        const ul = await this.getPortfolioUnderlying(this.selectedPortfolio);

        const defaultQty = this.portfolioDefaultQty;

        const addedHedge = await this.hedgeMatrixDataService
            .addNewHedge(side, ul);

        if (!isVoid(templateStrategyId)) {
            try {

                if (isVoid(rowData)) {
                    const errMsg = 'Data For Selected Row Not Found';
                    this._toastr.error(errMsg);
                    throw new Error(errMsg)
                }

                let templates = side === 'Call'
                    ? this._opgTemplatesService.getCallTemplates(this.selectedPortfolio.userId)
                    : this._opgTemplatesService.getPutTemplates(this.selectedPortfolio.userId);

                const descriptor = templates
                    .flatMap(x => x.descriptors)
                    .find(x => x.strategyId === templateStrategyId);

                if (isVoid(descriptor)) {
                    const errMsg = 'Selected Hedge Template Not Found';
                    this._toastr.error(errMsg);
                    throw new Error(errMsg)
                }

                const chain = await this._optionsChainService.getChain(ul);

                addedHedge.template = descriptor;

                let baseStrike = rowData.strike;

                let baseExpiration = this.hedgeMatrixDataService
                    .getHedgeDefaultExpiration(addedHedge.id);

                let baseExpirationDescriptor = chain.expirations
                    .find(x => x.optionExpirationDate === baseExpiration);

                const strikeMultiplier = side === 'Call' ? 1 : -1;

                let legs = descriptor.strategyLegs.slice();

                if (side === 'Call') {
                    legs.reverse();
                }

                const hasNegativeOffsets = legs
                    .filter(x => x.type === 'leg')
                    .some(l => l.expirationOffset < 0);

                if (hasNegativeOffsets) {
                    const offsets = legs.filter(x => x.type === 'leg')
                        .map(x => x.expirationOffset || 0);

                    const maxNegativeOffset = Math.min(...offsets);

                    const baseExpirationDateTime = DateTime.fromISO(baseExpiration);

                    const targetTime = baseExpirationDateTime.plusBusiness({days: Math.abs(maxNegativeOffset)});

                    const diff = targetTime.diff(baseExpirationDateTime, 'days').toObject().days;

                    const bdAdjustedDte = diff + baseExpirationDescriptor.daysToExpiration;

                    baseExpirationDescriptor = chain.expirations
                        .find(x => x.daysToExpiration >= bdAdjustedDte);
                }

                addedHedge.anchorExpiration = baseExpirationDescriptor;

                for (let leg of legs) {
                    if (leg.type === 'width') {
                        baseStrike += (leg.width * strikeMultiplier);
                    } else {
                        let expiration = baseExpirationDescriptor.optionExpirationDate;

                        const qtyMultiplier = leg.side === 'Buy' ? 1 : -1;

                        if (isValidNumber(leg.expirationOffset)) {
                            const offsetExpiration = findOffsetExpiration(
                                baseExpirationDescriptor,
                                leg.expirationOffset,
                                chain
                            );

                            if (isVoid(offsetExpiration)) {
                                const errMsg = `One (or more) of the legs of the chosen calendarized template 
                            are referencing non-existing expirations. Check selected negative offsets.`;
                                this._toastr.error(errMsg);
                                throw new Error()
                            }

                            expiration = offsetExpiration.optionExpirationDate;
                        }

                        this.hedgeMatrixDataService.setTransQty(
                            addedHedge,
                            baseStrike,
                            expiration,
                            leg.qty * defaultQty * qtyMultiplier
                        );
                    }
                }
            } catch {
                this.hedgeMatrixDataService.removeHedge(addedHedge.id);
                return;
            }
        }

        this.fillReferenceLists();

        const columnDefs = getColumnDefs(this, false);

        this.theGrid.api.setColumnDefs(columnDefs);

        this.restoreGridState();

        this.recalculatePnls();

        return addedHedge;
    }

    removeHedge(id: string) {
        this.hedgeMatrixDataService.removeHedge(id);
        this.rebuildRows();
    }

    async copyOrders(hedge: HedgeData, destination: 'hg' | 'ml-pad' | 'tos') {
        this.showVerificationDialog(hedge)
            .then(async () => {
                if (destination === 'tos') {
                    await this.copyToTos(hedge);
                } else {
                    this.copyToEts(hedge, destination);
                }
            })
            .catch(() => {
                // ignore
            });
    }

    private async showVerificationDialog(hedge: HedgeData): Promise<void> {
        const comboItems = this.makeComboItems(hedge.id);

        const orderPx = this.hedgeMatrixDataService.getTransCost(hedge.id) / this.portfolioDefaultQty;

        const config: OrdersVerificationDialogConfig = {
            orderLegs: comboItems.sort((a, b) => b.ticker.localeCompare(a.ticker)),
            orderPrice: orderPx,
            defaultQty: this.portfolioDefaultQty,
            ledgerPrice: () => this.hedgeMatrixDataService.getTransCost(hedge.id),
        };

        return this.orderVerificationDialog.show(config);
    }

    private async copyToTos(hedge: HedgeData) {

        const cbItems = this.makeComboItems(hedge?.id);

        const cost = this.hedgeMatrixDataService.getTransCost(hedge?.id);

        if (isVoid(cbItems)) {
            this._toastr.warning('No Legs to Copy!');
            return;
        }

        let hcf = 1;
        let orderQty = 1;

        const transformedLegs = cbItems
            .map(x => {

                const optionTicker = parseOptionTicker(x.ticker);

                const qty = (x.netPosition / hcf) * x.side;

                const date = DateTime.fromFormat(optionTicker.expiration, 'yyyy-MM-dd')
                    .toFormat('dd MMM yy')
                    .toUpperCase();

                const strike = optionTicker.strike;
                const type = optionTicker.type.toUpperCase();
                const price = cost;

                return {
                    qty,
                    date,
                    strike,
                    type,
                    price
                };
            });

        const underlying = this.hedgeMatrixDataService.getPortfolioUnderlying();

        const quantities = transformedLegs.map(x => x.qty).join('/');
        const dates = transformedLegs.map(x => x.date).join('/');
        const strikes = transformedLegs.map(x => x.strike).join('/');
        const types = transformedLegs.map(x => x.type).join('/');

        let line: string;
        if (underlying === 'SPX') {
            line = `BUY ${orderQty} ${quantities} CUSTOM ${underlying} 100 (Weeklys) ${dates} ${strikes} ${types} @ LMT GTC`;
        } else {
            line = `BUY ${orderQty} ${quantities} CUSTOM ${underlying} 100 ${dates} ${strikes} ${types} @ LMT GTC`;
        }

        await navigator.clipboard.writeText(line);

        this._toastr.success('Orders Copied to Clipboard!');
    }

    private copyToEts(hedge: HedgeData, destination: string) {
        const cost = this.hedgeMatrixDataService.getTransCost(hedge?.id);

        const comboItems = this.makeComboItems(hedge.id);

        if (isVoid(comboItems)) {
            this._toastr.warning('No Legs to Copy!');
            return;
        }

        const msg: ComboHighlightedUIMessage = {
            items: comboItems,
            orderParams: {
                orderLimitPx: cost,
                orderDuration: TimeInForce.GTC,
                orderQty: 1,
                orderType: OrderType.Limit
            }
        };

        if (!hedge.isNew) {
            msg.hedgingGridTransaction = {
                groupId: hedge?.id
            }
        }


        const clipboardKey = destination === 'hg' ? 'hg.opg-data' : 'combo';
        this._clipboardService.put(clipboardKey, msg);
        this._toastr.success('Combo copied to clipboard');

    }

    private makeComboItems(hedgeId: string): ComboHighlightedItem[] {

        const legs = this.hedgeMatrixDataService
            .getTransactionLegs(hedgeId);

        const comboItems = legs.map(x => {

            const optionTicker = parseOptionTicker(x.ticker);

            const displayName = makeOptionTickerDisplayNameVerbose(optionTicker);

            const optionType = optionTicker.type === 'Call'
                ? PortfolioItemType.Call
                : PortfolioItemType.Put;

            let side = MarketSide.Flat;
            if (x.qty > 0) {
                side = MarketSide.Buy;
            } else if (x.qty < 0) {
                side = MarketSide.Sell;
            }

            const item: ComboHighlightedItem = {
                accountId: null,
                comboId: null,
                portfolioId: null,
                ticker: x.ticker,
                underlying: optionTicker.underlying,
                tickerDisplayName: displayName,
                netPosition: Math.abs(x.qty),
                side: side,
                itemType: optionType,
                strategyId: null
            };

            return item;
        });

        return comboItems;
    }

    @DetectMethodChanges()
    onPnlExpirationSelectionChanged(expiration: {
        expiration: string,
        side: 'Call' | 'Put'
    }, value?: boolean, allInclusive?: boolean) {

        let id = `${expiration.expiration}:pnl-expiration:${expiration.side}`;

        if (allInclusive) {
            id += ':all';
        }

        const column = this.theGrid.columnApi.getColumn(id);

        if (isVoid(column)) {
            return;
        }

        if (isNullOrUndefined(value)) {
            value = !column.isVisible();
        }

        this.theGrid.columnApi.setColumnVisible(column, value);

        this.theGrid.api.refreshCells({force: true});

    }

    updateVisibleColumns() {

        if (!this.theGrid) {
            return;
        }

        this.saveGridState();

    }

    private saveGridState() {
        const gridOptions = this.theGrid;

        const colState = gridOptions.columnApi.getColumnState();
        const groupState = gridOptions.columnApi.getColumnGroupState();
        const expirationPnlNormalizedState = this.expirationPnlShowNormalized.slice();

        const pfKey = makePortfolioKey(this.selectedPortfolio);

        const gridState = {colState, groupState, expirationPnlNormalizedState};

        this._userSettingsService.setValue(`hg.matrix.columns.${pfKey}`, gridState);
    }


    private restoreGridState() {

        if (!this.theGrid) {
            return;
        }

        const pfKey = makePortfolioKey(this.selectedPortfolio);

        const state = this._userSettingsService.getValue<GridState>(`hg.matrix.columns.${pfKey}`);

        if (isVoid(state)) return;

        const gridOptions = this.theGrid;

        const colState = state.colState;
        const groupState = state.groupState;
        const expirationPnlNormalizedState = state.expirationPnlNormalizedState;

        if (expirationPnlNormalizedState) {
            this.expirationPnlShowNormalized = expirationPnlNormalizedState;
        }

        if (colState) {
            gridOptions.columnApi.setColumnState(colState);
        }

        if (groupState) {
            gridOptions.columnApi.setColumnGroupState(groupState);
        }
    }


    isPnlExpirationSelected(expiration: any, allInclusive: boolean): boolean {
        let id = `${expiration.expiration}:pnl-expiration:${expiration.side}`;
        if (allInclusive) {
            id += ':all';
        }
        const col = this.theGrid.columnApi.getColumn(id);
        return !isVoid(col) && col.isVisible();
    }

    toggleSelectAllPnlExpirations(args: DxValueChanged<boolean>) {
        if (!args.event) {
            return;
        }

        this.pnlExpirationsList
            .forEach(x => this.onPnlExpirationSelectionChanged(x, args.value));
    }

    recalculatePnls() {
        const strikesToShow = this.getStrikesToShow();

        this.hedgeMatrixDataService.calculatePnls(
            strikesToShow
        );

        this.theGrid.api.refreshCells();
    }

    onGrandTotalExpirationSelectionChanged(expiration: any) {
        let id = `${expiration.expiration}:grandtotalpnl`;

        const column = this.theGrid.columnApi.getColumn(id);

        if (isVoid(column)) {
            return;
        }

        const visible = !column.isVisible();
        this.theGrid.columnApi.setColumnVisible(column, visible);

        this.theGrid.api.refreshCells({force: true});
    }

    isGrandTotalExpirationSelected(expiration: any) {
        let id = `${expiration.expiration}:grandtotalpnl`;
        const col = this.theGrid.columnApi.getColumn(id);
        return !isVoid(col) && col.isVisible();
    }

    @DetectMethodChanges()
    decreaseZoom() {
        let desiredZoom = this.zoomLevel -= 0.1;
        if (desiredZoom < 0.1) {
            desiredZoom = 0.1;
        }
        this.setZoomInternal(desiredZoom);
    }

    @DetectMethodChanges()
    increaseZoom() {
        let desiredZoom = this.zoomLevel += 0.1;
        this.setZoomInternal(desiredZoom);
    }

    @DetectMethodChanges()
    setZoom($event: DxValueChanged<number>) {

        if (isVoid($event.event)) {
            return;
        }

        let zoom = $event.value / 100;

        this.setZoomInternal(zoom);
    }

    getVisibleHedges(): string[] {
        const visibleHedges = this.hedgeMatrixDataService.getHedges()
            .filter(hedge => {
                const id = hedge.id;
                const isVisible = this.theGrid.columnApi.getColumn(id)?.isVisible();
                return isVisible;
            })
            .map(x => x.id);

        return visibleHedges;
    }

    @DetectMethodChanges()
    onHeadersModeChanged() {
        if (this.headers === 'Hide') {
            this.theGrid.api.setPinnedTopRowData([]);
        } else if (this.headers === 'All') {
            const pinnedRows = this.getPinnedRows();
            this.theGrid.api.setPinnedTopRowData(pinnedRows);
        } else if (this.headers === 'Price Only') {
            const pinnedRows = this.getPinnedRows()
                .filter(x => x.isHedgePrice);
            this.theGrid.api.setPinnedTopRowData(pinnedRows);
        }
    }

    getAtmStrike(): number {
        const portfolioUnderlying = this.hedgeMatrixDataService.getPortfolioUnderlying();
        if (isVoid(portfolioUnderlying)) {
            return undefined;
        }
        const atm = this._atmStrikeService.getCurrentAtm(portfolioUnderlying);
        return atm;
    }

    @DetectMethodChanges({isAsync: true})
    async onNewHedgeExpirationChanged(hedgeId: string, expiration: string) {

        await this.hedgeMatrixDataService.onNewHedgeExpirationChanged(
            hedgeId,
            expiration
        );

        this.fillReferenceLists();

        const columnDefs = getColumnDefs(this, false);
        this.theGrid.api.setColumnDefs(columnDefs);
        this.restoreGridState();
    }

    @DetectMethodChanges()
    rebuildRows(scrollToNode?: RowNode) {

        this.fillReferenceLists();

        this.theGrid.api.setRowData([]);

        const columnDefs = getColumnDefs(this);

        this.theGrid.api.setColumnDefs(columnDefs);

        this.restoreGridState();

        const rows = this.getRows();

        this.hedgeMatrixDataService.calculatePnls(
            rows.map(x => x.strike)
        );

        this.theGrid.api.setRowData(rows);

        const pinned = this.getPinnedRows();

        this.theGrid.api.setPinnedTopRowData(pinned);

        this.theGrid.api.refreshCells({force: true});


        if (!scrollToNode) {
            return;
        }


        let prevNodeData = scrollToNode.data as HedgeMatrixRow;
        const targetStrike = prevNodeData.strike;

        let theNode: RowNode;

        this.theGrid.api.forEachNode(node => {
            const data: HedgeMatrixRow = node.data;
            if (!data) {
                return;
            }
            if (data.strike === targetStrike) {
                theNode = node;
            }
        });

        this.theGrid.api.ensureNodeVisible(nd => nd === theNode, 'middle');
    }

    addSpacer() {
        for (let i = 1; i <= 7; i++) {
            const colId = `spacer${i}`;
            const spacerCol = this.theGrid.columnApi.getColumn(colId);
            console.assert(!!spacerCol);
            if (!spacerCol.isVisible()) {
                this.theGrid.columnApi.setColumnVisible(spacerCol, true);
                break;
            }
        }
    }

    removeSpacer(colId: string) {
        this.theGrid.columnApi.setColumnVisible(colId, false);
    }

    @DetectMethodChanges({isAsync: true})
    async rebuildGrid() {
        await this.onPortfolioSelected(this.selectedPortfolio);
    }

    onNormalizedExpirationPnlToggled(value: boolean, item: Partial<ExpirationDescriptor>) {
        if (value) {
            if (!this.expirationPnlShowNormalized.includes(item.expiration)) {
                this.expirationPnlShowNormalized.push(item.expiration);
            }
        } else {
            const ix = this.expirationPnlShowNormalized.indexOf(item.expiration);
            if (ix >= 0) {
                this.expirationPnlShowNormalized.splice(ix, 1);
            }
        }
        this.saveGridState();
        this.theGrid?.api.refreshCells();
    }

    isNormalizedExpirationPnlEnabled(item: Partial<ExpirationDescriptor>) {
        return this.expirationPnlShowNormalized.includes(item.expiration);
    }

    @DetectMethodChanges()
    showSlideHedgeDialog(hedgeData: HedgeData) {
        const cb = (direction: 'Up' | 'Down') => {
            this.slideHedge(hedgeData, direction);
        };

        this.slideHedgeDialog.show(cb);
    }

    isHedgeExpired(hedgeData: HedgeData): boolean {

        if (isVoid(hedgeData)) {
            return false;
        }

        if (isVoid(hedgeData.legs)) {
            return false;
        }

        return hedgeData.legs.some(x => isOptionExpired(x.ticker));
    }

    @DetectMethodChanges()
    showPocket() {
        this.hedgePocket.show({portfolio: this.selectedPortfolio});
    }

    private slideHedge(hedgeData: HedgeData, direction: 'Up' | 'Down') {

        this.theGrid.api.showLoadingOverlay();

        const strikesToShow = this.getStrikesToShow();

        setTimeout(() => {
            try {
                const cellDataForHedge = this.hedgeMatrixDataService
                    .getCellDataForHedge(hedgeData.id);

                const multiplier = direction === 'Up' ? 1 : -1;

                const sortedCellData = cellDataForHedge
                    .slice()
                    .sort((a, b) => (b.strike - a.strike) * multiplier);

                for (let cd of sortedCellData) {
                    const cdStrikeIndex = strikesToShow.findIndex(strike => strike === cd.strike);

                    const nextIndex = cdStrikeIndex - 1 * multiplier;

                    if (nextIndex >= strikesToShow.length || nextIndex < 0) {
                        break;
                    }
                    const slideStrike = strikesToShow[nextIndex];

                    this.hedgeMatrixDataService.removeHedgeCellData(cd);

                    this.hedgeMatrixDataService.setTransQty(
                        hedgeData,
                        slideStrike,
                        cd.expiration,
                        cd.transQty
                    );
                }

            } finally {
                this.recalculatePnls();
                this.theGrid.api.hideOverlay();
                this.theGrid.api.refreshCells();
            }
        });
    }

    private setZoomInternal(zoom: number) {
        if (!isValidNumber(zoom)) {
            return;
        }

        if (zoom < 0.1) {
            zoom = 0.1;
        }

        this.zoomLevel = zoom;
    }

    rollOtherLegs(expiration: string, otherLegs: HedgePosition[], hedge: HedgeData) {
        otherLegs.forEach(leg => {

            this.hedgeMatrixDataService.setOutcomeQty(
                hedge,
                leg.strike,
                leg.expiration,
                null
            );

            this.hedgeMatrixDataService.setOutcomeQty(
                hedge,
                leg.strike,
                expiration,
                leg.qty
            );
        });
    }

    amendOtherLegs(expiration: string, otherLegs: HedgePosition[], hedge: HedgeData) {
        otherLegs.forEach(leg => {

            this.hedgeMatrixDataService.setTransQty(
                hedge,
                leg.strike,
                leg.expiration,
                null
            );

            this.hedgeMatrixDataService.setTransQty(
                hedge,
                leg.strike,
                expiration,
                leg.qty
            );
        });
    }

    toggleHedgeModifications(hedge: any, onOff: boolean) {

        this.isLoading = true;

        try {

            const hedgeData = this.hedgeMatrixDataService
                .getHedge(hedge.id);

            if (onOff) {
                const copy = hedgeData['modifications'] as HedgeMatrixCellData[];

                if (!isVoid(copy)) {
                    this.hedgeMatrixDataService.setCellData(copy);
                }

            } else {

                const cellDataForHedge = this.hedgeMatrixDataService
                    .getCellDataForHedge(hedge.id);

                const copy = jsonCloneObject(cellDataForHedge);

                cellDataForHedge.forEach(cd => {
                    this.hedgeMatrixDataService.setTransQty(
                        hedgeData,
                        cd.strike,
                        cd.expiration,
                        null
                    );

                    hedgeData['modifications'] = copy;
                });

            }

        } finally {
            setTimeout(() => {
                this.theGrid.api.redrawRows();
                this.isLoading = false
            }, 250);
        }
    }

    private restoreSubscriptionsOfExpirationColumns() {
        const expirationColumns = this.theGrid.columnApi.getAllDisplayedColumns()
            .filter(clmn => clmn.isVisible())
            .filter(clmn => clmn.getColId().indexOf(':expiration:') >= 0);

        expirationColumns.forEach(expCol => {

            const parts = expCol.getColId().split(':');

            if (parts.length !== 3) {
                return;
            }

            const expiration = parts[0];
            const side = parts[2] as any;

            this.theGrid.api.forEachNode(node => {

                const strike: number = node.data.strike;

                if (!isValidNumber(node.data.strike, true)) {
                    return;
                }

                const ticker = this.hedgeMatrixDataService.getTickerForCell(
                    strike,
                    expiration,
                    side
                );

                this.lastQuoteCache.subscribeTicker(ticker);
            });
        });
    }

    get canPasteHedgeFromOpg() : boolean {
        return this._clipboardService.hasKey('hg.opg-data');
    }

    @DetectMethodChanges({isAsync: true})
    async pasteHedgeFromOpg() {

        const message = this._clipboardService.get('hg.opg-data') as ComboHighlightedUIMessage;

        if (isVoid(message)) {
            this._toastr.error('Cannot paste hedge: clipboard is empty');
            return;
        }

        if (isVoid(message.items)) {
            this._toastr.error('Cannot paste hedge: no legs provided');
            return;
        }

        const items = message.items.map(x => {

            const optionTicker = parseOptionTicker(x.ticker);

            const side = x.side === MarketSide.Buy ? 1 : -1;

            return {
                type: optionTicker.type,
                strike: optionTicker.strike,
                expiration: optionTicker.expiration,
                qty: x.netPosition * side
            }
        });

        if (isVoid(items) || items.length !== message.items.length) {
            this._toastr.error('Cannot paste hedge: bad legs data');
            return;
        }

        const side = items[0].type;

        const addedHedge = await this.addHedgeInternal(side);

        if (isVoid(addedHedge)) {
            this._toastr.error('Cannot paste hedge: failed to add hedge to the matrix');
            return;
        }

        items.forEach(item => {
            this._hedgeMatrixDataService.setTransQty(
                addedHedge,
                item.strike,
                item.expiration,
                item.qty
                );
        });
    }
}