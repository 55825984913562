import {LastQuoteCacheService} from "../last-quote-cache.service";
import {QuoteDto} from "../shell-communication/dtos/quote-dto.class";
import {ExpectedMoveSettings} from "./expected-move.service";
import {isValidNumber} from "../utils";
import {MessageBusService} from "../message-bus.service";

export class ExpectedMoveRangeProcessor {

    constructor(
        private readonly _ticker: string,
        private readonly _expiration: string,
        private _settings: ExpectedMoveSettings,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _messageBus: MessageBusService
    ) {
    }

    private _atm: number;
    private _callTicker: string;
    private _putTicker: string;

    private _callPx: number;
    private _putPx: number;

    get id(): string {
        return `${this._ticker}:${this._expiration}`;
    }

    get ticker(): string {
        return this._ticker;
    }

    get expiration(): string {
        return this._expiration;
    }

    get callTicker(): string {
        return this._callTicker;
    }

    get putTicker(): string {
        return this._putTicker;
    }

    rangeStart: number;
    rangeEnd: number;

    onAtmChanged(currentAtm?: number) {

        if (!isValidNumber(currentAtm)) {
            this._lastQuoteCache.unsubscribeTickers([this._callTicker, this._putTicker]);
            this._callTicker = this._putTicker = undefined;
            this._callPx = this._putPx = undefined;
            this._atm = undefined;
            return;
        }

        this._atm = currentAtm;

        const callTicker = `@${this._ticker}${(this._ticker === 'SPX' ? 'W' : '')} ${this._ticker} Call ${currentAtm} ${this._expiration}`;
        const putTicker = `@${this._ticker}${(this._ticker === 'SPX' ? 'W' : '')} ${this._ticker} Put ${currentAtm} ${this._expiration}`;

        this._lastQuoteCache.unsubscribeTickers([this._callTicker, this._putTicker]);

        this._callTicker = callTicker;
        this._putTicker = putTicker;

        this._callPx = undefined;
        this._putPx = undefined;

        this._lastQuoteCache.subscribeTickers([this._callTicker, this._putTicker]);

        this.calculateRange();
    }

    onQuote(quoteDto: QuoteDto) {
        if (this._callTicker === quoteDto.ticker) {
            this._callPx = quoteDto.mid;
        } else if (this._putTicker === quoteDto.ticker) {
            this._putPx = quoteDto.mid;
        }

        this.calculateRange();
    }

    isStrikeInRange(strike: number) {
        if (!isValidNumber(strike)) {
            return false;
        }

        if (!isValidNumber(this.rangeStart) || !isValidNumber(this.rangeEnd)) {
            return false;
        }

        return strike >= this.rangeStart && strike <= this.rangeEnd;
    }

    calculateRange() {
        const rangeStartCopy = this.rangeStart;
        const rangeEndCopy = this.rangeEnd;

        try {
            this._callPx = this._lastQuoteCache.getLastQuote(this._callTicker)?.mid;
            this._putPx = this._lastQuoteCache.getLastQuote(this._putTicker)?.mid;

            const sum = this._callPx + this._putPx;

            if (!isValidNumber(sum)) {
                this.rangeStart = this.rangeEnd = undefined;
                return;
            }

            const atm = this._atm;

            if (!isValidNumber(atm)) {
                this.rangeStart = this.rangeEnd = undefined;
                return;
            }

            if (this._settings.rangeModel === 'ATM Straddle') {
                this.rangeStart = atm - sum;
                this.rangeEnd = atm + sum;

            } else if (this._settings.rangeModel === 'Custom Percentage') {
                const rangeModelCustomPercent = this._settings.rangeModelCustomPercent;
                this.rangeStart = atm - sum * rangeModelCustomPercent;
                this.rangeEnd = atm + sum * rangeModelCustomPercent;
            }
            const step = this._ticker === 'SPX' ? 5 : 1;
            this.rangeStart = Math.floor(this.rangeStart / step) * step;
            this.rangeEnd = Math.ceil(this.rangeEnd / step) * step;
        } finally {
            this._messageBus.publishAsync({
                topic: 'ExpectedMoveRangeChanged',
                payload: {
                    ticker: this.ticker,
                    expiration: this.expiration
                }
            });
        }
    }

    onSettingsChanged(expectedMoveSettings: ExpectedMoveSettings) {
        this._settings = expectedMoveSettings;
        this.calculateRange();
    }
}