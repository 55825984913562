import {NgModule} from '@angular/core';

import {ExpectedMoveSettingsComponent} from './expected-move-settings.component';
import {DevExtremeModule} from "../devextreme.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [DevExtremeModule,CommonModule],
    exports: [ExpectedMoveSettingsComponent],
    declarations: [ExpectedMoveSettingsComponent],
    providers: [],
})
export class ExpectedMoveSettingsModule {
}
