import {NgModule} from '@angular/core';
import {QuoteGeneratorComponent} from './quote-generator.component';
import {DevExtremeModule} from "../devextreme.module";
import {CommonModule} from "@angular/common";
import {EtsSymbolPickerModule} from "../symbol-picker/symbol-picker.module";
import {OptionsCommonModule} from "../options-common/options-common.module";
import {DateTimePickerModule} from "../datetime-picker/datetime-picker.module";
import {DatasetSelectorComponent} from "./dataset-selector/dataset-selector.component";
import {CustomScriptInputComponent} from "./custom-script-input/custom-script-input.component";
import {CustomScriptSaveComponent} from "./custom-script-save/custom-script-save.component";
import {EtsCommonModule} from "../common-services/common.module";
import {GenericConfirmationDialogModule} from "../generic-confirmation-dialog/generic-confirmation-dialog.module";
import {DxFileUploaderModule} from "devextreme-angular";
import {FormsModule} from "@angular/forms";

@NgModule({
    imports: [
        CommonModule,
        DevExtremeModule,
        EtsSymbolPickerModule,
        OptionsCommonModule,
        DateTimePickerModule,
        EtsCommonModule,
        GenericConfirmationDialogModule,
        DxFileUploaderModule,
        FormsModule
    ],
    exports: [
        QuoteGeneratorComponent,
    ],
    declarations: [
        QuoteGeneratorComponent,
        DatasetSelectorComponent,
        CustomScriptInputComponent,
        CustomScriptSaveComponent
    ],
    providers: [
    ],
})
export class QuoteGeneratorModule {
}
