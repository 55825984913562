import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DetectMethodChanges, DetectSetterChanges, isVoid} from "../../utils";
import {MessageBusService} from "../../message-bus.service";

import {QuoteStream} from "../quote.stream";
import {
    GetHistoryDatasetList,
    GetHistoryDatasetListReply, GetSavedScriptList, GetSavedScriptListReply,
    HistoryDatasetDescriptor, SavedScriptDescriptor
} from "../../shell-communication/shell-operations-protocol";
import {ShellClientService} from "../../shell-communication/shell-client.service";
import {
    GenericConfirmationDialogComponent
} from "../../generic-confirmation-dialog/generic-confirmation-dialog.component";
import {QuoteGeneratorService} from "../quote-generator.service";

@Component({
    selector: 'ets-dataset-selector',
    templateUrl: 'dataset-selector.component.html',
    styleUrls: ['dataset-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DatasetSelectorComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _messageBus: MessageBusService,
        private readonly _shellClient: ShellClientService,
        private readonly _quoteGeneratorService: QuoteGeneratorService
    ) {
    }

    private _stream: any;

    visible = false;

    @ViewChild(GenericConfirmationDialogComponent)
    genericConfirmationDialog: GenericConfirmationDialogComponent;

    private _isLoading = false;
    get isLoading(): boolean {
        return this._isLoading;
    }
    @DetectSetterChanges()
    set isLoading(value: boolean) {
        this._isLoading = value;
    }

    datasets: HistoryDatasetDescriptor[] = [];

    scripts: any[] = [];

    mode: 'Script' | 'History';

    files: any[] = [];

    private _fileUploaderVisible: boolean;
    get fileUploaderVisible(): boolean {
        return this._fileUploaderVisible;
    }

    @DetectSetterChanges()
    set fileUploaderVisible(value: boolean) {
        this._fileUploaderVisible = value;
    }

    uploadResultText: string;

    private _isFileUploading: boolean;
    get isFileUploading(): boolean {
        return this._isFileUploading;
    }
    @DetectSetterChanges()
    set isFileUploading(value: boolean) {
        this._isFileUploading = value;
    }

    uploadScriptName: string;

    uploadScriptDescription: string;


    ngOnInit() {
        this._messageBus.of('Qg.ReloadSavedScripts')
            .subscribe(() => this.onReloadScripts());
    }

    @DetectMethodChanges()
    show(stream: QuoteStream, mode: 'History' | 'Script') {

        this._stream = stream;
        this.mode = mode;

        this.visible = true;
        this.isLoading = true;

        this.loadData(mode);
    }

    private loadData(mode: "History" | "Script") {
        setTimeout(async () => {
            try {
                if (mode === 'History') {
                    const qry = new GetHistoryDatasetList();
                    const reply = await this._shellClient.processQuery<GetHistoryDatasetListReply>(qry);
                    this.datasets = reply.datasets;
                } else if (mode === 'Script') {
                    const qry = new GetSavedScriptList();
                    const reply = await this._shellClient.processQuery<GetSavedScriptListReply>(qry);
                    this.scripts = reply.scripts;
                }
            } finally {
                this.isLoading = false;
            }
        });
    }

    @DetectMethodChanges()
    onClose() {
        this.visible = false;
    }

    onDatasetSelected(dataset: any) {
        this._messageBus.publish({
            topic: 'Qg.DatasetSelected',
            payload: {
                stream: this._stream,
                dataset
            }
        });

        this.onClose();
    }

    onScriptSelected(script: any) {
        this._messageBus.publish({
            topic: 'Qg.ScriptSelected',
            payload: {
                stream: this._stream,
                script
            }
        });

        this.onClose();
    }

    @DetectMethodChanges()
    onReloadScripts() {
        this.isLoading = true;
        this.loadData('Script');
    }

    editScript(script: SavedScriptDescriptor) {
        this._messageBus.publish({
            topic: 'Qg.EditScript',
            payload: {
                script
            }
        });
    }

    @DetectMethodChanges({isAsync: true})
    async deleteScript(script: SavedScriptDescriptor) {

        try {
            await this.genericConfirmationDialog.show([`Are you sure you want to delete this script`, `[${script.name}]`]);
        } catch  {
            return;
        }

        this._messageBus.publish({
            topic: 'Qg.DeleteScript',
            payload: {
                script
            }
        });
        const ix = this.scripts.indexOf(script);
        if (ix > -1) {
            this.scripts.splice(ix, 1);
        }
    }

    onUploaded($event: any) {
        const file: File = this.files[0];

        const fr= new FileReader();

        fr.onloadend = () => {
            this.uploadResultText = `File "${file.name}" uploaded!`;

            const name: string =  this.uploadScriptName;
            const description: string =  this.uploadScriptDescription;
            const script = <string>fr.result;

            this._quoteGeneratorService.saveCustomScript(
                name,
                description,
                script,
                false
            ).then(() => {
                this.loadData('Script');
                this.onFileUploaderClosed();
                this.isFileUploading = false;
            });
        };

        fr.readAsText(file);
    }

    showFileUploader() {
        this.fileUploaderVisible = true;
    }

    onFileUploaderClosed() {
        this.fileUploaderVisible = false;
    }

    onUploadStarted() {
        this._isFileUploading = true;
    }

    canUploadFile() {
        return !isVoid(this.uploadScriptName)
         && !isVoid(this.uploadScriptDescription);
    }

    @DetectMethodChanges()
    onChange() {
    }
}