import { Subject } from 'rxjs';
import { Logger } from './logging/logger.interface';
import { Injectable } from '@angular/core';
import { MessageBusService } from './message-bus.service';
import { takeUntil } from 'rxjs/operators';
import {
   TradingDataCleanupCompletedMessageDto
} from './shell-communication/dtos/trading-data-cleanup-completed-message-dto.class';
import { ClearTradingDataProgressDto } from './shell-communication/dtos/clear-trading-data-progress-dto.class';
import {
   ResetAggregatedPositionsMessageDto
} from './shell-communication/dtos/reset-aggregated-positions-message-dto.class';
import { ClearTradingDataUIMessage } from './ui-messages/clear-trading-data-ui-message.class';
import {
   ClearTradingDataProgressUIMessage
} from './ui-messages/clear-trading-data-progress-ui-message.interface';
import { ClientConsoleClientService } from './client-console/client-console-client.service';
import { GetClientShellTerminals } from './client-console/protocol/operations';
import { EtsConstants } from './ets-constants.const';
import { ClientShellTerminalDto } from './client-console/protocol/dto';
import { environment } from './environments/environment';
import { SessionService } from './authentication/session-service.service';
import { ToastrService } from 'ngx-toastr';
import { ScopeStorageService } from 'projects/dashboard/src/app/global-services/scope-storage.service';
import { LoggerService } from './logging/logger-factory.service';
import { ReloadPriceChartsDto } from './shell-communication/dtos/reload-price-charts-dto.interface';
import {
   ReloadPriceChartsUIMessage,
   ReloadResourcesUIMessage
} from './ui-messages/reload-price-charts-ui-message.interface';
import { ResubscribeQuotesDto } from './shell-communication/dtos/resubscribe-quotes-dto.interface';
import {ReloadResourcesDto, ResubscribeOptionStrategiesDto} from './shell-communication/shell-dto-protocol';


@Injectable({ providedIn: 'root' })
export class MultipleEventGuardService {
   constructor(
      private readonly _messageBus: MessageBusService,
      private readonly _clientConsole: ClientConsoleClientService,
      private readonly _sessionService: SessionService,
      private readonly _toastr: ToastrService,
      private readonly _scopeStorage: ScopeStorageService,
      loggerService: LoggerService
   ) {
      this._logger = loggerService.createLogger('MultipleEventGuardService');
      this._processedEvents = {};
   }

   private readonly _logger: Logger;
   private _terminalsByShellId: Record<string, string[]>;
   private _processedEvents: Record<string, number>;
   private _unsubscriber: Subject<any>;


   async init(): Promise<any> {
      const start = Date.now();

      if (!!this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }

      this._unsubscriber = new Subject<any>();
      this._processedEvents = {};
      this._terminalsByShellId = {};

      if (environment.runtimeAppId === EtsConstants.companyServices.etsDashboardApplicationId) {
         const scope = this._scopeStorage.getScope();
         if (scope) {
            if (scope.length > 0) {
               const qry = new GetClientShellTerminals(scope);
               const clientShellTerminals = await this._clientConsole.processQuery<ClientShellTerminalDto[]>(qry);
               clientShellTerminals.forEach(cst => {
                  if (!(cst.shellId in this._terminalsByShellId)) {
                     this._terminalsByShellId[cst.shellId] = [];
                  }
                  this._terminalsByShellId[cst.shellId].push(cst.terminalId);
               });
            }
         } else {
            this._logger.warn(`Couldn't find a scope in local storage. Service will not work properly`);
         }
      } else {
         const terminals = this._sessionService.loginResult.availableTerminals.slice();
         const shellId = this._sessionService.connectedShell.shellId;
         this._terminalsByShellId[shellId] = terminals.map(t => t.terminalId);
      }

      this._messageBus
         .of<TradingDataCleanupCompletedMessageDto>('TradingDataCleanupCompletedMessageDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(data => setTimeout(() => this._onTradingDataCleanupCompleted(data.payload), 0));

      this._messageBus
         .of<ClearTradingDataProgressDto>('ClearTradingDataProgressDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(data => setTimeout(() => this._onClearTradingDataProgressMessage(data.payload), 0));

      this._messageBus.of<ResetAggregatedPositionsMessageDto>('ResetAggregatedPositionsMessageDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(data => setTimeout(() => this._onResetAggregatedPositions(data.payload), 0));

      this._messageBus.of<ReloadPriceChartsDto>('ReloadPriceChartsDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(data => setTimeout(() => this._onReloadPriceChartsDto(data.payload), 0));

      this._messageBus.of<ResubscribeQuotesDto>('ResubscribeQuotesDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(data => setTimeout(() => this._onResubscribeQuotesDto(data.payload), 0));

      this._messageBus.of<ResubscribeOptionStrategiesDto>('ResubscribeOptionStrategiesDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(data => setTimeout(() => this._onResubscribeOptionStrategiesDto(data.payload), 0));

      this._messageBus.of<ReloadResourcesDto>('ReloadResourcesDto')
         .pipe(takeUntil(this._unsubscriber))
         .subscribe(data => setTimeout(() => this.onReloadResourcesDto(data.payload), 0));


      const end = Date.now();

      this._messageBus.publish({
         topic: 'ServiceInitialized',
         payload: {
            time: end-start,
            name: 'MutliEventGuard'
         }
      });


      return Promise.resolve();
   }
   
   private _onResubscribeOptionStrategiesDto(data: any): void {
      this._toastr.info(`Option Strategies Service is back online!`, 'Option Strategies Service');
   }

   private _onResubscribeQuotesDto(data: ResubscribeQuotesDto): void {
      this._toastr.info(`Quotes Service is back online!`, 'Quotes Service');
   }

   private _onReloadPriceChartsDto(message: ReloadPriceChartsDto): void {
      if (!(message.eventId in this._processedEvents)) {
         this._processedEvents[message.eventId] = 0;

         const msg: ReloadPriceChartsUIMessage = { shellId: message.shellId };

         this._messageBus.publish({ topic: 'ReloadPriceChartsUIMessage', payload: msg });

         this._toastr.info(`Price charts were reloaded, because "Price Data Service" was down and restored`, 'PriceData Service');
      }

      const counter = this._processedEvents[message.eventId] + 1;
      const shellTerminals = this._terminalsByShellId[message.shellId];
      const terminalsCount = shellTerminals ? shellTerminals.length : 1;
      if (counter >= terminalsCount) {
         delete this._processedEvents[message.eventId];
      } else {
         this._processedEvents[message.eventId] = counter;
      }
   }

   private _onTradingDataCleanupCompleted(message: TradingDataCleanupCompletedMessageDto): void {
      if (!(message.eventId in this._processedEvents)) {
         this._processedEvents[message.eventId] = 0;

         const msg = new ClearTradingDataUIMessage();
         msg.data = message.command.data || {} as any;
         msg.strategies = message.strategies || [];
         msg.hasErrors = message.hasErrors;
         msg.terminals = message.command.terminals || [];
         msg.accounts = message.command.accounts || [];
         msg.shellId = message.shellId;
         msg.clientId = message.clientId;
         msg.shellName = message.shellName;
         msg.clientName = message.clientName;
         msg.refreshDb = message.command.refreshDb;

         this._messageBus.publish({ topic: 'ClearTradingDataUIMessage', payload: msg });

         const toastTitle = `${msg.shellName} @ ${msg.clientName}`;

         if (msg.hasErrors) {
            this._toastr.error(`"Clear Trading Data" operation completed with errors`, toastTitle);
         } else {
            this._toastr.info(`"Clear Trading Data" operation completed`, toastTitle);
         }
      }

      const counter = this._processedEvents[message.eventId] + 1;
      const shellTerminals = this._terminalsByShellId[message.shellId];
      const terminalsCount = shellTerminals ? shellTerminals.length : 1;
      if (counter >= terminalsCount) {
         delete this._processedEvents[message.eventId];
      } else {
         this._processedEvents[message.eventId] = counter;
      }
   }

   private _onClearTradingDataProgressMessage(message: ClearTradingDataProgressDto) {
      if (!(message.eventId in this._processedEvents)) {
         this._processedEvents[message.eventId] = 0;

         const msg: ClearTradingDataProgressUIMessage = {
            progress: message.progress,
            message: message.message,
            clientId: message.clientId,
            shellId: message.shellId,
            clientName: message.clientName,
            shellName: message.shellName
         };

         this._messageBus.publish({ topic: 'ClearTradingDataProgressUIMessage', payload: msg });
      }

      const counter = this._processedEvents[message.eventId] + 1;
      const shellTerminals = this._terminalsByShellId[message.shellId];
      const terminalsCount = shellTerminals ? shellTerminals.length : 1;
      if (counter >= terminalsCount) {
         delete this._processedEvents[message.eventId];
      } else {
         this._processedEvents[message.eventId] = counter;
      }
   }

   private _onResetAggregatedPositions(message: ResetAggregatedPositionsMessageDto): void {
      if (!(message.eventId in this._processedEvents)) {
         this._processedEvents[message.eventId] = 0;
         this._messageBus.publish({
            topic: 'ResetAggregatedPositionsUIMessage',
            payload: message
         });
      }

      const counter = this._processedEvents[message.eventId] + 1;
      const shellTerminals = this._terminalsByShellId[message.shellId];
      const terminalsCount = shellTerminals ? shellTerminals.length : 1;
      if (counter >= terminalsCount) {
         delete this._processedEvents[message.eventId];
      } else {
         this._processedEvents[message.eventId] = counter;
      }
   }

   private onReloadResourcesDto(message: ReloadResourcesDto) {
      if (!(message.eventId in this._processedEvents)) {
         this._processedEvents[message.eventId] = 0;

         const msg: ReloadResourcesUIMessage = { shellId: message.shellId };

         this._messageBus.publish({ topic: 'ReloadResourcesUIMessage', payload: msg });
      }

      const counter = this._processedEvents[message.eventId] + 1;
      const shellTerminals = this._terminalsByShellId[message.shellId];
      const terminalsCount = shellTerminals ? shellTerminals.length : 1;
      if (counter >= terminalsCount) {
         delete this._processedEvents[message.eventId];
      } else {
         this._processedEvents[message.eventId] = counter;
      }
   }
}
