import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DetectMethodChanges, DetectSetterChanges} from "../../utils";

import {QuoteStream} from "../quote.stream";
import {SavedScriptDescriptor} from "../../shell-communication/shell-operations-protocol";
import {QuoteGeneratorService} from "../quote-generator.service";
import {MessageBusService} from "../../message-bus.service";

@Component({
    selector: 'ets-custom-script-input',
    template: `
      <dx-popup
        [closeOnOutsideClick]="false"
        [dragEnabled]="true"
        [resizeEnabled]="true"
        [showTitle]="true"
        [visible]="this.visible"
        [shading]="false"
        [width]="480"
        [height]="350"
        title="Custom Script"
        (onHidden)="this.onClose()"
      >
        <div *dxTemplate="let data of 'content'" class="content" #content>
          <div class="body">
            <div class="script-name" *ngIf="this.isEditMode">
              <dx-text-box placeholder="Script Name" [(value)]="this.scriptName"></dx-text-box>
            </div>
            <div class="script-description" *ngIf="this.isEditMode">
              <dx-text-box placeholder="Description" [(value)]="this.scriptDescription"></dx-text-box>
            </div>
            <div class="area">
              <dx-text-area [(value)]="this.customScript" height="100%" width="100%"
                            placeholder="Script Text"
              ></dx-text-area>
            </div>
          </div>
          <div class="footer">
            <dx-button text="Apply" (click)="this.saveClicked()" width="110px"></dx-button>
            <dx-button text="Cancel" (click)="this.cancelClicked()" width="110px"></dx-button>
          </div>
          
          <dx-load-panel [(visible)]="this.isLoading" [position]="{my: 'center', at: 'center', of: content}">
          </dx-load-panel>
        
        </div>
      </dx-popup>
    `,
    styles: [`
        .content {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
        }

        .body {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: 5px;
        }

        .body .area {
            flex: 1;
        }

        .body .script-name {
            flex: 0;
        }

        .footer {
            flex: 0;
            display: flex;
            justify-content: flex-end;
            column-gap: 10px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomScriptInputComponent implements OnInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _quoteGeneratorService: QuoteGeneratorService,
        private readonly _messageBus: MessageBusService
    ) {
    }

    private _stream: QuoteStream;
    private _resolver: (value?: (PromiseLike<string> | string)) => void;
    private _rejecter: (reason?: any) => void;
    private _isLoading = false;
    private _existingScript: SavedScriptDescriptor;

    get isEditMode(): boolean {
        return !this._stream && !!this._existingScript;
    }

    visible = false;

    customScript: string;

    scriptName = '';

    scriptDescription = '';

    get isLoading(): boolean {
        return this._isLoading;
    }

    @DetectSetterChanges()
    set isLoading(value: boolean) {
        this._isLoading = value;
    }

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(stream: QuoteStream) {

        const prms = new Promise<string>((res, rej) => {
            this._resolver = res;
            this._rejecter = rej;
        });

        this._stream = stream;
        this.customScript = stream.customScript;
        this.visible = true;

        return prms;
    }

    @DetectMethodChanges()
    onClose() {
        this.visible = false;

        this._existingScript = null;
        this._stream = null;
        this.scriptName = this.scriptDescription = this.customScript = null;
    }

    saveClicked() {
        if (this._stream) {
            this._stream.customScript = this.customScript;
            this._stream.savedScript = null;
            this._resolver(this.customScript);
            this.onClose();
        } else {
            if (this._existingScript) {
                const name = this.scriptName;
                const description = this.scriptDescription;
                const script = this.customScript;
                this.isLoading = true;
                this._quoteGeneratorService.saveCustomScript(name, description, script, this.isEditMode)
                    .then(() => {
                        this.onClose();
                        this._messageBus.publish({
                            topic: 'Qg.ReloadSavedScripts',
                            payload: {}
                        });
                    }).finally(() => {this.isLoading = false;});
            }
        }
    }

    cancelClicked() {
        this.onClose();
        if (this._rejecter) {
            this._rejecter();
        }
    }

    @DetectMethodChanges()
    showEditor(script: SavedScriptDescriptor) {

        this._existingScript = script;

        this.scriptName =script.name;
        this.scriptDescription =script.description;

        this.visible = true;

        this.isLoading = true;

        this._quoteGeneratorService
            .getSavedScript(script.id)
            .then((data) => {
                this.customScript = data;
            }).finally(() => {
            this.isLoading = false;
            this._changeDetector.detectChanges();
        });

    }
}