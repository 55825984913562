import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { LastQuoteCacheService } from '../last-quote-cache.service';
import { MessageBusService } from '../message-bus.service';
import { QuoteDto } from '../shell-communication/dtos/quote-dto.class';
import { TradingInstrument } from '../trading-instruments/trading-instrument.class';
import {DetectSetterChanges, isValidNumber, isVoid, tickersMatch} from '../utils';

@Component({
   selector: 'ets-last-price',
   template: `<div *ngIf="this.lastPx">
                  <span *ngIf="this.showLabel">{{this.label}}</span>
                  <span>{{this.lastPx | number:'1.2-2'}}</span>
                  <span style="padding-left: 2px" *ngIf="this.showChange" [style.color]="this.getChangeColor()">
                     ({{this.change | number:'1.2-2'}})
                  </span>
      
               </div>
               <div *ngIf="!this.lastPx">
                  ---
               </div>
            `,
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class LastPriceComponent implements OnInit, AfterViewInit, OnDestroy {

   constructor(
      private _changeDetector: ChangeDetectorRef,
      private _lastQuoteCache: LastQuoteCacheService,
      private _messageBus: MessageBusService,
   ) { }

   //

   private _unsubscriber = new Subject();

   private _asset: TradingInstrument | string;
   get asset(): TradingInstrument | string {
      return this._asset;
   }

   //
   @DetectSetterChanges()
   @Input() set asset(v: TradingInstrument | string) {
      if (v === this.asset) {
         return;
      }

      this._asset = v;
      this.lastPx = null;
      this.onAssetChanged().then(() => {});
   }

   //
   @Input()
   showLabel = true;

   @Input()
   showChange = false;

   //
   @Input()
   label = 'Last Px: ';

   //
   private _lastPx: number;
   get lastPx(): number {
      return this._lastPx;
   }

   set lastPx(v: number) {
      this._lastPx = v;
   }


   private _change: number;
   get change(): number {
      return this._change;
   }

   set change(value: number) {
      this._change = value;
   }

   //
   private get ticker(): string {

      if (isNullOrUndefined(this.asset)) {
         return null;
      }

      let ticker;

      if (typeof this.asset === 'object') {
         ticker = this.asset.ticker;
      } else if (typeof this.asset === 'string') {
         ticker = this.asset;
      }

      return ticker;
   }

   //
   ngOnInit() {
   }

   ngAfterViewInit() {
       this._changeDetector.detach();
   }

    //
   ngOnDestroy(): void {
      if (this._unsubscriber) {
         this._unsubscriber.next();
         this._unsubscriber.complete();
      }
   }

   //

   private async onAssetChanged() {

      if (!this.ticker)  {
         return;
      }

      this._messageBus.of<QuoteDto[]>('QuoteDto')
         .subscribe(x => this.onQuote(x.payload));

      const lq = await this._lastQuoteCache.getLastQuoteWithAwait(this.ticker);

      if (lq) {
         this.onQuote([lq]);
      }
   }

   //

   private onQuote(payload: QuoteDto[]): void {

      const q = payload.find(x => tickersMatch(x.ticker, this.ticker));

      if (!q) {
         return;
      }

      this.lastPx = q.lastPx;
      this.change = q.dollarChange;

      this._changeDetector.detectChanges();
   }

   getChangeColor(): 'green' | 'red' {
      if (!isValidNumber(this.change, true)) {
         return undefined;
      }

      return this.change > 0 ? 'green' : 'red';
   }
}
